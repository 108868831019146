import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

//components
import CustomTabs from 'components/customTabs/CustomTabs'
import UploadedDocuments from 'components/shipment/UploadedDocuments'
import DocumentUploadModal from 'components/shipment/DocumentUploadModal'
import ShipmentUploadSearch from 'components/shipment/ShipmentUploadSearch'

import BookingAirDTD from 'components/booking/afapBookingDetails/BookingAirDTD'
import BookingAirPTP from 'components/booking/afapBookingDetails/BookingAirPTP'
import BookingAirDTP from 'components/booking/afapBookingDetails/BookingAirDTP'
import BookingAirPTD from 'components/booking/afapBookingDetails/BookingAirPTD'

//icons
import arrowL from 'assets/icons/arrow-left2.svg'

//types
import { Document } from 'components/shipment/type'

const BookingDetailType = ({ shipment_data, bookCategory }) => {
  return (
    <>
      {shipment_data?.air_freight_data?.air_freight_type === 'door_to_door' ? (
        <BookingAirDTD shipment_data={shipment_data} bookCategory={bookCategory} />
      ) : shipment_data?.air_freight_data?.air_freight_type === 'port_to_port' ? (
        <BookingAirPTP shipment_data={shipment_data} bookCategory={bookCategory} />
      ) : shipment_data?.air_freight_data?.air_freight_type === 'door_to_port' ? (
        <BookingAirDTP shipment_data={shipment_data} bookCategory={bookCategory} />
      ) : (
        shipment_data?.air_freight_data?.air_freight_type === 'port_to_door' && (
          <BookingAirPTD shipment_data={shipment_data} bookCategory={bookCategory} />
        )
      )}
    </>
  )
}

const BookingExportAir = (props: any) => {
  const { id, shipment_data, bookCategory } = props

  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState('Booking Details')
  const tab_result = ['Booking Details', 'Uploaded Documents']

  const [modalMode, setModalMode] = useState('')
  const [updateDocDetails, setUpdateDocDetails] = useState({})

  const [filteredDocuments, setFilteredDocuments] = useState<Document[]>(shipment_data?.document_details)
  useEffect(() => {
    setFilteredDocuments(shipment_data?.document_details)
  }, [shipment_data?.document_details])

  //modal states
  const [isOpen, setIsOpen] = useState(false)

  //modals functions
  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <>
      <div className='dashboard-content-scroll px-4 pt-8 lg:pt-10 lg:px-10'>
        <div className='flex items-center gap-x-1 mb-2 cursor-pointer w-fit' onClick={() => navigate(-1)}>
          <div>
            <img src={arrowL} alt='' />
          </div>
          <p className='text-sm grey-text'>Bookings</p>
        </div>
        <p className='text-2xl'>Booking</p>
        <div className='mb-8 mt-8 flex items-end'>
          <CustomTabs tabs={tab_result} activeTab={activeTab} setActiveTab={setActiveTab} />
          <div className='border-b border-[1px] border-[#E5E7EB] w-1/2 flex-1' />
        </div>
        {activeTab === tab_result[1] && (
          <ShipmentUploadSearch openModal={openModal} documents={shipment_data?.document_details} setFilteredDocuments={setFilteredDocuments} />
        )}
        {activeTab === tab_result[0] && <BookingDetailType shipment_data={shipment_data} bookCategory={bookCategory} />}

        {activeTab === tab_result[1] && (
          <UploadedDocuments
            documents={shipment_data?.document_details}
            setModalMode={setModalMode}
            openUploadModal={setIsOpen}
            setUpdateDocDetails={setUpdateDocDetails}
            filteredDocuments={filteredDocuments}
          />
        )}

        <DocumentUploadModal
          id={id}
          isOpen={isOpen}
          closeModal={closeModal}
          shipCategory={shipment_data.shipment_type + shipment_data.shipment_transport_type}
          documents={shipment_data?.document_details}
          modalMode={modalMode}
          setModalMode={setModalMode}
          updateDocDetails={updateDocDetails}
          setUpdateDocDetails={setUpdateDocDetails}
        />
      </div>
    </>
  )
}

export default BookingExportAir
