import React from 'react'
import UnitTermsAndCond from './UnitTermsAndCond'

const ExportTermsAndCond = () => {
  return (
    <div className='text-sm black-text  '>
      <h1 className='text-[#4B5563] text-[10px] md:text-[20px] ' style={{ fontWeight: 500 }}>
        ONEPORT365 TERMS AND CONDITIONS
      </h1>

      <div className='border-[#F3F4F6] min-w-[300px]  border-[1.5px] rounded-[16px] mt-[24px] py-[10px] px-[24px] '>
        <div className=' flex flex-col gap-[8px]  '>
          <UnitTermsAndCond number='1' terms='Customs clearance would be provided by ONEEXPRESS : A licenced customs brokerage provider.' />

          <UnitTermsAndCond
            number='2'
            terms='Transit time is estimated time by carriers, and may vary depending on sailing dates of the specific service contracted.'
          />

          <UnitTermsAndCond number='3' terms='Ocean freight advised is subject to Environmental fuel surcharge from shipping line.' />

          <UnitTermsAndCond
            number='4'
            terms='Renomination and storage charges incurred as a result of delay in NXP validation by CBN / vessel roll over will  be borne by the client.'
          />

          <UnitTermsAndCond number='5' terms='Ocean Freight paid by One Port 365 will attract a 10% service fee on the total freight value.' />

          <UnitTermsAndCond
            number='6'
            terms="Exchange rate is subject to change according to carrier's exchange rate on the day of vessel sailing."
          />

          <UnitTermsAndCond number='7' terms='A debit note will be raised separately for only confirmed additional charges post sailing.' />

          <UnitTermsAndCond
            number='8'
            terms="Trucking demurrage of NGN30,000 per day kicks in 24 hours post arrival at client's warehouse (without operations)"
          />

          <UnitTermsAndCond number='9' terms='7.5% VAT will be applicable at the time of invoicing.' />

          <UnitTermsAndCond
            number='10'
            terms='All documentation related to clearing shipments is to be provided by the CLIENT Including: Approved NXP, PFI, packing list, Commercial Invoice, NESS payment.'
          />

          <UnitTermsAndCond number='11' terms='CLIENT to ensure that all the details are correct and in order.' />

          <UnitTermsAndCond
            number='12'
            terms='CLIENT ensures that all permits required for the exportation of their commodity are provided and valid at the time of export.'
          />

          <UnitTermsAndCond
            number='13'
            terms='The Goods exported are the same as the Goods described in the PFI and other Shipping Documentation provided.'
          />
          <UnitTermsAndCond number='14' terms='SERVICE PROVIDER to be notified 48 Hrs prior to requesting containers for stuffing at facility.' />
          <UnitTermsAndCond
            number='15'
            terms='CLIENT to ensure that Goods are available for loading when trucks are provided at the warehouse/facility, to avoid delay in stuffing.'
          />
          <UnitTermsAndCond
            number='16'
            terms='CLIENT to ensure proper stuffing of Consignment in the container. Where necessary, use belts, ropes or pallets to secure goods as SERVICE PROVIDER shall not be held liable for any incidences during inspection by Customs & other Govt. Agencies.'
          />
          <UnitTermsAndCond
            number='17'
            terms='CLIENT to load SERVICE PROVIDER’s Trucks within 24Hrs of arrival at Facility, Truck demurrage per day applies for delays beyond 48Hrs.'
          />
          <UnitTermsAndCond
            number='18'
            terms='Timely payment of NESS by CLIENT and receipt shared within 24 Hrs on advice to proceed by SERVICE PROVIDER.'
          />
          <UnitTermsAndCond number='19' terms='CLIENT to provide Shipping Instruction accurately on the DATASHEET.' />
          <UnitTermsAndCond
            number='20'
            terms='All Draft BLs sent by SERVICE PROVIDER to CLIENT to be vetted for amendments within 24Hrs of receipt.'
          />
          <UnitTermsAndCond
            number='21'
            terms='CLIENT is responsible for BL Corrections/Amendment Costs for any amendment on “approved” Draft BLs after Vessel Sail.'
          />
          <UnitTermsAndCond number='22' terms='Prompt payment of freight to ensure timely issuance of OBL/Seawaybill.' />
        </div>
      </div>
    </div>
  )
}

export default ExportTermsAndCond
