interface BookingDetails {
  pickup_location: string
  delivery_location: string
  origin_port_code: string
  destination_port_code: string
}
export const airFreightOriginDestinationFormat = (afap_type: string, od: string, booking_details: any) => {
  if (afap_type == 'door_to_door') {
    if (od == 'origin') {
      return booking_details?.pickup_location
    } else {
      return booking_details?.delivery_location
    }
  } else if (afap_type == 'door_to_port') {
    if (od == 'origin') {
      return booking_details?.pickup_location
    } else {
      return booking_details?.destination_port_code
    }
  } else if (afap_type == 'port_to_door') {
    if (od == 'origin') {
      return booking_details?.origin_port_code
    } else {
      return booking_details?.delivery_location
    }
  } else if (afap_type == 'port_to_port') {
    if (od == 'origin') {
      return booking_details?.origin_port_code
    } else {
      return booking_details?.destination_port_code
    }
  }
}
