import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

//redux
import { connect } from 'react-redux'

//libraries
import { useForm } from 'react-hook-form'
import uniqid from 'uniqid'

//helpers
import { getAllPorts, parseAllPortsNew, getAllowedPorts } from 'helpers'

//actions
import { createBooking, confirmBooking, getBookingDetailsById, clearBooking } from 'store/actions'

//components
import Layout from 'components/layout/Layout'
import PageLoading from 'components/partials/pageLoading'
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import PalletForm from './afapPartials/palletAddition/palletForm'
import SecondaryButtons from 'components/buttons/SecondaryButtons'
import BookingSuccessModal from './afapPartials/bookingSuccessModal'
import AirFreightLocations from 'components/airfreight/AirFreightLocations'
import BookingSummaryModal from 'pages/afap/afapPartials/bookingSummaryModal'
import AirFreightWeightInfo from 'components/airfreight/AirFreightWeightInfo'
import AirFreightShipmentDetails from 'components/airfreight/AirFreightShipmentDetails'

const _Json = require('sea-ports')

const AirFreightDetails = (props: any) => {
  const {
    loading,
    error,
    shipment_data,
    createBooking,
    clearBooking,
    confirmBooking,
    booking_confirmed,
    confirming_booking,
    booking_data,
    getting_booking,
    getBookingDetailsById,
  } = props

  const {
    handleSubmit,
    control,
    resetField,
    formState: { errors },
    reset,
    watch,
  } = useForm({ mode: 'onChange' || 'onSubmit' })

  const [airFreightCategory, setAirFreightCategory] = useState('porttoport')
  const [freightDetails, setFreightDetails] = useState({})

  const navigate = useNavigate()

  //default destination
  const [originPort, setOriginPort] = useState<string | {}>('')
  const [defaultPortsOfOrigin, setDefaultPortsOfOrigin] = useState([])
  const [defaultPortsOfDestination, setDefaultPortsOfDestination] = useState([])
  const [allPorts, setAllPorts] = useState([])
  const [allowedPorts, setAllowedPorts] = useState([])
  const [showSummaryModal, setShowSummaryModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const openBookingSummaryModal = () => {
    setShowSummaryModal(true)
  }

  const closeBookingSummaryModal = () => {
    setShowSummaryModal(false)
  }

  const closeSuccessModal = () => {
    setShowSuccessModal(false)
  }

  //fetches the ports
  useEffect(() => {
    //@ts-ignore
    const parsePorts = parseAllPortsNew(_Json.JSON, originPort?.value?.country)
    setDefaultPortsOfOrigin(parsePorts.origin)
    setDefaultPortsOfDestination(parsePorts.destination)
    setAllPorts(getAllPorts(_Json.JSON))
    setAllowedPorts(getAllowedPorts(_Json.JSON))
    resetField('destination_port')
  }, [originPort])

  const [cargoVolume, setCargoVolume] = useState<Record<string, string>>({}) // Specify the type for cargoVolume state
  const calculateCargoVolume = (height: string, width: string, length: string, uid: string) => {
    const parsedHeight = parseFloat(height).toString() // Convert height to string
    const parsedWidth = parseFloat(width).toString() // Convert width to string
    const parsedLength = parseFloat(length).toString() // Convert length to string
    const volume = ((parseFloat(parsedHeight) * parseFloat(parsedWidth) * parseFloat(parsedLength)) / 6000).toFixed(2)
    // Update the state for cargoVolume with the unique identifier
    setCargoVolume((prevState) => ({ ...prevState, [uid]: volume.toString() })) // Ensure volume is converted to string
  }

  const location = useLocation()
  const shipment_id = location?.state?.id

  const params = useParams()
  const { id } = params

  useEffect(() => {
    id && getBookingDetailsById(id)
  }, [])

  const handleBookingConfirmed = () => {
    closeBookingSummaryModal()
    setShowSuccessModal(true)
  }

  const bookingCreation = (booking_id) => {
    setBookingId(booking_id)
    openBookingSummaryModal()
  }

  const bookingConfirmation = (call_data, booking_id) => {
    confirmBooking(
      {
        ...call_data,
        shipment_id: booking_id,
      },
      handleBookingConfirmed
    )
  }

  const [bookingId, setBookingId] = useState('')

  const [palletSize, setPalletSize] = useState(true)
  const [additionalPallet, setAdditionalPallet] = useState([{ uid: uniqid() }])
  const [clickCount, setClickCount] = useState(1)

  const addNewPallet = () => {
    const newPallet = {
      uid: uniqid(),
    }
    setAdditionalPallet([...additionalPallet, newPallet])
    setClickCount(clickCount + 1)
  }

  const removePallet = (index: string) => {
    clickCount > 1 &&
      setAdditionalPallet((previousPallet) => {
        return previousPallet.filter((data, i) => index !== data['uid'])
      })
    clickCount > 1 && setClickCount(clickCount - 1)
  }

  const removeLastPallet = () => {
    palletSize
      ? additionalPallet.length > 1 && setAdditionalPallet(additionalPallet.slice(0, -1))
      : additionalPallet.length > 2 && setAdditionalPallet(additionalPallet.slice(0, -1))

    palletSize ? clickCount > 1 && setClickCount(clickCount - 1) : clickCount > 2 && setClickCount(clickCount - 1)
  }

  const resetPallet = () => {
    !palletSize ? setAdditionalPallet([{ uid: uniqid() }]) : setAdditionalPallet([{ uid: uniqid() }, { uid: uniqid() }])
  }

  const container_details: any = []

  interface PalletItem {
    uid: string
  }
  const onSubmit = (data: any) => {
    // @ts-ignore
    additionalPallet.forEach((item: PalletItem, index: number) => {
      const container_volume = cargoVolume[item.uid]
      container_details.push({
        container_length: data[`container_length_${item['uid']}`],
        container_width: data[`container_width_${item['uid']}`],
        container_height: data[`container_height_${item['uid']}`],
        container_weight: container_volume,
      })
      setFreightDetails({ ...data, container_details: container_details, air_freight_type: airFreightCategory, clickCount: clickCount })
      return container_details
    })

    const _ptpData = {
      shipment_type: data?.origin_port_code?.value?.terms?.[2]?.value === 'Nigeria' ? 'export' : 'import',
      shipment_transport_type: 'air_freight',
      air_freight_type: 'port_to_port',
      origin_port_code: data?.origin_port_code?.value?.description ?? data?.origin_port_code?.value,
      destination_port_code: data?.destination_port_code?.value?.description ?? data?.destination_port_code?.value,
    }

    const _dtpData = {
      shipment_type: data?.pickup_location?.value?.terms?.[2]?.value === 'Nigeria' ? 'export' : 'import',
      shipment_transport_type: 'air_freight',
      air_freight_type: 'door_to_port',
      pickup_location: data?.pickup_location?.value?.description ?? data?.pickup_location?.value,
      destination_port_code: data?.destination_port_code?.value?.description ?? data?.destination_port_code?.value,
    }

    const _dtdData = {
      shipment_type: data?.pickup_location?.value?.terms?.[2]?.value === 'Nigeria' ? 'export' : 'import',
      shipment_transport_type: 'air_freight',
      air_freight_type: 'door_to_door',
      pickup_location: data?.pickup_location?.value?.description ?? data?.pickup_location?.value,
      delivery_location: data?.delivery_location?.label,
    }

    const _ptdData = {
      shipment_type: 'import',
      shipment_transport_type: 'air_freight',
      air_freight_type: 'port_to_door',
      origin_port_code: data?.origin_port_code?.value?.description ?? data?.origin_port_code?.value,
      delivery_location: data?.delivery_location?.label,
    }

    airFreightCategory === 'porttoport'
      ? createBooking(_ptpData, (id) => bookingCreation(id))
      : airFreightCategory === 'doortoport'
        ? createBooking(_dtpData, (id) => bookingCreation(id))
        : airFreightCategory === 'doortodoor'
          ? createBooking(_dtdData, (id) => bookingCreation(id))
          : airFreightCategory === 'porttodoor' && createBooking(_ptdData, (id) => bookingCreation(id))
  }

  const air_freight_type = location?.state?.air_freight_type

  useEffect(() => {
    if (air_freight_type === 'porttoport') {
      setAirFreightCategory('porttoport')
    } else if (air_freight_type === 'doortodoor') {
      setAirFreightCategory('doortodoor')
    } else if (air_freight_type === 'doortoport') {
      setAirFreightCategory('doortoport')
    } else if (air_freight_type === 'porttodoor') {
      setAirFreightCategory('porttodoor')
    }
  }, [air_freight_type])

  console.log('freightDetails>>>', freightDetails)

  return (
    <Layout>
      <main className='px-4 pt-8 lg:pt-10 lg:px-10 dashboard-content-scroll'>
        {getting_booking ? (
          <PageLoading title='details' />
        ) : (
          <div className='w-full lg:w-4/5'>
            <div className='pb-8 bottom-divider'>
              <h1 className='black-text-2 text-2xl'>Air Freight</h1>
              <p className='black-text-4 text-sm font-light mt-1.5'>Please provide the details of the freight</p>
            </div>
            <AirFreightWeightInfo />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='bg-[#109b320d] rounded-lg mt-6'>
                <div className='bottom-divider-2 px-8 py-6'>
                  <h3 className='text-base black-text-3'>Air Freight details</h3>
                  <p className='black-text-4 text-sm font-light'>Please provide the required information</p>
                </div>
                <div className='px-8 pt-3 pb-6'>
                  <div className='categories'>
                    <div className='flex items-center gap-x-6 mb-6'>
                      <p
                        className={`text-sm cursor-pointer font-light ${airFreightCategory === 'porttoport' ? 'border-[#3AB44A] green-text-2' : 'border-transparent grey-text-1'} border-solid border-b-[2px] pb-2.5 w-fit cursor-pointer`}
                        onClick={() => {
                          setAirFreightCategory('porttoport')
                          reset()
                        }}
                      >
                        Port to Port
                      </p>
                      <p
                        className={`text-sm cursor-pointer font-light ${airFreightCategory === 'doortoport' ? 'border-[#3AB44A] green-text-2' : 'border-transparent grey-text-1'} border-solid border-b-[2px] pb-2.5 w-fit cursor-pointer`}
                        onClick={() => {
                          setAirFreightCategory('doortoport')
                          reset()
                        }}
                      >
                        Door to Port
                      </p>
                      <p
                        className={`text-sm cursor-pointer font-light ${airFreightCategory === 'porttodoor' ? 'border-[#3AB44A] green-text-2' : 'border-transparent grey-text-1'} border-solid border-b-[2px] pb-2.5 w-fit cursor-pointer`}
                        onClick={() => {
                          setAirFreightCategory('porttodoor')
                          reset()
                        }}
                      >
                        Port to Door
                      </p>

                      <p
                        className={`text-sm cursor-pointer font-light ${airFreightCategory === 'doortodoor' ? 'border-[#3AB44A] green-text-2' : 'border-transparent grey-text-1'} border-solid border-b-[2px] pb-2.5 w-fit cursor-pointer`}
                        onClick={() => {
                          setAirFreightCategory('doortodoor')
                          reset()
                        }}
                      >
                        Door to Door
                      </p>
                    </div>
                    <AirFreightLocations
                      defaultPortsOfOrigin={defaultPortsOfOrigin}
                      defaultPortsOfDestination={defaultPortsOfDestination}
                      allPorts={allPorts}
                      allowedPorts={allowedPorts}
                      control={control}
                      errors={errors}
                      originPort={originPort}
                      setOriginPort={setOriginPort}
                      resetField={resetField}
                      airFreightType={airFreightCategory}
                      extras={false}
                      booking_data={booking_data}
                    />
                  </div>
                </div>
              </div>

              <AirFreightShipmentDetails
                airFreightCategory={airFreightCategory}
                control={control}
                errors={errors}
                booking_data={booking_data}
                addNewPallet={addNewPallet}
                removePallet={removePallet}
                removeLastPallet={removeLastPallet}
                resetPallet={resetPallet}
                clickCount={clickCount}
                setClickCount={setClickCount}
                palletSize={palletSize}
                setPalletSize={setPalletSize}
                setCargoVolume={setCargoVolume}
                reset={reset}
              />

              <div>
                {additionalPallet.map((item: any, index: number) => {
                  return (
                    <div key={item.uid}>
                      <PalletForm
                        uid={item.uid}
                        index={index}
                        watch={watch}
                        control={control}
                        errors={errors}
                        removePallet={removePallet}
                        calculateCargoVolume={calculateCargoVolume}
                        cargoVolume={cargoVolume}
                        setCargoVolume={setCargoVolume}
                        palletSize={palletSize}
                        setPalletSize={setPalletSize}
                        firstPallet={additionalPallet[0]}
                      />
                    </div>
                  )
                })}
              </div>

              <hr />
              <div className='flex justify-end gap-x-6 mt-8'>
                <SecondaryButtons
                  title='Cancel'
                  style={{ padding: '12px 14px' }}
                  onClick={() => {
                    navigate('/dashboard')
                    clearBooking()
                  }}
                  disabled={false}
                  loading={false}
                  type={'button'}
                  icon={''}
                />
                <PrimaryButtons title='Confirm' style={{}} disabled={false} loading={loading} icon={''} />
              </div>
            </form>
          </div>
        )}

        <BookingSummaryModal
          bookingId={bookingId}
          freightDetails={freightDetails}
          confirming_booking={confirming_booking}
          showSummaryModal={showSummaryModal}
          bookingConfirmation={bookingConfirmation}
          openBookingSummaryModal={openBookingSummaryModal}
          closeBookingSummaryModal={closeBookingSummaryModal}
          setShowSummaryModal={closeBookingSummaryModal}
          setShowSuccessModal={setShowSuccessModal}
        />

        {showSuccessModal ? (
          <BookingSuccessModal
            modalIsOpen={showSuccessModal}
            closeModal={closeSuccessModal}
            heading='Booking Completed!!!'
            text='You have successfully completed your booking process. You will receive a quote shortly.'
          />
        ) : null}
      </main>
    </Layout>
  )
}

// export default AirFreightDetails

const mapStateToProps = (state: any) => {
  const { error, loading, shipment_data, getting_booking, booking_data } = state.booking
  const { booking_confirmed, confirming_booking } = state.additionalDetails

  return {
    error,
    loading,
    shipment_data,
    getting_booking,
    booking_data,
    booking_confirmed,
    confirming_booking,
  }
}

export default connect(mapStateToProps, { createBooking, confirmBooking, getBookingDetailsById, clearBooking })(AirFreightDetails)
