import React from 'react'
import logo from '../../assets/logos/oneport-logo.svg';

const KenyaQuoteAddress = () => {
  return (
    <div className="flex flex-col md:flex-row justify-between gap-5">
    <img src={logo} alt="oneport logo" className=" min-w-[200px]" />
    <div className="flex flex-col text-[#6B7280] text-[16px] items-end">
      <span>Katko complex,</span>
      <span className=" lg:text-end"> godown3</span>
      <span className="lg:text-end"> Old Mombasa Road</span>
    </div>
  </div>
  )
}

export default KenyaQuoteAddress