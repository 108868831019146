import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

//libraries
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'

//redux
import { clearErrors, forgotPasswordUser, VerifyOtpPasswordReset, resetPassword, verifyDocumentUpload } from 'store/actions'

import { document_name } from '../type'

//components
import Button from 'components/dock/Button'
import CustomOtpInput from 'components/textInputs/CustomOtpInput'
import DocumentUploadModal from '../DocumentUploadModal'

const OpenDocumentUpload = (props: any) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()
  const { error, verifying, verified_data, verifyDocumentUpload } = props

  const [step, setStep] = useState(1)

  const location = useLocation()

  const params = new URLSearchParams(location.search)

  const navigate = useNavigate()

  const closeModal = () => {
    navigate('/signin')
  }

  const documents: any = []
  // Iterate over the parameters and collect documents dynamically
  for (const [key, value] of params.entries()) {
    if (key.startsWith('document')) {
      documents.push({ label: document_name[value] || value, value: value })
    }
  }

  const pathname = window.location.pathname

  // Split the pathname by '/' and extract the id part
  const parts = pathname.split('/')
  const idPart = parts[2]

  const verify_token = params?.get('verify_token')?.replace(/ /g, '+')
  const email = params.get('email')

  const onSubmitOtp = (data: any) => {
    const { otp_code } = data

    const _data = {
      token: verify_token,
      email: email,
      otp: parseInt(otp_code),
    }

    verifyDocumentUpload(_data, setStep)
  }

  return (
    <div className='bg-[#F5F5F5] h-screen flex items-center justify-center'>
      <div className='sm:w-[450px] w-full p-6 sm:p-0 max-w-[450px] h-fit'>
        {step === 1 && (
          <>
            <h2 className='text-2xl black-text-3 text-center'>Verify Your Account</h2>
            <p className='text-sm grey-text font-light text-center mt-1'>
              Kindly input the OTP sent to your mail to enable you upload the requested documents
            </p>
            <form onSubmit={handleSubmit(onSubmitOtp)}>
              <div className='mt-20 flex flex-col items-center justify-center'>
                <div className='mt-10'>
                  {error ? <p className='bg-error p-3 text-center text-red-600 font-500 text-sm my-4 font-light rounded'>{error}</p> : <></>}
                </div>
                <CustomOtpInput control={control} name='otp_code' isRequired={true} errors={errors} />
              </div>
              <div className='mt-12'>
                <Button title='Verify' isColored={true} style={{ width: '100%', fontWeight: '500' }} loading={verifying} />
              </div>
            </form>
          </>
        )}
        {step === 2 && (
          <DocumentUploadModal id={idPart} isOpen={true} modalMode='request' docList={documents} callAction={false} closeModal={closeModal} />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  const { error, verifying, verified_data } = state.shipment
  return { error, verifying, verified_data }
}

export default connect(mapStateToProps, {
  forgotPasswordUser,
  VerifyOtpPasswordReset,
  resetPassword,
  clearErrors,
  verifyDocumentUpload,
})(OpenDocumentUpload)
