import { generateActions } from 'helpers/generateActions'

export const GET_TEAMMATES = generateActions('GET_TEAMMATES')

export const INVITE_TEAMMATES = generateActions('INVITE_TEAMMATES')

export const UPDATE_TEAMMATES = generateActions('UPDATE_TEAMMATES')

export const DELETE_TEAMMATES = generateActions('DELETE_TEAMMATES')

export const API_ERROR = 'API_ERROR'
