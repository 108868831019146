import OutlineButton from "components/buttons/OutlineButton"
import Modal from "react-modal"

interface RejectNotificationQuoteModalProps {
  close: () => void;
}

const RejectNotificationQuoteModal = ({close}: RejectNotificationQuoteModalProps) => {

  const customStyles = { 
    content: {
      top: '45%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      height: "367px",
      width: "434px",
      marginTop: "44px",
      padding: "0px",
      border: "30px",
      borderColor: "#296FD8",
      overflow: "hidden"
    },
    overlay: {
      zIndex: '90',
      backgroundColor: 'rgba(6, 24, 2, 0.55)',
    },
  }
  return (
    <Modal style={customStyles} isOpen>
       <div className="flex flex-col">
          <div className="flex-1 pt-[64px] pb-[48px]">
             <div className="bg-[#FFBAB326] rounded-[13px] flex justify-center items-center mx-auto object-center w-[80px] h-[80px]">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M30.6367 15.3322C31.2969 15.3322 31.8337 14.7954 31.8337 14.1352V10.0676C31.8337 3.95433 28.0178 0.166992 21.9426 0.166992H10.0676C3.95432 0.166992 0.166992 3.95433 0.166992 10.0723V21.9473C0.166992 28.0463 3.95432 31.8337 10.0676 31.8337H21.9473C28.0463 31.8337 31.8337 28.0463 31.8289 21.9426C31.8289 21.2744 31.2858 20.7313 30.6177 20.7313C29.9479 20.7313 29.4064 21.2744 29.4064 21.9426C29.4064 26.7464 26.7607 29.4064 21.9426 29.4064H10.0676C5.24949 29.4064 2.58949 26.7464 2.58949 21.9426V10.0676C2.58949 5.24949 5.24949 2.58949 10.0723 2.58949H21.9473C26.767 2.58949 29.4112 5.23366 29.4112 10.0676V14.0924V14.0956C29.4127 14.7637 29.9542 15.3037 30.6224 15.3037V15.3322H30.6367ZM12.5404 17.8139L11.4574 18.8969C10.9745 19.356 10.9476 20.1176 11.3973 20.61L11.4321 20.6354C11.8944 21.0977 12.6386 21.1104 13.1152 20.6639L14.1934 19.5856C14.6827 19.1296 14.7096 18.3633 14.2536 17.8725C13.796 17.3848 13.0297 17.3563 12.5404 17.8139ZM20.9761 20.4389C20.5106 20.9028 19.7585 20.9123 19.2819 20.4579L19.2218 20.3993L11.6534 12.8326C11.1911 12.3417 11.1816 11.5786 11.6329 11.0782C12.0952 10.6048 12.852 10.5953 13.3254 11.0576C13.3283 11.0595 13.3305 11.0614 13.3329 11.0636C13.3345 11.0651 13.3362 11.0668 13.3381 11.0687L16.291 14.0232L18.9779 11.3347C19.4609 10.874 20.2224 10.8787 20.7006 11.3458C20.7861 11.4313 20.8589 11.5279 20.9144 11.6356C21.1883 12.1121 21.1091 12.7106 20.7212 13.1001L18.0454 15.7744L20.9555 18.6861C21.4464 19.1501 21.4654 19.9243 20.9999 20.4136C20.9963 20.419 20.9916 20.4233 20.9868 20.4279C20.9832 20.4313 20.9795 20.4348 20.9761 20.4389Z" fill="#AD0013"/>
              </svg>
            </div>
            <div className="flex flex-col gap-y-[24px] pt-[24px]">
              <h2 className="text-center">Quote Rejected</h2>
              <p className="text-center mx-auto max-w-[291px] text-[12px] text-[#4B5563]">Your quote has been successfully rejected a new one will be created for you shortly.</p>
            </div>
            
          </div>
          <div className="px-[24px] pb-[24px]">
          <OutlineButton title='Close' loading={false} disabled={false} icon={''} onClick={close} style={{ color: '#AD0013', width: '100%' }} />
          </div>
       </div>
    </Modal>
  )
}

export default RejectNotificationQuoteModal