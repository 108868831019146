import { GET_BOOKINGS, GET_BOOKING_BY_ID, API_ERROR } from 'store/bookings/constants'

export const getBookings = (data: any) => {
  return {
    type: GET_BOOKINGS.REQUEST,
    payload: { data },
  }
}

export const getBookingById = (id: number, isPublic:boolean) => {
  return {
    type: GET_BOOKING_BY_ID.REQUEST,
    payload: { id, isPublic },
  }
}

export const getBookingByIdSuccess = (data: any) => {
  return {
    type: GET_BOOKING_BY_ID.SUCCESS,
    payload: { data },
  }
}

export const getBookingByIdError = (error: any) => {
  return {
    type: GET_BOOKING_BY_ID.FAILURE,
    payload: { error },
  }
}


export const getBookingsSuccess = (response: any) => {
  return {
    type: GET_BOOKINGS.SUCCESS,
    payload: response,
  }
}

export const BookingsApiError = (error: any) => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
