import { useState } from 'react'
import moment from 'moment'

// icons
import bigArrow from 'assets/icons/bigarrow-right.svg'
import chevronDown from 'assets/icons/chevron-down.svg'
import chevronUp from 'assets/icons/chevron-up.svg'

//components
import ShipmentNotfication from 'components/rate/ShipmentNotfication'

const BookingExportAirDoor = (props: any) => {
  const { shipment_data, bookCategory } = props

  const [accordion, setAccordion] = useState({})

  const toggleAccordion = (id) => {
    setAccordion((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  const accordionData = shipment_data?.air_cargo_details

  return (
    <>
      <div>
        <div className='grid grid-cols-1 gap-y-6 xl:gap-y-8 xl:grid-cols-3 xl:gap-x-8 mb-10 xl:mb-0'>
          <div className='col-span-2'>
            <div className='solid-br rounded'>
              <div className='pt-4 pb-4 xl:pt-[17px] xl:pb-[16px] border-solid border-b-[#F3F4F6] border-b-[1px]'>
                <div className='flex flex-col gap-y-4 xl:gap-x-0 xl:flex-row xl:justify-between xl:items-center'>
                  <p className='black-text-3 px-6 font-normal pb-4 xl:pb-0 xl:w-[45%]'>
                    {shipment_data?.shipment_type?.charAt(0).toUpperCase() + shipment_data?.shipment_type?.slice(1)}{' '}
                    {shipment_data?.shipment_transport_type === 'air_freight'
                      ? 'Air Freight Details (Door to Door)'
                      : shipment_data?.shipment_transport_type === 'ocean_freight'
                      ? '(Ocean Freight Details)'
                      : shipment_data?.shipment_transport_type === 'haulage'
                      ? '(Haulage Details)'
                      : ''}
                  </p>
                  {bookCategory === 'exportair_freight' && (
                    <div className='flex justify-between items-center gap-x-6 pr-6 xl:w-[55%] pl-6 xl:pl-0'>
                      <div>
                        <p className='text-sm font-light grey-text'>Pickup Address</p>
                        <p className='text-sm black-text-3 font-medium capitalize'>{shipment_data?.pickup_location}</p>
                      </div>
                      <div>
                        <img src={bigArrow} alt='' />
                      </div>
                      <div>
                        <p className='text-sm font-light grey-text'>Destination Address</p>
                        <p className='text-sm black-text-3 font-medium capitalize'>{shipment_data?.delivery_location}</p>
                      </div>
                    </div>
                  )}
                  {bookCategory === 'importair_freight' && (
                    <div className='flex justify-between items-center gap-x-6 pr-6 xl:w-[55%] pl-6 xl:pl-0'>
                      <div>
                        <p className='text-sm font-light grey-text'>Pickup Address</p>
                        <p className='text-sm black-text-3 font-medium'>{shipment_data?.pickup_location}</p>
                      </div>
                      <div>
                        <img src={bigArrow} alt='' />
                      </div>
                      <div>
                        <p className='text-sm font-light grey-text'>Destination Address</p>
                        <p className='text-sm black-text-3 font-medium'>{shipment_data?.delivery_location}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='p-6'>
                <>
                  <div className='grid grid-cols-2 md:grid-cols-3 text-sm gap-5 md:gap-8'>
                    <div>
                      <p className='grey-text font-light pb-1.5'>Commodity Type</p>
                      <p className='black-text-3'>
                        {shipment_data.goods_type === 'general_cargo' ? (
                          <>General Cargo</>
                        ) : (
                          shipment_data.goods_type === 'hazardous' && <>Hazardous Cargo</>
                        )}
                      </p>
                    </div>
                    <div>
                      <p className='grey-text font-light pb-1.5'>Commodity Description</p>
                      <p className='black-text-3 capitalize'>{shipment_data?.commodity_description}</p>
                    </div>
                    <div>
                      <p className='grey-text font-light pb-1.5'>Total weight of Goods</p>
                      <p className='black-text-3'>{shipment_data?.goods_weight ? shipment_data?.goods_weight : 'N/A'} KG</p>
                    </div>
                    {shipment_data?.shipment_type === 'import' ? (
                      <>
                        <div>
                          <p className='grey-text font-light pb-1.5'>Sender Name</p>
                          <p className='black-text-3 capitalize'>
                            {shipment_data?.air_freight_data?.consignee_name ? shipment_data?.air_freight_data?.consignee_name : 'N/A'}
                          </p>
                        </div>
                        <div>
                          <p className='grey-text font-light pb-1.5'>Sender Phone Number</p>
                          <p className='black-text-3'>
                            {shipment_data?.air_freight_data?.consignee_phone ? shipment_data?.air_freight_data?.consignee_phone : 'N/A'}
                          </p>
                        </div>

                        <div>
                          <p className='grey-text font-light pb-1.5'>Sender Email</p>
                          <p className='black-text-3'>
                            {shipment_data?.air_freight_data?.consignee_email ? shipment_data?.air_freight_data?.consignee_email : 'N/A'}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <p className='grey-text capitalize font-light pb-1.5'>Consignee Name</p>
                          <p className='black-text-3 capitalize'>
                            {shipment_data?.air_freight_data?.consignee_name ? shipment_data?.air_freight_data?.consignee_name : 'N/A'}
                          </p>
                        </div>
                        <div>
                          <p className='grey-text font-light pb-1.5'>Consignee Phone Number</p>
                          <p className='black-text-3'>
                            {shipment_data?.air_freight_data?.consignee_phone ? shipment_data?.air_freight_data?.consignee_phone : 'N/A'}
                          </p>
                        </div>

                        <div>
                          <p className='grey-text font-light pb-1.5'>Consignee Email</p>
                          <p className='black-text-3'>
                            {shipment_data?.air_freight_data?.consignee_email ? shipment_data?.air_freight_data?.consignee_email : 'N/A'}
                          </p>
                        </div>
                      </>
                    )}
                    <div>
                      <p className='grey-text font-light pb-1.5'>Booking Date</p>
                      <p className='black-text-3'>{shipment_data.createdAt ? moment(shipment_data.createdAt).format('DD-MM-YYYY') : 'N/A'}</p>
                    </div>

                    <div>
                      <p className='grey-text font-light pb-1.5'>Package Pick up Medium</p>
                      <p className='black-text-3'>
                        {shipment_data?.air_freight_data?.package_pickup_medium === 'dispatch_vans' ? (
                          <>Dispatch Vans</>
                        ) : (
                          shipment_data?.air_freight_data?.package_pickup_medium === 'bikes' && <>Bikes</>
                        )}
                      </p>
                    </div>

                    <div>
                      <p className='grey-text font-light pb-1.5'>Form M</p>
                      <p className='black-text-3'>{shipment_data?.form_m_number ? shipment_data?.form_m_number : 'N/A'}</p>
                    </div>

                    <div>
                      <p className='grey-text font-light pb-1.5'>Banker's Acceptance number</p>
                      <p className='black-text-3'>{shipment_data?.bankers_acceptance_number ? shipment_data?.bankers_acceptance_number : 'N/A'}</p>
                    </div>
                  </div>
                </>
              </div>
            </div>

            <div className='py-10'>
              <div className='solid-br rounded'>
                {shipment_data.air_cargo_details?.length > 1 ? (
                  <>
                    <p className='black-text-3 p-6 pb-[18px] font-normal bottom-divider-2'>
                      Cargo Details <span className='grey-text'>(Different Pallet Sizes)</span>
                    </p>
                    <div className='px-6 pb-5'>
                      {accordionData?.map((item: any, index) => {
                        return (
                          <>
                            <div className='flex mt-5' key={item._id}>
                              <div
                                className={`flex solid-br px-4 py-4 bottom-divider ${
                                  !accordion[item._id] ? 'rounded-bl rounded-br' : ''
                                } rounded-tl rounded-tr cursor-pointer`}
                                style={{ width: '-webkit-fill-available' }}
                                onClick={() => toggleAccordion(item._id)}
                              >
                                <p className='black-text-3 text-sm font-normal capitalize'>Details for Pallet {index + 1}</p>
                                <div className='ml-auto mt-2'>
                                  <img src={accordion[item?._id] ? chevronUp : chevronDown} alt='' width={16} />
                                </div>
                              </div>
                            </div>

                            {accordion[item._id] && (
                              <>
                                <div
                                  className={`grid grid-cols-2 md:grid-cols-3 text-sm p-4 pb-8 gap-8 right-divider left-divider bottom-divider ${
                                    accordion[item._id] ? 'rounded-bl rounded-br' : ''
                                  }`}
                                >
                                  <>
                                    <div>
                                      <p className='grey-text font-light pb-1.5'>Volumetric Weight (CM^3)</p>
                                      <p className='black-text-3'>{item?.container_weight ? item?.container_weight : 'N/A'}</p>
                                    </div>
                                    <div>
                                      <p className='grey-text font-light pb-1.5'>Width of Cargo (Inches)</p>
                                      <p className='black-text-3'>{item?.container_width ? item?.container_width : 'N/A'}</p>
                                    </div>
                                    <div>
                                      <p className='grey-text font-light pb-1.5'>Height of Cargo (Inches)</p>
                                      <p className='black-text-3'>{item?.container_height ? item?.container_height : 'N/A'}</p>
                                    </div>
                                    <div>
                                      <p className='grey-text font-light pb-1.5'>Length of Cargo (Inches)</p>
                                      <p className='black-text-3'>{item?.container_length ? item?.container_length : 'N/A'}</p>
                                    </div>
                                  </>
                                </div>
                              </>
                            )}
                          </>
                        )
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    <p className='black-text-3 p-6 pb-[18px] font-normal bottom-divider-2'>
                      Cargo Details <span className='grey-text'>(Same Size Pallets)</span>
                    </p>
                    <div className='grid grid-cols-2 md:grid-cols-3 text-sm gap-x-4 p-6 gap-6'>
                      <>
                        <div>
                          <p className='grey-text font-light pb-1.5'>Volumetric Weight (CM^3)</p>
                          <p className='black-text-3'>
                            {shipment_data.air_cargo_details[0]?.container_weight ? shipment_data.air_cargo_details[0]?.container_weight : 'N/A'}
                          </p>
                        </div>
                        <div>
                          <p className='grey-text font-light pb-1.5'>Width of Cargo (Inches)</p>
                          <p className='black-text-3'>
                            {shipment_data.air_cargo_details[0]?.container_width ? shipment_data.air_cargo_details[0]?.container_width : 'N/A'}
                          </p>
                        </div>
                        <div>
                          <p className='grey-text font-light pb-1.5'>Height of Cargo (Inches)</p>
                          <p className='black-text-3'>
                            {shipment_data.air_cargo_details[0]?.container_height ? shipment_data.air_cargo_details[0]?.container_height : 'N/A'}
                          </p>
                        </div>
                        <div>
                          <p className='grey-text font-light pb-1.5'>Length of Cargo (Inches)</p>
                          <p className='black-text-3'>
                            {shipment_data.air_cargo_details[0]?.container_length ? shipment_data.air_cargo_details[0]?.container_length : 'N/A'}
                          </p>
                        </div>

                        <div>
                          <p className='grey-text font-light pb-1.5'>Number of Pallets</p>
                          <p className='black-text-3'>
                            {shipment_data.air_cargo_details[0]?.container_count ? shipment_data.air_cargo_details[0]?.container_count : 'N/A'}
                          </p>
                        </div>
                      </>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className='w-full xl:w-[100%]'>
            {shipment_data?.shipment_status !== 'cancelled' && shipment_data?.shipment_status === 'new booking' && (
              <ShipmentNotfication subtext={'Please Note'} text={'Your Booking will be confirmed shortly.'} style={{}} />
            )}
            {shipment_data?.shipment_status !== 'cancelled' && shipment_data?.shipment_status === 'awaiting quotes' && (
              <ShipmentNotfication subtext={'Please Note'} text={'Your Quote will be sent shortly.'} style={{}} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default BookingExportAirDoor
