import React, { useEffect, useRef, useState } from 'react'
import Layout from 'components/layout/Layout'
import {connect} from "react-redux"
import PageLoading from 'components/partials/pageLoading'
import PageTitle from 'components/partials/PageTitle'
import QuoteAntTable from 'components/quote/quoteTable/QuoteAntTable'
import LoadingSpinner from 'components/partials/LoadingSpinner'
import { getMyQuotes, getMyQuotesSuccess, getQuotesById, } from 'store/actions'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import FilterComp from 'components/booking/FilterComp'
import filter from 'assets/icons/Filter-grey.svg'
import search from 'assets/icons/search.svg'
import divider from 'assets/icons/divider.svg'
import moment from 'moment'
import arrowR from 'assets/icons/arrow-right-green.svg'

interface Quote {
    loading: boolean;
    quote_data: any;
    my_quotes: any;
    table_loading: boolean;
    getMyQuotes: any;
    getQuotesById: any;
    getMyQuotesSuccess:any;
    total_quotes: number
}

const Quote = ({loading, table_loading, my_quotes, quote_data, getMyQuotes, getQuotesById, getMyQuotesSuccess, total_quotes}: Quote) => {

  const [filteredQuotes, setFilteredQuotes] = useState<any>(my_quotes)
  const[page, setPage] = useState(0)
  const [startNum, setStartNum] = useState(0)
  const [endNum, setEndNum] = useState(0)
   const [filterObject, setFilterObject] = useState({page: "0"}) 
   const [pageLoading, setPageLoading] = useState(false)
   const [mobilefilterDisplay, setMobileFilterDisplay] = useState(false)
   const [exportMain, setExportMain] = useState<Boolean>(false)
   const [filterDisplay, setFilterDisplay] = useState(false)
   const [importMain, setImportMain] = useState<Boolean>(false)
 
   const [modalIsOpen, setModalIsOpen] = useState(false)
 
   const [air, setAir] = useState<Boolean>(false)
   const [haulage, setHaulage] = useState<Boolean>(false)
   const [ocean, setOcean] = useState<Boolean>(false)
   const [warehouse, setWarehouse] = useState<Boolean>(false)
   const [cbt, setCBT] = useState<Boolean>(false)
   const filter_ref = useRef<HTMLDivElement>(null)
  const mobilefilter_ref = useRef<HTMLDivElement>(null)
   const [dateRange, setDateRange] = useState([null, null])
   const [startDate, endDate] = dateRange
 
   const [clear, setClear] = useState(false)
  
  useEffect(() => {
    getMyQuotes() 
  }, [])

  console.log(startNum)

  console.log(endNum)

  useEffect(() => {
   
   if(my_quotes.length !== 0){
    setFilteredQuotes(my_quotes)
   }
   else {
    setFilteredQuotes(my_quotes)
   }
  },[my_quotes])

  const navigate = useNavigate()

  const fetchBooking = () => {
    const filter_string = new URLSearchParams(filterObject).toString()
    setPageLoading(true)
    getMyQuotes(filter_string)
  }

  useEffect(() => {
    fetchBooking()
  }, [filterObject])

  const handleFilter = (value: any, type: string) => {
    console.log(value)
    if (value) {
      setFilterObject({
        ...filterObject,
        ...{
          page: '0',
          service_type: value.shipment_transport_type,
          shipment_type: value.shipment_type,
          status: value.shipment_status,
        },
      })
    }
  }

  const handleSearch = (value: string, type: string) => {
    if (value) {
      setFilterObject({ ...filterObject, ...{ page: '0', search: value } })
    } else {
      setFilterObject({ ...filterObject, ...{ page: '0', search: value } })
    }
  }

  const applyFilter = () => {
    // only export
    // exportMain && handleFilter("export", "shipment");
  }

  const handlePageChange = (page) => {
    setFilterObject({ ...filterObject, ...{ page: String(page - 1) } });
  };

  const handleCountPerPage = (page, pageCount) => {
    setFilterObject({
      ...filterObject,
      ...{ page: String(page), count: String(pageCount) },
    });
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (filterDisplay && filter_ref.current && !filter_ref.current.contains(e.target)) {
        setFilterDisplay(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [filterDisplay])

  console.log(total_quotes)

  return (
    <Layout>
       <div className='dashboard-content-scroll'>
          {loading ? (
            <div className='mt-3'>
              <PageLoading title={'Quotes'} />
            </div>
          ) : (
            <>
              <div>
                <div className='mx-7 pt-4 md:pt-10 lg:mx-14 lg:pt-10 mb-6 lg:mb-0'>
                  <PageTitle text={'Quotes'} subtext='Here’s a list of all your Quotes.' showShipmentButton={false} />
                </div>
                <div className='lg:mt-9 w-full h-fit '>
                  <div className='mt-4 w-full'>
                    <div className='desktop-only relative md:mx-7 lg:mx-14'>
                      {table_loading && (
                        <div className='absolute h-full w-full bg-[#7c8d788c] z-[999999] flex justify-center'>
                          <div className='pt-5'>
                            <LoadingSpinner top={false} height='10' loadingText='Loading table data' />
                          </div>
                        </div>
                      )}
                      <div ref={filter_ref} className='absolute w-[336px] left-[50%] ml-[-300px] z-50'>
                      <FilterComp
                          handleFilter={handleFilter}
                          filteredBookings={filteredQuotes}
                          applyFilter={applyFilter}
                          filterDisplay={filterDisplay}
                          exportMain={exportMain}
                          setExportMain={setExportMain}
                          importMain={importMain}
                          setImportMain={setImportMain}
                          air={air}
                          setAir={setAir}
                          haulage={haulage}
                          setHaulage={setHaulage}
                          ocean={ocean}
                          setOcean={setOcean}
                          warehouse={warehouse}
                          setWarehouse={setWarehouse}
                          cbt={cbt}
                          setCBT={setCBT}
                          setFilterDisplay={setFilterDisplay}
                          loading={loading}
                          dateRange={dateRange}
                          setDateRange={setDateRange}
                          startDate={startDate}
                          endDate={endDate}
                          setClear={setClear}
                          clear={clear}
                          type={'quote'}
                        />
                      </div>

                      <QuoteAntTable
                       filteredQuotes={my_quotes}
                       setFilterDisplay={setFilterDisplay}
                       filterDisplay={filterDisplay}
                       handleSearch={handleSearch}
                       total_count={total_quotes}
                       filterObject={filterObject}
                       handlePageChange={handlePageChange}
                       handleRowsPerChange={handleCountPerPage}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* mobile only */}
              {!loading && (
                <div className='mb-6 mobile-only'>
                  <div className='flex items-center justify-between sm:justify-start sm:gap-x-[16px] mx-7 '>
                    {/* search */}
                    <div className='flex items-center gap-x-1 form-input px-4 py-1.5 custom-input w-[70%] sm:w-[auto] black-text mb-5'>
                      <div className='min-w-[16px] h-[16px] '>
                        <img src={search} alt=''></img>
                      </div>
                      <input placeholder='Search Bookings' className='w-[100%]' onChange={(e) => handleSearch(e.target.value, 'search')} />
                    </div>

                    <div
                      className='cursor-pointer px-4 flex items-center gap-x-1 border border-[#e5e7eb] rounded w-[28%] sm:w-[auto] h-[37.6px] mt-[-20px]'
                      onClick={() => setMobileFilterDisplay(true)}
                    >
                      <div className='min-w-[16px] h-[16px] '>
                        <img src={filter} alt='' className='w-[100%]'></img>
                      </div>
                      <p className='text-xs text-[#9CA3AF]'>Filter</p>
                    </div>
                  </div>

                  {/* filter page */}

                  <div className='fixed top-0 w-full'>
                           <FilterComp
                          handleFilter={handleFilter}
                          filteredBookings={filteredQuotes}
                          applyFilter={applyFilter}
                          filterDisplay={mobilefilterDisplay}
                          exportMain={exportMain}
                          setExportMain={setExportMain}
                          importMain={importMain}
                          setImportMain={setImportMain}
                          air={air}
                          setAir={setAir}
                          haulage={haulage}
                          setHaulage={setHaulage}
                          ocean={ocean}
                          setOcean={setOcean}
                          warehouse={warehouse}
                          setWarehouse={setWarehouse}
                          cbt={cbt}
                          setCBT={setCBT}
                          setFilterDisplay={setMobileFilterDisplay}
                          loading={loading}
                          dateRange={dateRange}
                          setDateRange={setDateRange}
                          startDate={startDate}
                          endDate={endDate}
                          setClear={setClear}
                          clear={clear}
                          type={'quote'}
                        />
                  </div>

              
              
                {table_loading ?
                <PageLoading title='Quotes'/>
                :
               <div className="w-[100%]  overflow-x-auto  mt-3 mb-3 px-[20px]">
                      
                           {my_quotes.length > 0 ? my_quotes?.map((data, index) => (
                              <div
                              className={`bg-white mx-7 border border-grey-3 px-4 py-6 ${index === 0 ? 'top-divider-2' : ''}`}
                              onClick={() => {
                                navigate(`/quote/${data?.shipment_quotes_details?.['_id']}`)
                              }}
                            >
                              {/* awaiting */}
                              <div
                                className={`py-1 px-1.5 rounded-full mt-3 mb-3 w-fit ${
                                  data.shipment_quotes_details.status === 'pending'
                                    ? 'background-blue'
                                    : data.shipment_quotes_details.status === 'rejected'
                                      ? 'bg bg-red-300'
                                      : data.shipment_quotes_details.status === 'awaiting quotes'
                                        ? 'bg-[#FFFADF]'
                                        : data.shipment_quotes_details.status === 'accepted'
                                          ? 'background-green'
                                          : ''
                                }`}
                              >
                                <p
                                  className={`text-xs capitalize text-center ${
                                    data.shipment_quotes_details.status === 'pending'
                                      ? 'text-[#4B83F0]'
                                      : data.shipment_quotes_details.status === 'accepted'
                                        ? 'text-[#059C01]'
                                        : data.shipment_quotes_details.status === 'awaiting quotes'
                                          ? 'text-[#C27500]'
                                          : data.shipment_quotes_details.status === 'rejected'
                                            ? 'text-red-600'
                                            : ''
                                  }`}
                                >
                                  {data.shipment_quotes_details.status}
                                </p>
                              </div>
  
                              <div className='flex gap-[15px] items-center mb-3'>
                                {/* shipment type */}
                                <div>
                                  {data?.shipment_type === 'export' && <p className='text-lg black-text-3 font-medium'>Export</p>}
                                  {data?.shipment_type === 'import' && <p className='text-lg black-text-3 font-medium'>Import</p>}
                                </div>
                                <img src={divider} alt='divider' />
                                <div>
                                  {data.shipment_transport_type === 'ocean_freight' && <p>Ocean Freight</p>}
                                  {data.shipment_transport_type === 'air_freight' && <p>Air Freight</p>}
                                  {data.shipment_transport_type === 'haulage' && <p>Haulage</p>}
                                  {data.shipment_transport_type === 'warehousing' && <p>Warehousing</p>}
                                  {data.shipment_transport_type === 'customs_brokerage' && <p>Custom Brokerage</p>}
                                </div>
                              </div>
  
                              <div className='flex mb-2 justify-between'>
                                <p className='text-[#6B7280] text-sm'>{moment(data.createdAt).format('DD/MM/YYYY')}</p>
                                <div className='cursor-pointer'>
                                  <img src={arrowR} alt='' />
                                </div>
                              </div>
  
                              {/* line */}
                              <div className='w-[80%] h-[1px] bg-[#F3F4F6] my-4'></div>
  
                              {/* origin and destination */}
                            </div>
                              )): (
                                <span>
                                  No Data Avaliable
                                </span>
                              )}
                    
                    
                    {my_quotes.length > 0 && (
                    <div className='mt-4 mx-7  py-6 flex justify-between'>
                      {/* left */}
                      {page === 0 ? (
                        <div className='rotate-180 w-[24px] h-[24px]' onClick={() => {}}>
                          <svg
                            className='w-[100%] h-[100%]'
                            width='14'
                            height='14'
                            viewBox='0 0 14 14'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M4.95703 2.91732L9.04036 7.00065L4.95703 11.084'
                              stroke='#d6dfed'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                          </svg>
                        </div>
                      ) : (
                        <div
                          className='rotate-180 w-[24px] h-[24px]'
                          onClick={() => {
                            handlePageChange(page)
                            setPage(page - 1)
                            setStartNum(startNum - 10)
                            setEndNum(endNum - 10)
                          }}
                        >
                          <svg
                            className='w-[100%] h-[100%]'
                            width='14'
                            height='14'
                            viewBox='0 0 14 14'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M4.95703 2.91732L9.04036 7.00065L4.95703 11.084'
                              stroke='#374151'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                          </svg>
                        </div>
                      )}

                      {/* middle */}
                      <div>
                        <p>
                          {startNum}-{my_quotes.length >= 10 ? endNum : endNum - (10 - (my_quotes.length % 10))} of {total_quotes}
                        </p>
                      </div>

                      {/* right */}
                      {page >= Math.ceil(total_quotes / 10 - 1) ? (
                        <div className='w-[24px] h-[24px]' onClick={() => {}}>
                          <svg
                            className='w-[100%] h-[100%]'
                            width='14'
                            height='14'
                            viewBox='0 0 14 14'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M4.95703 2.91732L9.04036 7.00065L4.95703 11.084'
                              stroke='#d6dfed'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                          </svg>
                        </div>
                      ) : (
                        <div
                          className='w-[24px] h-[24px] '
                          onClick={() => {
                            handlePageChange(page + 2)
                            setPage(page + 1)
                            setStartNum(startNum + 10)
                            setEndNum(my_quotes.length >= 10 ? endNum + 10 : endNum - (10 - (my_quotes.length % 10)))
                          }}
                        >
                          <svg
                            className='w-[100%] h-[100%]'
                            width='14'
                            height='14'
                            viewBox='0 0 14 14'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M4.95703 2.91732L9.04036 7.00065L4.95703 11.084'
                              stroke='#374151'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  )}
               </div> 
               }
                </div>
              )}
            </>
          )}
        </div>
    </Layout>
  )

}

const mapStateToProps = (state: any) => {
    const { loading, quote_data, my_quotes, table_loading, total_quotes } = state.quote
    return { loading, quote_data, my_quotes, table_loading, total_quotes }
  }

  export default connect(mapStateToProps, {
    getMyQuotes,
    getMyQuotesSuccess,
    getQuotesById,
  })(Quote)