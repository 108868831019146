import React, { FC } from 'react'
// import "./index.scss";

const Spinner = () => <div className='spinner mr-2'></div>

interface Props {
  title: string
  style?: object
  onClick?: any
  loading?: boolean
  disabled: boolean
  icon?: any
  class_names?: string
  iconRight?: boolean
  isExtraText?: boolean
}
const PrimaryButtons: FC<Props> = (props): JSX.Element => {
  const { title, style = {}, onClick, loading = false, disabled = false, iconRight = false, isExtraText = false, icon, class_names } = props
  return (
    <div>
      <button
        className={`btn bg-green-2 ${(loading || disabled) && 'opacity-50'} white-text text-sm py-3 w-full rounded ${class_names}`}
        type='submit'
        style={style}
        onClick={onClick}
        disabled={loading || disabled}
      >
        <div className={`flex items-center ${isExtraText ? 'justify-between' : 'justify-center'}  px-4`}>
          {loading && <Spinner />}
          {isExtraText && <span className={`text-transparent ${loading ? 'hidden' : ''}`}>.</span>}
          {icon ? <img src={icon} alt='cross' className={`${iconRight ? 'mr-2 order-1 ml-2' : 'mr-2'}`} /> : <></>}
          {title}
        </div>
      </button>
    </div>
  )
}

export default PrimaryButtons
