import { Link } from 'react-router-dom'
import Modal from 'react-modal'

// icons
import customer from 'assets/icons/customer.svg'
import agent from 'assets/icons/agent.svg'
import close from 'assets/icons/close.svg'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: '30px 30px',
    maxHeight: 'calc(100vh - 100px)',
    overflow: 'hidden',
    // WebkitOverflowScrolling: "touch",
    transform: 'translate(-50%, -50%)',
    width: 'calc(100vw - 10%)',
    borderRadius: '10px',
    border: '0.01px solid #888',
  },
  overlay: {
    zIndex: '99999999999',
    backgroundColor: 'rgba(6, 24, 2, 0.55)',
  },
}

interface ModalProps {
  type: string
  modalIsOpen?: any
  toDashboard?: any
  setShowModal?: any
  closeModal?: any
}

const MobileModalAuth = ({ type, modalIsOpen, closeModal, setShowModal }: ModalProps) => {
  interface AgentUrls {
    [key: string]: string
  }
  const agentUrls: AgentUrls = {
    Development: 'https://test-agent.oneport365.com',
    Staging: 'https://staging-agent.oneport365.com',
    Production: 'https://agent.oneport365.com',
  }

  // const agentUrl = (process.env.REACT_APP_STAGE && agentUrls[process.env.REACT_APP_STAGE]) || null
  const agentUrl = process.env.REACT_APP_AGENT_BASE_URL
  console.log(agentUrl)
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={false}
        style={customStyles}
        className={'successmodal'}
        contentLabel='Mobile Auth Modal'
      >
        <div className='flex flex-col'>
          <div className='flex justify-end cursor-pointer w-fit ml-auto' onClick={closeModal}>
            <img src={close} alt='' />
          </div>
          {type === 'signin' && (
            <div>
              <Link
                to='/signin'
                onClick={() => setShowModal(false)}
                className='flex items-center gap-x-4 px-4 cursor-pointer py-3 hover:bg-[#98ff9b1a]'
              >
                <img src={customer} alt='' />
                <div>
                  <p className='black-text-2 font-bold text-sm mb-1'>Customer</p>
                </div>
              </Link>
              <Link
                to={`${agentUrl}`}
                target='_blank'
                rel='noreferrer'
                onClick={setShowModal(false)}
                className='flex items-center gap-x-4 px-4 cursor-pointer py-3 hover:bg-[#98ff9b1a]'
              >
                <img src={agent} alt='' />
                <div>
                  <p className='black-text-2 text-sm font-bold mb-1'>Agent</p>
                </div>
              </Link>
            </div>
          )}
          {type === 'signup' && (
            <div>
              <Link
                to='/signup'
                onClick={() => setShowModal(false)}
                className='flex items-center gap-x-4 px-4 cursor-pointer py-3 hover:bg-[#98ff9b1a]'
              >
                <img src={customer} alt='' />
                <div>
                  <p className='black-text-2 text-sm font-medium mb-1'>Are you a Customer?</p>
                  <p className='max-w-[185px] grey-text font-light text-xs'>Enjoy effortless and stress-free shipments on OnePort365.</p>
                </div>
              </Link>
              <Link
                to={`${agentUrl}/signup`}
                target='_blank'
                rel='noreferrer'
                onClick={() => setShowModal(false)}
                className='flex items-center gap-x-4 px-4 cursor-pointer py-3 hover:bg-[#98ff9b1a]'
              >
                <img src={agent} alt='' />
                <div>
                  <p className='black-text-2 text-sm font-medium mb-1'>Are you an Agent ?</p>
                  <p className='max-w-[185px] grey-text font-light text-xs'>Boost your income while helping your customers.</p>
                </div>
              </Link>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default MobileModalAuth
