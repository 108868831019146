import React from 'react'
import logo from '../../assets/logos/oneport-logo.svg';

const GhanaQuoteAddress = () => {
  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between gap-5">
      <img src={logo} alt="oneport logo" className=" min-w-[200px]" />
      <div className="flex flex-col text-[#6B7280] text-[16px] items-end">
        <span></span>
        <span>1st Floor, Meridian Heights Building</span>
        <span className=" lg:text-end">Community 1</span>
        <span>Tema</span>
      </div>
    </div>
     </div>
  )
}

export default GhanaQuoteAddress