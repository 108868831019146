import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

//libraries
import { connect } from 'react-redux'

//types
import { Document } from 'components/shipment/type'

// redux actions
import { getShipmentById, getBookingSummary, getAirTrackingById } from 'store/actions'

//components
import Layout from 'components/layout/Layout'
import PageLoading from 'components/partials/pageLoading'
import CustomTabs from 'components/customTabs/CustomTabs'
import ShipmentTitleUpload from 'components/shipment/ShipmentTitleUpload'
import ShipmentDetails from 'pages/shipment/ShipmentsDetails'
import ShipmentDetailAir from 'components/shipment/ShipmentDetailAir'
import ShipmentDetailCBT from 'components/shipment/ShipmentDetailCBT'
import ShipmentDetailHaulage from 'components/shipment/ShipmentDetailHaulage'
import ShipmentDetailWarehousing from 'components/shipment/ShipmentDetailWarehousing'
import DocumentUploadModal from 'components/shipment/DocumentUploadModal'
import UploadedDocuments from 'components/shipment/UploadedDocuments'
import ShipmentUploadSearch from 'components/shipment/ShipmentUploadSearch'

const ShipmentDetailType = ({ shipment_data, props }) => {
  return (
    <>
      {shipment_data.shipment_transport_type === 'ocean_freight' ? (
        <ShipmentDetails shipCategory={shipment_data.shipment_type + shipment_data.shipment_transport_type} {...props} />
      ) : shipment_data.shipment_transport_type === 'air_freight' ? (
        <ShipmentDetailAir shipCategory={shipment_data.shipment_type + shipment_data.shipment_transport_type} {...props} />
      ) : shipment_data.shipment_transport_type === 'haulage' ? (
        <ShipmentDetailHaulage shipCategory={shipment_data.shipment_type + shipment_data.shipment_transport_type} {...props} />
      ) : shipment_data.shipment_transport_type === 'warehousing' ? (
        <ShipmentDetailWarehousing shipCategory={shipment_data.shipment_type + shipment_data.shipment_transport_type} {...props} />
      ) : shipment_data.shipment_transport_type === 'customs_brokerage' ? (
        <ShipmentDetailCBT shipCategory={shipment_data.shipment_type + shipment_data.shipment_transport_type} {...props} />
      ) : null}
    </>
  )
}

const ShipmentDetailsRoot = (props: any) => {
  const location = useLocation()
  const state = location.state

  const params = useParams()
  const { id } = params

  const navigate = useNavigate()

  const {
    getShipmentById,
    getAirTrackingById,
    getting_shipments,
    loading,
    shipment_data,
    getBookingSummary,
    booking_summary,
    error,
    airtracking_loading,
    airtrack_data,
  } = props

  useEffect(() => {
    id && getShipmentById(id, false)
    id && getBookingSummary(id)
    id && getAirTrackingById(id)
  }, [])

  // console.log("error>>>", error);
  // console.log("airdata>>>", airtrack_data)

  // if (error) {
  // 	navigate("/invalidshipment");
  // }

  //modal states
  const [isOpen, setIsOpen] = useState(false)

  //modals functions
  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const [activeTab, setActiveTab] = useState('Shipment Details')
  const tab_result = ['Shipment Details', 'Uploaded Documents']

  const [modalMode, setModalMode] = useState('')
  const [updateDocDetails, setUpdateDocDetails] = useState({})

  const [filteredDocuments, setFilteredDocuments] = useState<Document[]>(shipment_data?.document_details)
  useEffect(() => {
    setFilteredDocuments(shipment_data?.document_details)
  }, [shipment_data?.document_details])

  console.log('filteredDocc>>>', filteredDocuments)

  const [components] = useState([
    {
      id: 1,
      component: <ShipmentDetailCBT shipCategory={state?.ship_category} />,
      service: 'customs_brokerage',
    },
    {
      id: 2,
      component: <ShipmentDetailHaulage shipCategory={state?.ship_category} />,
      service: 'haulage',
    },
    {
      id: 3,
      component: <ShipmentDetailAir shipCategory={state?.ship_category} />,
      service: 'air_freight',
    },
    {
      id: 4,
      component: <ShipmentDetails shipCategory={state?.ship_category} />,
      service: 'ocean_freight',
    },
    {
      id: 5,
      component: <ShipmentDetailWarehousing shipCategory={state?.ship_category} />,
      service: 'warehousing',
    },
  ])

  const [activeComponent, setActiveComponent] = useState<any>({
    id: 1,
    component: <ShipmentDetails />,
    service: 'ocean_freight',
  })

  useEffect(() => {
    // if (!state?.ship_category) {
    //     navigate('/dashboard')
    //     return;
    // }
    if (shipment_data?.shipment_transport_type && !loading) {
      setActiveComponent((getPrev: any) => components.find((item: any) => item.service === shipment_data?.shipment_transport_type))
    }
    // if (!components.find((item: any) => item.shipmentCategory === state?.shipmentCategory)) {
    //#ZILOS23050032
    // }
  }, [])

  return (
    <Layout>
      {loading || getting_shipments || airtracking_loading ? (
        <PageLoading title='shipment details' />
      ) : (
        <main className='px-4 pt-8 lg:pt-10 lg:px-10 dashboard-content-scroll'>
          <div>
            <ShipmentTitleUpload shipment_data={shipment_data} />
            <div className='mb-8 mt-8 flex items-end'>
              <CustomTabs tabs={tab_result} activeTab={activeTab} setActiveTab={setActiveTab} />
              <div className='border-b border-[1px] border-[#E5E7EB] w-1/2 flex-1' />
            </div>
          </div>

          {activeTab === tab_result[1] && (
            <ShipmentUploadSearch openModal={openModal} documents={shipment_data?.document_details} setFilteredDocuments={setFilteredDocuments} />
          )}

          {activeTab === tab_result[0] && <ShipmentDetailType shipment_data={shipment_data} props={props} />}

          {activeTab === tab_result[1] && (
            <UploadedDocuments
              documents={shipment_data?.document_details}
              setModalMode={setModalMode}
              openUploadModal={setIsOpen}
              setUpdateDocDetails={setUpdateDocDetails}
              filteredDocuments={filteredDocuments}
            />
          )}

          <DocumentUploadModal
            id={id}
            isOpen={isOpen}
            closeModal={closeModal}
            shipCategory={shipment_data.shipment_type + shipment_data.shipment_transport_type}
            documents={shipment_data?.document_details}
            modalMode={modalMode}
            setModalMode={setModalMode}
            updateDocDetails={updateDocDetails}
            setUpdateDocDetails={setUpdateDocDetails}
          />
        </main>
      )}
    </Layout>
  )
}

//ZILOS23050032

const mapStateToProps = (state) => {
  const { error, getting_shipments, shipment_data, shipment_id_data, container_details, loading, live_rates, airtracking_loading, airtrack_data } =
    state.booking

  const { booking_summary } = state.additionalDetails

  return {
    error,
    getting_shipments,
    shipment_data,
    container_details,
    shipment_id_data,
    live_rates,
    loading,
    booking_summary,
    airtracking_loading,
    airtrack_data,
  }
}

export default connect(mapStateToProps, { getShipmentById, getBookingSummary, getAirTrackingById })(ShipmentDetailsRoot)
