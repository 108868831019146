import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import { connect } from 'react-redux'

//icons
import pencil from 'assets/icons/pencil.svg'
import port from 'assets/icons/home-port.svg'
import destination from 'assets/icons/location-pin.svg'
import haulageTruck from 'assets/icons/haulage-truck.svg'

//libraries
import { useForm } from 'react-hook-form'
import uniqid from 'uniqid'

//components
import Layout from 'components/layout/Layout'

//redux actions
import { getBookingDetailsById, confirmBooking, clearBooking, getShipmentById } from 'store/actions'

import AirFreightWeightInfo from 'components/airfreight/AirFreightWeightInfo'
import AirFreightTitle from 'components/airfreight/AirFreightTitle'
import PageLoading from 'components/partials/pageLoading'
import AirFreightShipmentDetails from 'components/airfreight/AirFreightShipmentDetails'

import PalletForm from './afapPartials/palletAddition/palletForm'
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import SecondaryButtons from 'components/buttons/SecondaryButtons'
import SuccessModal from 'components/partials/SuccessModal'
import BookingSummaryModal from './afapPartials/bookingSummaryModal'

function InAirFreightDetails(props: any) {
  const { loading, airFreightCategory, getting_booking, booking_data, getBookingDetailsById, confirming_booking, confirmBooking, clearBooking } =
    props

  const {
    handleSubmit,
    control,
    resetField,
    formState: { errors },
    reset,
    watch,
  } = useForm({ mode: 'onChange' || 'onSubmit' })
  const _Json = require('sea-ports')

  const navigate = useNavigate()
  const location = useLocation()

  console.log('location', booking_data?.air_freight_data?.air_freight_type)

  const params = useParams()
  const { id } = params

  console.log('booker', id)
  console.log('first....', errors)

  const [showSummaryModal, setShowSummaryModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  // const showSuccessModal = () => {
  //     setShowSuccess(true)
  // }

  const openBookingSummaryModal = () => {
    setShowSummaryModal(true)
  }

  const closeBookingSummaryModal = () => {
    setShowSummaryModal(false)
  }

  const closeSuccessModal = () => {
    setShowSuccessModal(false)
  }

  const onConfirmed = () => {
    closeBookingSummaryModal()
    setShowSuccessModal(true)
  }

  useEffect(() => {
    id && getBookingDetailsById(id)
  }, [id])

  const [cargoVolume, setCargoVolume] = useState<Record<string, string>>({}) // Specify the type for cargoVolume state
  const calculateCargoVolume = (height: string, width: string, length: string, uid: string) => {
    const volume = ((parseFloat(height) * parseFloat(width) * parseFloat(length)) / 6000).toFixed(2)
    // Update the state for cargoVolume with the unique identifier
    setCargoVolume((prevState) => ({ ...prevState, [uid]: volume.toString() })) // Ensure volume is converted to string
  }

  const [freightDetails, setFreightDetails] = useState({})

  const [palletSize, setPalletSize] = useState(true)
  const [additionalPallet, setAdditionalPallet] = useState([{ uid: uniqid() }])
  const [clickCount, setClickCount] = useState(1)

  const airfreight_map = {
    port_to_port: 'porttoport',
    door_to_port: 'doortoport',
    port_to_door: 'porttodoor',
    door_to_door: 'doortodoor',
  }

  const addNewPallet = () => {
    const newPallet = {
      uid: uniqid(),
    }
    setAdditionalPallet([...additionalPallet, newPallet])
    setClickCount(clickCount + 1)
  }

  const removePallet = (index: string) => {
    clickCount > 1 &&
      setAdditionalPallet((previousPallet) => {
        return previousPallet.filter((data, i) => index !== data['uid'])
      })
    clickCount > 1 && setClickCount(clickCount - 1)
  }

  const removeLastPallet = () => {
    palletSize
      ? additionalPallet.length > 1 && setAdditionalPallet(additionalPallet.slice(0, -1))
      : additionalPallet.length > 2 && setAdditionalPallet(additionalPallet.slice(0, -1))

    palletSize ? clickCount > 1 && setClickCount(clickCount - 1) : clickCount > 2 && setClickCount(clickCount - 1)
  }

  const resetPallet = () => {
    !palletSize ? setAdditionalPallet([{ uid: uniqid() }]) : setAdditionalPallet([{ uid: uniqid() }, { uid: uniqid() }])
  }

  const bookingConfirmation = (call_data, booking_id) => {
    confirmBooking(
      {
        ...call_data,
        shipment_id: booking_id,
      },
      onConfirmed
    )
  }

  const container_details: any = []

  interface PalletItem {
    uid: string
  }
  const onSubmit = (data: any) => {
    // @ts-ignore
    additionalPallet.forEach((item: PalletItem, index: number) => {
      const container_volume = cargoVolume[item.uid]
      container_details.push({
        container_length: data[`container_length_${item['uid']}`],
        container_width: data[`container_width_${item['uid']}`],
        container_height: data[`container_height_${item['uid']}`],
        container_weight: container_volume,
      })

      setFreightDetails({
        ...data,
        ...booking_data,
        container_details: container_details,
        air_freight_type: airfreight_map[booking_data?.air_freight_data?.air_freight_type],
        clickCount: clickCount,
      })
      return container_details
    })

    openBookingSummaryModal()
  }

  const air_freight_type = booking_data?.air_freight_data?.air_freight_type
  return (
    <Layout>
      <main className='px-4 pt-8 lg:pt-10 lg:px-10 dashboard-content-scroll'>
        {getting_booking ? (
          <PageLoading title='air freight details' />
        ) : (
          <div className='w-full lg:w-4/5'>
            <AirFreightTitle />
            <AirFreightWeightInfo />
            <div className='pb-8 border-b-[0px] border-solid border-[#EAEFEB] mt-6'>
              <div className='rounded solid-br bg-[#109b320d]'>
                <div className='pl-8 py-5 pr-6 flex items-start border-b-[1px] border-solid border-[#e5e7eb]'>
                  <div>
                    <h2 className='black-text-3 text-lg font-normal'>Air Freight details</h2>
                    <p className='black-text-4 text-sm font-light'>Please provide the required information</p>
                  </div>
                  <Link
                    to={`/air-freight-details/${id}`}
                    state={{
                      air_freight_type: air_freight_type,
                    }}
                    className='flex items-center gap-1 ml-auto text-base'
                  >
                    <img src={pencil} alt='' />
                    <span className='green-text hidden lg:block'>Edit details</span>
                  </Link>
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-3 gap-y-6 px-8 py-6'>
                  <div className='lg:border-r-[1px] border-solid border-[#e5e7eb]'>
                    <div className='flex items-center gap-2'>
                      <span>
                        <img className='min-w-[32px]' src={port} alt='' />
                      </span>
                      <div className=''>
                        <p className='grey-text font-light text-sm mb-1'>
                          {booking_data?.air_freight_data?.air_freight_type === 'door_to_door' ||
                          booking_data?.air_freight_data?.air_freight_type === 'door_to_port' ? (
                            <div className='flex justify-between items-center gap-x-6 xl:pl-0'>
                              <div>
                                <p className='text-sm font-light grey-text'>Pickup Address</p>
                                <p className='text-sm black-text-3 font-medium capitalize'>{booking_data?.pickup_location}</p>
                              </div>
                            </div>
                          ) : booking_data?.air_freight_data?.air_freight_type === 'port_to_port' ||
                            booking_data?.air_freight_data?.air_freight_type === 'port_to_door' ? (
                            <div className='flex justify-between items-center gap-x-6 xl:pl-0'>
                              <div>
                                <p className='text-sm font-light grey-text'>Pickup Airport</p>
                                <p className='text-sm black-text-3 font-medium capitalize'>{booking_data?.origin_port_code}</p>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </p>
                        <p className='black-text-4 text-sm font-medium'></p>
                      </div>
                    </div>
                  </div>
                  <div className='lg:border-r-[1px] border-solid border-[#e5e7eb] lg:pl-4'>
                    <div className='flex items-center gap-2'>
                      <span>
                        <img className='min-w-[32px]' src={destination} alt='' />
                      </span>
                      <div className=''>
                        <p className='grey-text font-light text-sm mb-1'>
                          {booking_data?.air_freight_data?.air_freight_type === 'port_to_port' ||
                          booking_data?.air_freight_data?.air_freight_type === 'door_to_port' ? (
                            <div className='flex justify-between items-center gap-x-6 xl:pl-0'>
                              <div>
                                <p className='text-sm font-light grey-text'>Destination Airport</p>
                                <p className='text-sm black-text-3 font-medium capitalize'>{booking_data?.destination_port_code}</p>
                              </div>
                            </div>
                          ) : booking_data?.air_freight_data?.air_freight_type === 'port_to_door' ||
                            booking_data?.air_freight_data?.air_freight_type === 'door_to_door' ? (
                            <div className='flex justify-between items-center gap-x-6 xl:pl-0'>
                              <div>
                                <p className='text-sm font-light grey-text'>Destination Address</p>
                                <p className='text-sm black-text-3 font-medium capitalize'>{booking_data?.delivery_location}</p>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='lg:pl-4 flex flex-col'>
                    <div className='flex items-center gap-2'>
                      <span>
                        <img className='min-w-[32px]' src={haulageTruck} alt='' />
                      </span>
                      <div className=''>
                        <p className='grey-text font-light text-sm mb-1'>Air Freight Type</p>
                        <p className='black-text-4 text-sm font-medium'>
                          {/* {booking_data?.air_freight_data?.air_freight_type} */}
                          {booking_data?.air_freight_data?.air_freight_type === 'port_to_port' ? (
                            <>Port To Port</>
                          ) : booking_data?.air_freight_data?.air_freight_type === 'door_to_door' ? (
                            <>Door To Door</>
                          ) : booking_data?.air_freight_data?.air_freight_type === 'door_to_port' ? (
                            <>Door To Port</>
                          ) : (
                            booking_data?.air_freight_data?.air_freight_type === 'port_to_door' && <>Port To Door</>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              {Object.keys(booking_data).length > 0 && (
                <AirFreightShipmentDetails
                  airFreightCategory={airFreightCategory}
                  control={control}
                  errors={errors}
                  booking_data={booking_data}
                  addNewPallet={addNewPallet}
                  removePallet={removePallet}
                  removeLastPallet={removeLastPallet}
                  clickCount={clickCount}
                  setClickCount={setClickCount}
                  palletSize={palletSize}
                  setPalletSize={setPalletSize}
                  setCargoVolume={setCargoVolume}
                  reset={reset}
                  resetPallet={resetPallet}
                />
              )}

              <div>
                {additionalPallet.map((item: any, index: number) => {
                  return (
                    <div key={item.uid}>
                      <PalletForm
                        uid={item.uid}
                        index={index}
                        watch={watch}
                        control={control}
                        errors={errors}
                        removePallet={removePallet}
                        calculateCargoVolume={calculateCargoVolume}
                        cargoVolume={cargoVolume}
                        setCargoVolume={setCargoVolume}
                        palletSize={palletSize}
                        setPalletSize={setPalletSize}
                        firstPallet={additionalPallet[0]}
                      />
                    </div>
                  )
                })}
              </div>

              {/* <div className="mt-9 pb-16 bottom-divider">
                                <PalletAddition
                                    type="button"
                                    title="Add New Pallet"
                                    icon={add}
                                    onClick={addNewPallet}
                                    customOnChange
                                />
                            </div> */}
              <hr />
              <div className='flex justify-end gap-x-6 mt-8'>
                <SecondaryButtons
                  title='Cancel'
                  style={{ padding: '12px 14px' }}
                  onClick={() => {
                    navigate('/dashboard')
                    clearBooking()
                  }}
                  disabled={false}
                  loading={false}
                  type={'button'}
                  icon={''}
                />
                <PrimaryButtons
                  title='Confirm'
                  style={{}}
                  // onClick={() => setShowSummaryModal(true)}
                  disabled={false}
                  loading={confirming_booking}
                  icon={''}
                />
              </div>
            </form>
          </div>
        )}
      </main>

      <BookingSummaryModal
        bookingId={id}
        freightDetails={freightDetails}
        confirming_booking={confirming_booking}
        showSummaryModal={showSummaryModal}
        bookingConfirmation={bookingConfirmation}
        openBookingSummaryModal={openBookingSummaryModal}
        closeBookingSummaryModal={closeBookingSummaryModal}
        setShowSummaryModal={closeBookingSummaryModal}
        setShowSuccessModal={setShowSuccessModal}
      />

      {showSuccessModal ? (
        <SuccessModal
          modalIsOpen={showSuccessModal}
          closeModal={closeSuccessModal}
          heading='Booking Completed!!!'
          text='You have successfully completed your booking process. You will receive a quote shortly.'
        />
      ) : null}
    </Layout>
  )
}

// export default InAirFreightDetails

const mapStateToProps = (state) => {
  const { error, getting_booking, booking_data } = state.booking
  const { loading, booking_summary, confirming_booking } = state.additionalDetails

  return { error, getting_booking, booking_data, booking_summary, confirming_booking }
}

export default connect(mapStateToProps, { getBookingDetailsById, confirmBooking, clearBooking, getShipmentById })(InAirFreightDetails)
