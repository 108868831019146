//icons
import deleteIcon from 'assets/icons/delete.svg'

//component
import CustomInput from 'components/textInputs/NewCustomInput'

var converter = require('number-to-words')

interface PalletFormProps {
  errors: any
  control: any
  watch: any
  airFreightCategory?: string
  booking_data?: any
  cargoVolume?: any
  calculateCargoVolume?: any
  removePallet: any
  index: any
  uid: any
  setCargoVolume?: any
  palletSize?: any
  setPalletSize?: any
  firstPallet?: any
}

const PalletForm = ({
  cargoVolume,
  calculateCargoVolume,
  watch,
  control,
  errors,
  index,
  uid,
  removePallet,
  booking_data,
  setCargoVolume,
  palletSize,
  setPalletSize,
  firstPallet,
}: PalletFormProps) => {
  return (
    <>
      <div className='grid grid-cols-2 gap-x-5'>
        <div>
          <p className='text-base black-text-3 py-5'>
            <span className='text-xl black-text-2 font-medium mt-4'>
              Cargo Volumetric Weight
              {palletSize === true ? (
                <></>
              ) : (
                <span className='text-sm'>
                  {' '}
                  (
                  {converter
                    .toWordsOrdinal(index + 1)
                    .charAt(0)
                    .toUpperCase() + converter.toWordsOrdinal(index + 1).slice(1)}{' '}
                  Cargo)
                </span>
              )}
            </span>
          </p>
        </div>
        {index === 0 || index === 1 ? (
          <></>
        ) : (
          <>
            <div className='flex items-center gap-x-2 cursor-pointer ml-auto' onClick={() => removePallet(uid)}>
              <>
                <div>
                  <img src={deleteIcon} alt='' />
                </div>
                <span className='text-xs red-text font-medium'>Remove Cargo</span>
              </>
            </div>
          </>
        )}
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 mb-5'>
        <CustomInput
          control={control}
          name={`container_height_${uid}`}
          id={`container_height_${uid}`}
          label={'Height of Cargo (CM)'}
          placeholder={''}
          isRequired={true}
          type={'number'}
          errors={errors}
          isDisabled={false}
          defaultValue={palletSize ? watch(`container_height_${uid}`) : ''}
          min={''}
          max={''}
          icon=''
          customOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            calculateCargoVolume(e.target.value, watch(`container_width_${uid}`), watch(`container_length_${uid}`), uid)
          }
        />

        <CustomInput
          control={control}
          name={`container_width_${uid}`}
          id={`container_width_${uid}`}
          label={'Width of Cargo (CM)'}
          placeholder={''}
          isRequired={true}
          type={'number'}
          errors={errors}
          isDisabled={false}
          defaultValue={palletSize ? watch(`container_width_${uid}`) : ''}
          min={''}
          max={''}
          icon=''
          customOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            calculateCargoVolume(watch(`container_height_${uid}`), e.target.value, watch(`container_length_${uid}`), uid)
          }
        />

        <CustomInput
          control={control}
          name={`container_length_${uid}`}
          id={`container_length_${uid}`}
          label={'Length of Cargo (CM)'}
          placeholder={''}
          isRequired={true}
          type={'number'}
          errors={errors}
          isDisabled={false}
          defaultValue={palletSize ? watch(`container_length_${uid}`) : ''}
          min={''}
          max={''}
          icon=''
          customOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            calculateCargoVolume(watch(`container_height_${uid}`), watch(`container_width_${uid}`), e.target.value, uid)
          }
        />

        <div>
          <p className='text-[#6B7280] font-light mb-1'>Calculated Volumetric Weight (CM³)</p>
          <span>
            <p className='bg-[#E5E7EB] rounded-md new-solid-br p-2.5 font-light text-[#1F2937]'>{isNaN(cargoVolume[uid]) ? '0' : cargoVolume[uid]}</p>
          </span>
        </div>
      </div>
    </>
  )
}

export default PalletForm
