import Modal from 'react-modal'
import close from 'assets/icons/close.svg'
import { useForm } from 'react-hook-form'
import CustomTextarea from 'components/textInputs/CustomTextarea'
import OutlineButton from 'components/buttons/OutlineButton'
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import { useState } from 'react'
import { connect } from 'react-redux'
import { rejectQuote } from 'store/actions'
import CustomDefaultSelect from 'components/selectInputs/CustomDefaultSelect'

interface RejectQuoteProps {
  close: () => void
  onReject: () => void
  rejectQuote?: any
  quoteId?: number
}

const RejectPromptQuote = ({ close: closeRejectModal, onReject, rejectQuote, quoteId }: RejectQuoteProps) => {
  const [loadingState, setLoadingState] = useState(false)
  const [isOther, setIsOther] = useState<boolean>(false)
  const customStyles = {
    content: {
      top: '45%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      height: '394px',
      width: '434px',
      marginTop: '44px',
      padding: '0px',
      border: '30px',
      borderColor: '#296FD8',
    },
    overlay: {
      zIndex: '90',
      backgroundColor: 'rgba(6, 24, 2, 0.55)',
    },
  }

  const onSubmit = (data) => {
    try {
      const _data = {
        id: quoteId,
        state: {
          reason: isOther ? data.reason.value : data.rejection_reason.value,
          status: 'rejected',
        },
      }
      rejectQuote(_data)
      setLoadingState(true)
      setTimeout(() => {
        setLoadingState(false)
        onReject()
      }, 3000)
    } catch (error) {
      console.log(error)
    }
  }

  const reject_options = [
    { label: 'Pricing', value: 'Pricing' },
    { label: 'Wrong details', value: 'Wrong details' },
    { label: 'Delivery time', value: 'Delivery time' },
    { label: 'Other', value: 'other' },
  ]

  const handleReasonChange = (e) => {
    // setValueReason(e.value);
    if (e.value == 'other') {
      // setIsOther(true);
      setIsOther(true)
    } else {
      setIsOther(false)
    }
  }

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm()
  

  return (
    <Modal isOpen={true} style={customStyles}>
      <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
        <div className='flex justify-between items-center pt-6 px-6 pb-4 border-solid border-b-[1px] border-[#e6e7ec80]'>
          <div className=''>
            <p className='text-xl black-text-3 font-semibold'> Reject Quote</p>
            <p className='text-[#6B7280] text-[12px]'>Kindly provide a reason for rejecting this quote</p>
          </div>
          <>
            <img onClick={closeRejectModal} className='cursor-pointer' src={close} alt='close' />
          </>
        </div>

        <div className='p-[24px]'>
          {!isOther && (
            <div className='mb-[32px]'>
              <CustomDefaultSelect
                control={control}
                name={'rejection_reason'}
                id={'rejection_reason'}
                label={''}
                placeholder={'Select Reason'}
                isRequired={false}
                errors={errors}
                isDisabled={false}
                defaultValue={''}
                options={reject_options}
                icon=''
                customOnChange={handleReasonChange}
              />
            </div>
          )}

          {isOther && (
            <CustomTextarea
              control={control}
              name='reason'
              id='reason'
              label='Reason'
              placeholder='Leave a Reason'
              isDisabled={false}
              isRequired={true}
              defaultValue={''}
              icon=''
              errors={errors}
              style={{ height: '88px' }}
            />
          )}
          <PrimaryButtons
            title={'Yes, I want to reject this quote'}
            style={{ color: '#AD0013', backgroundColor: '#AD0013', marginBottom: '16px' }}
            // onClick={closeModal}
            disabled={false}
            icon={''}
            loading={loadingState}
            onClick={() => {}}
          />
          <OutlineButton title='Cancel' loading={false} disabled={false} icon={''} onClick={() => {}} style={{ color: '#AD0013', width: '100%' }} />
        </div>
      </form>
    </Modal>
  )
}

const mapStateToProps = (state: any) => {
  return {}
}

export default connect(mapStateToProps, {
  rejectQuote,
})(RejectPromptQuote)
