import React, { useState } from 'react'
import notice from '../../assets/icons/notice.svg'
import { useSelector } from 'react-redux'
import { branch_to_countries } from 'helpers/branchToCountriesHelper'
interface Props {
  booking_details: any
}
const ExportQuotesNotes = ({ booking_details }: Props) => {
  return (
    <div className='relative bg-[#004300] py-[24px] px-[32px]  flex items-start gap-[10px]  rounded-[12px] '>
      <img src={notice} alt='' className='min-w-[20px]'></img>

      <div className='flex flex-col gap-[20px] text-[12px] md:text-[14px]  '>
        {branch_to_countries[booking_details?.branch] == 'NG' ? (
          <p className='text-[#E5E7EB]'>
            Upon receipt, acceptance of quote is expected within two working days. All relevant pre shipment documentation are to be provided by the
            client. VATOS: Trucking rate advised is subject to review and valid at the time of shipment. 7.5% VAT will be applicable at the time of
            invoicing. Storage / detention / demurrage charges (if any) are as per receipted.
          </p>
        ) : branch_to_countries[booking_details?.branch] == 'GH' ? (
          <p className='text-[#E5E7EB]'>
            Upon receipt, acceptance of quote is expected within two working days. All relevant pre shipment documentation are to be provided by the
            client. VATOS: Trucking rate advised is subject to review and valid at the time of shipment. VAT will be applicable at the time of
            invoicing. Storage / detention / demurrage charges (if any) are as per receipted.
          </p>
        ) : (
          <p className='text-[#E5E7EB]'>
            Upon receipt, acceptance of quote is expected within two working days. All relevant pre shipment documentation are to be provided by the
            client. VATOS: Trucking rate advised is subject to review and valid at the time of shipment. 7.5% VAT will be applicable at the time of
            invoicing. Storage / detention / demurrage charges (if any) are as per receipted.
          </p>
        )}
      </div>
    </div>
  )
}

export default ExportQuotesNotes
