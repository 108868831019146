import { FC, useState } from 'react'
import { Controller } from 'react-hook-form'

//icons
import exportImg from 'assets/icons/export-upload.svg'

interface Props {
  id: string
  control: object
  name: string
  label: string
  placeholder: string
  defaultValue: string | undefined
  errors: object
  isRequired: boolean
  disabled: boolean
  handleFileChange: (any) => void
}

const ShipmentUploadInput: FC<Props> = ({
  id,
  control,
  name,
  label,
  placeholder,
  defaultValue,
  errors,
  isRequired,
  handleFileChange,
  disabled,
}): JSX.Element => {
  const [filename, setFileName] = useState<any>('')

  //handles file submission
  // const handleFileChange = (val) => {
  //     let selected = val.target.files[0]
  //     setFileName(selected.name)

  //     if (val.target.files[0].size < 10000000) {
  //         setUploadError("Maximum file size of 10MB")
  //         return
  //     } else if (['application/pdf',].includes(val.target.files[0]?.type)) {
  //         setUploadError("Only PDF format allowed.")
  //         return
  //     }
  //     submit({
  //         ship_doc: val.target.files
  //     })
  // }
  // const [uploadError, setUploadError] = useState("")
  return (
    <>
      <Controller
        //  @ts-ignore
        control={control}
        defaultValue={defaultValue}
        name={name}
        rules={{
          required: isRequired ? true : false,
          validate: {
            lessThan10MB: (files) => files[0]?.size < 10000000 || 'Maximum file size of 10MB',
            acceptedFormats: (files) =>
              ['application/pdf', 'image/jpeg', 'image/png'].includes(files[0]?.type) || 'Only PDF, JPEG, and PNG formats allowed.',
          },
        }}
        // @ts-ignore
        render={({ field: { onChange, value } }) => {
          return (
            <div>
              <label htmlFor={id} className='cursor-pointer'>
                <div
                  className={`bg-[#F9FAFB] text-sm green-text-2 rounded border-[1px] border-[#37B248] w-full py-3 text-center ${disabled && 'opacity-40 bg-[#F9FAFB]'}`}
                >
                  Select Document
                </div>
              </label>
              <input
                id={id}
                type='file'
                onChange={(val) => {
                  onChange(val.target.files)
                  handleFileChange(val)
                }}
                disabled={disabled}
              />
              {/* @ts-ignore */}
              {errors[name] && (
                <div className='mt-2 error-text'>
                  {/* @ts-ignore */}
                  {errors[name]['message'] ? (
                    //  @ts-ignore
                    <p>{errors[name]['message']}</p>
                  ) : (
                    <p>{label ? label : name} is required.</p>
                  )}
                </div>
              )}
            </div>
          )
        }}
      />
    </>
  )
}
// control={control}
//       defaultValue={defaultValue}
//       name={name}
//       rules={{
//         required: isRequired ? true : false,
//       }}

export default ShipmentUploadInput
