import React from 'react'
import logo from '../../assets/logos/oneport-logo.svg';

const NigeriaQuoteAddress = () => {
  return (
    <div className="flex flex-col md:flex-row justify-between gap-5">
      <img src={logo} alt="oneport logo" className=" min-w-[200px]" />
      <div className="flex flex-col text-[#6B7280] text-[16px] items-end">
        <span>UAC Building Nigeria</span>
        <span className=" lg:text-end">Lagos Nigeria</span>
        <span className="lg:text-end">100223</span>
      </div>
    </div>
  )
}

export default NigeriaQuoteAddress