export const formatValue = (value) => (value ? value : 'N/A')

export const capitalize = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase())
}

export const formatShipmentType = (type) => {
  if (!type) return ''
  return type.split('_').map(capitalize).join(' ')
}

// Function to map and format container details
export const formatContainerDetails = (containers) => {
  console.log('Containers input:', containers)

  // Calculate container counts by container size
  const containerMap = containers.reduce((acc, { container_size, container_count }) => {
    if (container_size) {
      if (!acc[container_size]) {
        acc[container_size] = 0
      }
      acc[container_size] += container_count !== undefined && container_count !== null ? container_count : 1
    }
    return acc
  }, {})

  console.log('Container map:', containerMap)

  // Check if containerMap is empty
  if (Object.keys(containerMap).length === 0) {
    return 'N/A'
  }

  // Format container details as "size X count"
  return Object.entries(containerMap)
    .map(([size, count]) => `${size} X ${count}`)
    .join(', ')
}
