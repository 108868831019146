import React from 'react'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'

//icons
import transit from 'assets/icons/transit.svg'
import calendar from 'assets/icons/calendar.svg'

//components
import CustomSelect from 'components/selectInputs/CustomSelect'
import CustomInput from 'components/textInputs/CustomInput'
import CustomGoogleInput from 'components/textInputs/CustomGoogleInput'
import WorldGoogleInput from 'components/textInputs/worldGoogleInput'
import CustomCurrencyInput from 'components/textInputs/CustomCurrencyInput'

//helpers
import { findDefaultPort, getCurrentTimestamp, parseAllPortsNew, getPortNameByCode } from 'helpers'
const _Json = require('sea-ports')

interface AirFreightProps {
  defaultPortsOfOrigin: any
  defaultPortsOfDestination: any
  rate_data: any
  control: any
  errors: any
  resetField: any
  originPort?: any
  allPorts?: any
  allowedPorts?: any
  setOriginPort?: any
  extras: boolean
  currency?: any
  setCurrency?: any
  shipment_data?: any
  prefix?: any
  setPrefix?: any
  booking_data?: any
  id?: string
  airFreightType?: string
}

const AirFreightLocations = ({
  defaultPortsOfOrigin,
  defaultPortsOfDestination,
  rate_data,
  control,
  errors,
  resetField,
  allPorts,
  allowedPorts,
  originPort,
  setOriginPort,
  extras,
  currency,
  setCurrency,
  airFreightType,
  shipment_data,
  prefix,
  setPrefix,
  booking_data,
  id,
}: AirFreightProps) => {
  console.log('booking_', booking_data)

  return (
    <>
      <div className=''>
        <div
          className={`flex gap-y-4 md:space-gap-0 md:items-center md:gap-x-6 ${airFreightType === 'porttoport' ? 'flex-col md:flex-row' : 'flex-col md:flex-row'}`}
        >
          {airFreightType === 'porttoport' || airFreightType === 'porttodoor' ? (
            <div className='flex-1'>
              <CustomGoogleInput
                icon=''
                control={control}
                name={'origin_port_code'}
                id={'origin_port_code'}
                label={'Pickup Airport'}
                placeholder={'Input Address'}
                isRequired={true}
                errors={errors}
                isDisabled={false}
                defaultValue={booking_data?.origin_port_code}
                // defaultValue={{
                //     label: booking_data?.origin_port_code,
                //     value: booking_data?.origin_port_code,
                // }}
                allowWorldWide={true}
                isBorderTransparent={true}
              />
            </div>
          ) : airFreightType === 'doortoport' || airFreightType === 'doortodoor' ? (
            <>
              <div className='flex-1'>
                <CustomGoogleInput
                  icon=''
                  control={control}
                  name={'pickup_location'}
                  id={'pickup_location'}
                  label={'Pickup Address'}
                  placeholder={'Input Address'}
                  isRequired={true}
                  errors={errors}
                  isDisabled={false}
                  defaultValue={booking_data?.pickup_location}
                  // defaultValue={{
                  //     label: booking_data?.pickup_location,
                  //     value: booking_data?.pickup_location,
                  // }}
                  allowWorldWide={true}
                  isBorderTransparent={true}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          <div className='md:mt-3.5 flex items-center justify-center md:flex-none'>
            <img src={transit} alt='transit' className='rotate-90 md:rotate-0' />
          </div>

          {airFreightType === 'doortodoor' || airFreightType === 'porttodoor' ? (
            <div className='flex-1'>
              <CustomGoogleInput
                icon=''
                control={control}
                name={'delivery_location'}
                id={'delivery_location'}
                label={'Destination Address'}
                placeholder={'Input Address'}
                isRequired={true}
                errors={errors}
                isDisabled={false}
                defaultValue={booking_data?.delivery_location}
                // defaultValue={{
                //     label: booking_data?.delivery_location,
                //     value: booking_data?.delivery_location,
                // }}
                allowWorldWide={true}
                isBorderTransparent={true}
              />
            </div>
          ) : airFreightType === 'doortoport' || airFreightType === 'porttoport' ? (
            <>
              <div className='flex-1'>
                <CustomGoogleInput
                  icon=''
                  control={control}
                  name={'destination_port_code'}
                  id={'destination_port_code'}
                  label={'Destination Airport'}
                  placeholder={'Input Address'}
                  isRequired={true}
                  errors={errors}
                  isDisabled={false}
                  defaultValue={booking_data?.destination_port_code}
                  // defaultValue={{
                  //     label: booking_data?.destination_port_code,
                  //     value: booking_data?.destination_port_code,
                  // }}
                  allowWorldWide={true}
                  isBorderTransparent={true}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  )
}

// export default AirFreightLocations

const mapStateToProps = (state: any) => {
  const { error, loading, rate_data, getting_rates } = state.rate
  return { error, loading, rate_data, getting_rates }
}

export default connect(mapStateToProps, null)(AirFreightLocations)
