import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

//icons
import close from 'assets/icons/close.svg'
import deleted from 'assets/icons/small-delete.svg'
import disclaimer from 'assets/icons/disclaimer-white.svg'

//library
import Modal from 'react-modal'
import { useForm } from 'react-hook-form'

//components
import CustomSelect from 'components/selectInputs/CustomSelect'
import ShipmentUploadInput from './ShipmentUploadInput'
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import CustomInput from 'components/textInputs/CustomInput'

//redux actions
import { uploadShipmentDoc } from 'store/actions'

import { export_air_freight_documents, import_air_freight_documents, import_ocean_freight_documents, export_ocean_freight_documents } from './type'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100vw - 10%)',
    borderRadius: '10px',
    border: '0.01px solid #888',
  },
  overlay: {
    zIndex: '90',
    backgroundColor: 'rgba(6, 24, 2, 0.55)',
  },
}

type DocumentUploadType = {
  isOpen: boolean
  closeModal?: () => void
  id?: string
  shipCategory?: string
  upload_loading: boolean
  uploadShipmentDoc?: any
  deleteShipmentDoc?: any
  documents?: any
  modalMode?: string
  setModalMode?: any
  updateDocDetails?: any
  setUpdateDocDetails?: any
  uploaded_data?: any
  delete_shipdoc_loading?: boolean
  docList?: any
  callAction?: boolean
}

const DocumentUploadModal = (props: DocumentUploadType) => {
  const {
    handleSubmit,
    control,
    resetField,
    formState: { errors },
    setValue,
  } = useForm()

  const {
    isOpen,
    closeModal,
    id,
    shipCategory,
    upload_loading,
    uploadShipmentDoc,
    documents,
    modalMode,
    setModalMode,
    updateDocDetails,
    setUpdateDocDetails,
    docList,
    callAction = true,
  } = props

  interface Name {
    label: string
    value: string
    type: string
  }

  interface UploadedDocument {
    name: Name
    file: Record<string, any>
  }

  // interface DocName {
  //   label: string;
  //   value: string
  // }

  const [aliasDocName, setAliasDocName] = useState<any>(updateDocDetails || undefined)
  const [docName, setDocName] = useState<any>(updateDocDetails || undefined)
  const [docToBeUploaded, setDocToBeUploaded] = useState<UploadedDocument[]>([])

  const [disableFileInput, setDisableFileInput] = useState(modalMode === 'update' ? false : true)

  const [otherDocName, setOtherDocName] = useState('')

  console.log('other>>>', otherDocName)

  useEffect(() => {
    if (modalMode === 'update') {
      setValue('doc_upload', updateDocDetails)
      setDocName(updateDocDetails)
      setAliasDocName(updateDocDetails)
    } else {
      setValue('doc_upload', '')
    }
  }, [modalMode, setValue, updateDocDetails])

  useEffect(() => {
    if (!docName || Object.keys(docName)?.length < 1 || (docName?.value === 'other' && !otherDocName)) {
      setDisableFileInput(true)
    } else {
      setDisableFileInput(false)
    }
  }, [docName, otherDocName])

  //holds select options
  const [options, setOptions] = useState(
    shipCategory === 'importair_freight'
      ? import_air_freight_documents
      : shipCategory === 'exportair_freight'
        ? export_air_freight_documents
        : modalMode === 'request'
          ? docList
          : shipCategory === 'importocean_freight'
            ? import_ocean_freight_documents
            : export_ocean_freight_documents
  )

  //callback function tpassed onSubmit sucess
  const onUploadSuccess = () => {
    setDocToBeUploaded([])
    if (callAction) {
      setModalMode('')
      setOtherDocName('')
    }
    closeModal && closeModal()
    setUploadError('')
  }

  //resettind other field on docSelect
  const customOnChangeOption = (option) => {
    setDocName(option)
    setAliasDocName(option)
    !uploadError && resetField('document_name')
    setUploadError('')
    setOtherDocName('')
  }

  //function that submits doc
  const onSubmit = (data: any) => {
    const uploadData = new FormData()

    data.forEach((doc, idx) => {
      uploadData.append('shipment_file', doc.file, doc.name.value)
      uploadData.append('document_name', doc.name.value)
      uploadData.append('document_type', doc.name.type)
    })

    const finalData = {
      id: id,
      data: uploadData,
    }

    resetField('doc_upload')
    setDocName('')

    //determines if it's an upload or update success handler at the redux stage
    callAction ? uploadShipmentDoc(finalData, onUploadSuccess) : uploadShipmentDoc(finalData, onUploadSuccess, false)
  }

  // removing doc to be uploaded from the list
  const deleteDocToBeUploaded = (item) => {
    setDocToBeUploaded((prev) => prev.filter((i, idx) => i.name.value !== item.name.value))

    // modalMode !== 'update' && aliasDocName.value !== 'other' && setOptions((prev) => [item.name, ...prev])
    modalMode !== 'update' && item.name.type === 'main' && setOptions((prev) => [item.name, ...prev])

    resetField('document_name')
    setOtherDocName('')
  }

  const [uploadError, setUploadError] = useState('')

  //handles file submission
  const handleFileChange = (val) => {
    if (
      docToBeUploaded?.map((item) => item.name.label?.toLowerCase()).includes(otherDocName?.toLowerCase()) ||
      options.map((item) => item.label?.toLowerCase()).includes(otherDocName?.toLowerCase())
    ) {
      setUploadError('Other Document name already exists in selected documents or options, please update it')
      val.target.value = null
      return
    }

    if (documents?.map((item) => item?.document_name?.toLowerCase()).includes(otherDocName?.toLowerCase())) {
      setUploadError('Document has been uploaded already')
      val.target.value = null
      return
    }

    if (val.target.files[0].size > 10000000) {
      setUploadError('Maximum file size of 10MB')
      val.target.value = null
      return
    }

    if (!['application/pdf', 'image/jpeg', 'image/png'].includes(val.target.files[0]?.type)) {
      setUploadError('Only PDF, JPEG, and PNG formats allowed.')
      val.target.value = null
      return
    }

    const data = modalMode === 'update' ? [] : [...docToBeUploaded]
    data.unshift({
      name: otherDocName ? { label: otherDocName?.toLowerCase(), value: otherDocName?.toLowerCase(), type: 'other' } : { ...docName, type: 'main' },
      file: val.target.files[0],
    })

    setDocToBeUploaded(data)

    modalMode !== 'update' && setOptions((prev) => prev.filter((doc) => docName.value !== 'option' && doc.value !== docName.value))

    resetField('document_name')
    setOtherDocName('')

    if (modalMode !== 'update') {
      setDocName('') //we do this so that the docName is empty and triggers the effect to disable state
      resetField('doc_upload')
    }

    val.target.value = null
    setUploadError('')
  }

  // console.log('docTobeUploaded>>>', docToBeUploaded)
  // console.log('docName>>', docName)
  // console.log('shipCategory>>', shipCategory)
  // console.log('document>>.', documents)

  return (
    <>
      <Modal isOpen={isOpen} style={customStyles} className={'uploadmodal'}>
        <div>
          <div className='flex justify-between items-center px-6 pt-6 pb-3'>
            <div>
              <p className='black-text-3 font-medium text-base md:text-lg xl:text-xl flex flex-col'>
                {modalMode === 'update' ? 'Update Document' : 'Upload Documents'}
              </p>
              {/* <p className="grey-text text-sm font-light">{documents?.length} document(s) uploaded</p> */}
              <p className='grey-text text-sm font-light'>Please upload the required documents</p>
            </div>
            <span
              className='cursor-pointer'
              onClick={() => {
                if (!upload_loading) {
                  setModalMode && setModalMode('')
                  closeModal && closeModal()
                  setUpdateDocDetails({})
                  setDocToBeUploaded([])
                  setUploadError('')
                  setDisableFileInput(true)
                  resetField('document_name')
                  setOtherDocName('')
                }
              }}
            >
              <img src={close} alt='' />
            </span>
          </div>
          <div className='max-h-[calc(100vh_-_300px)] overflow-auto'>
            <div className='pb-5 pt-4 px-6 bg-[#F3F4F6]'>
              <div className='flex gap-x-2 bg-[#1f2937] rounded text-white py-3 px-4 mb-5'>
                <span>
                  <img src={disclaimer} alt='' className='mt-[2px] min-w-[16px]' />
                </span>
                <p className='text-sm font-light'>
                  <span className='font-medium'>Note, </span>
                  <span>only upload PDF, JPG or PNG files with a maximum file size of 10mb.</span>
                </p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='w-[100%]'>
                  <CustomSelect
                    control={control}
                    name={`doc_upload`}
                    id={`doc_upload`}
                    label={''}
                    placeholder={`Select document`}
                    isRequired={true}
                    errors={errors}
                    isDisabled={modalMode === 'update' ? true : false}
                    options={
                      !callAction
                        ? options.filter((item) => !documents?.map((doc) => doc?.document_name)?.includes(item?.value))
                        : [
                            ...options.filter((item) => !documents?.map((doc) => doc?.document_name)?.includes(item?.value)),
                            { label: 'Other', value: 'other' },
                          ]
                    } //filters the options array to include only those items whose value is not found in the document_name values of the documents array.
                    defaultValue={modalMode === 'update' ? updateDocDetails : ''}
                    icon=''
                    customOnChange={customOnChangeOption}
                  />
                </div>
                {docName?.value === 'other' && (
                  <div className='mt-5'>
                    <CustomInput
                      control={control}
                      name={`document_name`}
                      id={`document_name`}
                      label={''}
                      placeholder={'input document name'}
                      isRequired={true}
                      type={'text'}
                      errors={errors}
                      isDisabled={false}
                      defaultValue={''}
                      min={''}
                      max={''}
                      icon=''
                      customOnChange={setOtherDocName}
                    />
                  </div>
                )}
                <div className={`w-[100%] self-center mt-5`}>
                  <ShipmentUploadInput
                    control={control}
                    id={'ship_doc'}
                    name={'ship_doc'}
                    label={''}
                    placeholder={''}
                    defaultValue={''}
                    errors={errors}
                    isRequired={true}
                    disabled={disableFileInput}
                    // disabled={modalMode === 'update' ? false : !docName}
                    handleFileChange={handleFileChange}
                  />
                </div>
              </form>
              {uploadError && <p className='mt-2 error-text'>{uploadError}</p>}
            </div>
            <div className='flex flex-col gap-y-4 py-4 px-6'>
              <p className='black-text-3 text-sm font-medium'>Documents selected ({docToBeUploaded?.length})</p>
              {docToBeUploaded?.length > 0 ? (
                docToBeUploaded.map((item, idx) => (
                  <div key={idx} className='p-4 solid-br rounded flex justify-between items-center relative'>
                    <div>
                      <p className='black-text-4 text-sm mb-1 capitalize'>{item?.name.label}</p>
                      <p className='grey-text-1 text-sm font-light'>{Math.ceil(item?.file?.size / 1000)}kb</p>
                    </div>
                    <div className='cursor-pointer flex items-center gap-x-1.5' onClick={() => (upload_loading ? null : deleteDocToBeUploaded(item))}>
                      <img src={deleted} alt='' />
                      <p className='text-xs text-[#AD0013] font-normal'>Delete</p>
                    </div>
                  </div>
                ))
              ) : (
                <p className='black-text-3 text-[15px] text-center py-16'>
                  {modalMode === 'update' ? 'Choose file to be updated' : 'Choose documents to be uploaded'}
                </p>
              )}
            </div>
          </div>
          <div className='p-6 top-divider-2'>
            <PrimaryButtons
              title={modalMode === 'update' ? 'Update Document' : 'Upload Document(s)'}
              disabled={!(docToBeUploaded?.length > 0) || upload_loading}
              onClick={() => onSubmit(docToBeUploaded)}
              loading={upload_loading}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

const mapStateToProps = (state: any) => {
  const { upload_loading, uploaded_data, delete_shipdoc_loading } = state.booking
  return { upload_loading, uploaded_data, delete_shipdoc_loading }
}

export default connect(mapStateToProps, {
  uploadShipmentDoc,
})(DocumentUploadModal)
