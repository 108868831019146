import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import search from 'assets/icons/search.svg'
import filter from 'assets/icons/Filter-grey.svg'
import { useNavigate } from 'react-router-dom'

interface QuoteAntTableProps {
  filteredQuotes?: any[];
  filterDisplay: any;
  setFilterDisplay: any;
  handleSearch: (value:string, type: string) => void;
  total_count: number;
  handlePageChange: (page: string) => void;
  handleRowsPerChange: (page: number, count: number) => void;
  filterObject: any

}

const QuoteAntTable = ({filteredQuotes, filterDisplay, setFilterDisplay, handleSearch, handlePageChange, handleRowsPerChange, filterObject, total_count}: QuoteAntTableProps) => {

  const [data, setData] = useState(filteredQuotes)
  const [page, setPage] = useState(parseInt(filterObject.page))

    const navigate = useNavigate()

    useEffect(() => {
      setPage(parseInt(filterObject.page));
    }, [filterObject]);

    console.log(total_count)

    const columns = [
        {
          title: <small className='font-light text-[#6B7280] font-[inter] text-xs'>Job Number</small>,
          dataIndex: 'job_number',
          key: 'job_number',
          width: '20.67%',
          render: (_, { job_number }) => <>{job_number ? job_number : 'N/A'}</>,
        },
        {
          title: <small>Shipment Type</small>,
          dataIndex: 'shipment_type',
          key: 'shipment_type',
          width: '20.67%',
          render: (_, { shipment_type }) => <>{shipment_type === 'export' ? <small>Export</small> : <small>Import</small>}</>,
        },
        {
          title: <small>Service Type</small>,
          dataIndex: 'shipment_transport_type',
          key: 'shipment_transport_type',
          width: '20.67%',
          render: (_, { shipment_transport_type }) => (
            <small>
              {shipment_transport_type === 'air_freight' && 'Air Freight'}
              {shipment_transport_type === 'ocean_freight' && 'Ocean Freight'}
              {shipment_transport_type === 'warehousing' && 'Warehousing'}
              {shipment_transport_type === 'customs_brokerage' && 'Customs Brokerage'}
              {shipment_transport_type === 'haulage' && 'Haulage'}
            </small>
          ),
        },
        {
          title: <small>Status</small>,
          dataIndex: 'shipment_status',
          key: 'shipment_status',
          width: '20.67%',
          render: (_, row, { shipment_status }) => (
            <>
              <div
                className={`py-[4px] px-[8px] rounded-full w-fit  text-center ${
                  row.shipment_quotes_details.status === 'pending'
                    ? 'bg-[#FFE75D4D]'
                    : row.shipment_quotes_details.status === 'draft'
                      ? 'bg-[#EAEFEB]'
                      : row.shipment_quotes_details.status === 'accepted'
                        ? 'bg-[#059C010D]'
                        : row.shipment_quotes_details.status === 'rejected'
                          ? 'bg-red-100'
                          : row.shipment_quotes_details.status === 'expired'
                            ? 'bg-[#FFBAB333]'
                            : ''
                }`}
              >
                <p
                  className={`text-xs capitalize text-center ${
                    row.shipment_quotes_details.status === 'pending'
                      ? 'text-[#7B3A00]'
                      : row.shipment_quotes_details.status === 'accepted'
                        ? 'text-[#059C01]'
                        : row.shipment_quotes_details.status === 'rejected'
                          ? 'text-[#8A0000]'
                          : row.shipment_quotes_details.status === 'draft'
                            ? 'text-[#59725C]'
                            : row.shipment_quotes_details.status === 'expired'
                              ? 'text-[#AD0013]'
                              : ''
                  }`}
                >
                  {' '}
                  {row.shipment_quotes_details.status}
                </p>
              </div>
            </>
          ),
        },
        {
            title: <p></p>,
            key: 'operation',
            render: (_, row, {_id}) => {
              return parseInt(row.invoice_amount_pending) === 0 ? (
                <></>
              ) : (
                <div
                  className=' p-1 rounded cursor-pointer'
                  onClick={() => navigate(`/quote/${row?.shipment_quotes_details['_id']}`)}
                >
                  <p className='text-sm text-center text-[#3AB44A] font-medium p-2 cursor-pointer'>View Details</p>
                </div>
              )
            },
          },
      ]

  return (
    <div>
      <div className='mb-1'>
        <div className='pt-2 flex justify-between items-center'>
          <div className='flex items-center gap-x-[25px]'>
            {/* search */}
            <div className='flex items-center gap-x-1 form-input px-4 py-1.5 custom-input w-full black-text w-[264px] mb-5'>
              <div className=''>
                <img src={search} alt=''></img>
              </div>
              <input placeholder='Search Quotes' className='' onChange={(e) => handleSearch(e.target.value, "search")} />
            </div>

            {/* filter */}
            <div
              className='cursor-pointer px-4 flex items-center gap-x-1 border border-[#e5e7eb] rounded  h-[37.6px] mt-[-20px]'
              onClick={() => {
                setFilterDisplay(true)
              }}
            >
              {/* image */}
              <div>
                <img className='min-w-[16px]' src={filter} alt=''></img>
              </div>
              {/* filter */}
              <p className='text-xs text-[#9CA3AF]'>Filter</p>
            </div>
          </div>

        </div>
      </div>
      <div className='solid-br'>
        <Table
          //   @ts-ignore
          columns={columns}
          dataSource={filteredQuotes}
          tableLayout='fixed'
          pagination={{
            defaultPageSize: 10,
            total: total_count,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            current: page + 1,
            showSizeChanger: true,
            pageSizeOptions: ['10', '15', '20', '30'],
            onChange: (page, pageSize) => {
              handlePageChange(String(page - 1))
              handleRowsPerChange(page-1, pageSize)
            },
          }}
        />
      </div>
      
    </div>
  )
}

export default QuoteAntTable