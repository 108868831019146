import { useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'

//icons
import greenEye from 'assets/icons/green-eye.svg'
import threeDots from 'assets/icons/three-dots.svg'
import update from 'assets/icons/update.svg'

//components
import LoadingSpinner from 'components/partials/LoadingSpinner'

//redux actions
import { uploadShipmentDoc, deleteShipmentDoc } from 'store/actions'

//types
import { ActionProps, UploadDocumentType, document_name } from './type'

const Actions = ({ linkView, docId, deleteDoc, delete_shipdoc_loading, setModalMode, openUploadModal }: ActionProps) => (
  <div className='p-1 w-36 bg-white shadow-[1px_4px_12px_-1px_rgba(44,78,39,0.15)]'>
    <a href={linkView} target='_blank' rel='noreferrer' className='hover:bg-[#139c330f] flex items-center gap-x-2 p-2 rounded cursor-pointer'>
      <span>
        <img src={greenEye} alt='' />
      </span>
      <span className='green-text-2 text-xs'>View File</span>
    </a>
    <div className='hover:bg-[#139c330f] flex items-center gap-x-2 p-2 rounded cursor-pointer'>
      <span>
        <img src={update} alt='' />
      </span>
      <p
        className='green-text text-xs'
        onClick={() => {
          setModalMode('update')
          openUploadModal(true)
        }}
      >
        Update File
      </p>
      <div>{delete_shipdoc_loading && <LoadingSpinner top={false} color={'#AD0013'} height={'4'} />}</div>
    </div>
  </div>
)

const UploadedDocuments = ({
  documents,
  filteredDocuments,
  upload_loading,
  delete_shipdoc_loading,
  deleteShipmentDoc,
  setModalMode,
  openUploadModal,
  setUpdateDocDetails,
}: UploadDocumentType) => {
  const ref = useRef<HTMLDivElement>(null)

  const [showAction, setShowAction] = useState<boolean[]>([])

  const toggle = (index) => {
    const temp = [...showAction]
    temp[index] = !temp[index]
    setShowAction([...temp])
  }

  //reset show filter array on successful submission
  const resetShowAction = () => {
    setShowAction([])
  }

  //delete a document()
  const deleteDoc = (id: string) => {
    deleteShipmentDoc(id, resetShowAction)
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu, then close the menu
      if (showAction && ref.current && !ref.current.contains(e.target)) {
        setShowAction([])
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showAction])

  return (
    <div>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-8'>
        {filteredDocuments?.length > 0 ? (
          filteredDocuments.map((item, idx) => (
            <div
              key={item?.document_location}
              className='p-4 solid-br rounded flex justify-between items-start relative'
              onClick={() => setUpdateDocDetails({ label: document_name[item?.document_name] || item?.document_name, value: item?.document_name })}
            >
              <div>
                <p className='black-text-4 text-sm mb-1 capitalize'>{document_name[item?.document_name] || item.document_name || 'N/A'}</p>
                <p className='grey-text-1 text-sm font-light'>{item?.createdAt?.slice(0, 10)}</p>
              </div>
              <span className='cursor-pointer' onClick={() => toggle(idx)}>
                <img src={threeDots} alt='' />
              </span>
              {showAction[idx] && (
                <div ref={ref} className='absolute right-5 top-8 z-20'>
                  <Actions
                    linkView={item?.document_location}
                    docId={item?._id}
                    deleteDoc={deleteDoc}
                    delete_shipdoc_loading={delete_shipdoc_loading}
                    setModalMode={setModalMode}
                    openUploadModal={openUploadModal}
                  />
                </div>
              )}
            </div>
          ))
        ) : (
          <div className='flex flex-col col-span-3 mt-32 justify-center items-center'>
            <p className='grey-text text-xl py-2'>No Document(s)</p>
            <p className='max-w-[200px] text-[#D1D5DB] text-sm text-center'>Uploaded documents will be displayed here</p>
          </div>
        )}
      </div>
    </div>
  )
}
const mapStateToProps = (state: any) => {
  const { upload_loading, uploaded_data, delete_shipdoc_loading } = state.booking
  return { upload_loading, uploaded_data, delete_shipdoc_loading }
}

export default connect(mapStateToProps, { uploadShipmentDoc, deleteShipmentDoc })(UploadedDocuments)
