import React from 'react'
import { capitalize, formatShipmentType, formatValue } from 'util/format'

interface BookingDetailCompProps {
  email?: boolean
  label: string
  details: string
}

const BookingDetailComp = ({ email, details, label }: BookingDetailCompProps) => {
  return (
    <div className='flex flex-col'>
      <span className='text-xs text-[#9CA3AF]'>{label}</span>
      {email ? (
        <span className='text-[#007003] break-words text-sm whitespace-normal'>{formatValue(details)}</span>
      ) : (
        <span className='text-sm whitespace-normal'>{capitalize(formatShipmentType(details))}</span>
      )}
    </div>
  )
}

export default BookingDetailComp
