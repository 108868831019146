import React from 'react'
import GhanaAirTAndC from './GhanaAirTAndC'
import GhanaImportTAndC from './GhanaImportTAndC'
import GhanaExportTAndC from './GhanaExportTAndC'
interface Props {
  booking_details: any
}

const GhanaTermsAndCond = ({ booking_details }: Props) => {
  return (
    <>
      {booking_details?.shipment_transport_type == 'air_freight' ? (
        <GhanaAirTAndC />
      ) : booking_details?.shipment_type == 'import' ? (
        <GhanaImportTAndC />
      ) : (
        <GhanaExportTAndC />
      )}
    </>
  )
}

export default GhanaTermsAndCond
