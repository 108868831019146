import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

//components
import CustomTabs from 'components/customTabs/CustomTabs'
import UploadedDocuments from 'components/shipment/UploadedDocuments'
import ShipmentTitleUpload from 'components/shipment/ShipmentTitleUpload'
import DocumentUploadModal from 'components/shipment/DocumentUploadModal'
import ShipmentDetailAirDTD from 'components/shipment/afapShipment/shipmentDetailAirDTD'
import ShipmentDetailAirPTP from 'components/shipment/afapShipment/shipmentDetailAirPTP'
import ShipmentDetailAirDTP from 'components/shipment/afapShipment/ShipmentDetailAirDTP'
import ShipmentDetailAirPTD from 'components/shipment/afapShipment/ShipmentDetailAirPTD'

const ShipmentDetailAir = (props: any) => {
  const params = useParams()
  const { id } = params

  const { shipment_data, booking_summary, shipCategory, airtracking_loading, airtrack_data } = props

  //modal states
  const [isOpen, setIsOpen] = useState(false)

  const [activeTab, setActiveTab] = useState('Shipment Details')

  const [modalMode, setModalMode] = useState('')
  const [updateDocDetails, setUpdateDocDetails] = useState({})

  //modals functions
  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  console.log('shipment_data>>>', shipment_data)

  const tab_result = ['Shipment Details', 'Uploaded Documents']

  const ShipmentDetailAirOptions = ({ shipment_data }) => (
    <>
      {shipment_data?.air_freight_data?.air_freight_type === 'door_to_door' ? (
        <ShipmentDetailAirDTD shipdata={shipment_data} booking={booking_summary} shipCategory={shipCategory} airtrack_data={airtrack_data} />
      ) : shipment_data?.air_freight_data?.air_freight_type === 'port_to_port' ? (
        <ShipmentDetailAirPTP shipdata={shipment_data} booking={booking_summary} shipCategory={shipCategory} airtrack_data={airtrack_data} />
      ) : shipment_data?.air_freight_data?.air_freight_type === 'port_to_door' ? (
        <ShipmentDetailAirPTD shipdata={shipment_data} booking={booking_summary} shipCategory={shipCategory} airtrack_data={airtrack_data} />
      ) : shipment_data?.air_freight_data?.air_freight_type === 'door_to_port' ? (
        <ShipmentDetailAirDTP shipdata={shipment_data} booking={booking_summary} shipCategory={shipCategory} airtrack_data={airtrack_data} />
      ) : (
        <></>
      )}
    </>
  )

  console.log('fre>>', updateDocDetails)

  return (
    <main>
      <>
        {/* <ShipmentTitleUpload
					shipment_data={shipment_data}
					openModal={openModal}
				/>
				<div className="mb-8 mt-8 flex items-end">
					<CustomTabs
						tabs={tab_result}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
					/>
					<div className="border-b border-[1px] border-[#E5E7EB] w-1/2 flex-1" />
				</div> */}

        {/* {activeTab === tab_result[0] &&  */}
        <ShipmentDetailAirOptions shipment_data={shipment_data} />
        {/* } */}

        {/* {activeTab === tab_result[1] && 
					<UploadedDocuments 
						documents={shipment_data?.document_details} 
						setModalMode={setModalMode} 
						openUploadModal={setIsOpen} 
						setUpdateDocDetails={setUpdateDocDetails}
					/>
				} */}

        {/* <DocumentUploadModal
					id={id}
					isOpen={isOpen}
					closeModal={closeModal}
					shipCategory={shipCategory}
					documents={shipment_data?.document_details}
					modalMode={modalMode}
					setModalMode={setModalMode}
					updateDocDetails={updateDocDetails}
					setUpdateDocDetails={setUpdateDocDetails}
				/> */}
      </>
    </main>
  )
}

export default ShipmentDetailAir
