import { useState, useEffect } from 'react'
import { connect } from 'react-redux'

//libraries
import Modal from 'react-modal'

//icons
import port from 'assets/icons/home-port.svg'
import chevronUp from 'assets/icons/chevron-up.svg'
import arrowright from 'assets/icons/arrow-right.svg'
import chevronDown from 'assets/icons/chevron-down.svg'
import destination from 'assets/icons/location-pin.svg'

import close from 'assets/icons/close.svg'

//components
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import OutlineButton from 'components/buttons/OutlineButton'

var converter = require('number-to-words')

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    maxHeight: 'calc(100vh - 100px)',
    overflow: 'scroll',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100vw - 10%)',
    borderRadius: '8px',
    border: '0.01px solid #888',
  },
  overlay: {
    zIndex: '99999999999',
    backgroundColor: 'rgba(6, 24, 2, 0.55)',
  },
}

function BookingSummaryModal(props: any) {
  const { bookingId, freightDetails, bookingConfirmation, showSummaryModal, closeBookingSummaryModal, confirming_booking } = props

  const [showChargeDetails, setShowChargeDetails] = useState(false)
  const [showShipmentDetails, setShowShipmentDetails] = useState(false)

  // console.log('shipment', shipment_data)
  // console.log('shipment_id', shipment_id)
  // console.log('shipment_booking', booking_data)
  console.log('freightDetails>>>', freightDetails)

  const [accordion, setAccordion] = useState({})
  const toggleAccordion = (id) => {
    setAccordion((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  const _ptpConfirmation = {
    shipment_transport_type: 'air_freight',
    goods_weight: freightDetails?.goods_weight,
    goods_type: freightDetails?.goods_type?.value,
    package_pickup_medium: freightDetails?.package_pickup_medium?.value,
    commodity_description: freightDetails?.commodity_description,
    consignee_name: freightDetails?.consignee_name,
    consignee_email: freightDetails?.consignee_email,
    consignee_phone: freightDetails?.consignee_phone?.phone,
    container_count: freightDetails?.clickCount,
    container_details: freightDetails?.container_details,
    shipment_id: bookingId,
    form_m_number: freightDetails?.form_m_number,
    bankers_acceptance_number: freightDetails?.bankers_acceptance_number
  }

  const _dtpConfirmation = {
    shipment_transport_type: 'air_freight',
    goods_weight: freightDetails?.goods_weight,
    goods_type: freightDetails?.goods_type?.value,
    package_pickup_medium: freightDetails?.package_pickup_medium?.value,
    commodity_description: freightDetails?.commodity_description,
    consignee_name: freightDetails?.consignee_name,
    consignee_email: freightDetails?.consignee_email,
    consignee_phone: freightDetails?.consignee_phone?.phone,
    container_count: freightDetails?.clickCount,
    container_details: freightDetails?.container_details,
    shipment_id: bookingId,
    form_m_number: freightDetails?.form_m,
    bankers_acceptance_number: freightDetails?.bankers_number
  }

  const _dtdConfirmation = {
    shipment_transport_type: 'air_freight',
    goods_weight: freightDetails?.goods_weight,
    goods_type: freightDetails?.goods_type?.value,
    package_pickup_medium: freightDetails?.package_pickup_medium?.value,
    commodity_description: freightDetails?.commodity_description,
    consignee_name: freightDetails?.consignee_name,
    consignee_email: freightDetails?.consignee_email,
    consignee_phone: freightDetails?.consignee_phone?.phone,
    container_count: freightDetails?.clickCount,
    container_details: freightDetails?.container_details,
    shipment_id: bookingId,
    form_m_number: freightDetails?.form_m,
    bankers_acceptance_number: freightDetails?.bankers_number
  }

  const _ptdConfirmation = {
    shipment_transport_type: 'air_freight',
    goods_weight: freightDetails?.goods_weight,
    goods_type: freightDetails?.goods_type?.value,
    package_pickup_medium: freightDetails?.package_pickup_medium?.value,
    commodity_description: freightDetails?.commodity_description,
    consignee_name: freightDetails?.consignee_name,
    consignee_email: freightDetails?.consignee_email,
    consignee_phone: freightDetails?.consignee_phone?.phone,
    container_count: freightDetails?.clickCount,
    container_details: freightDetails?.container_details,
    shipment_id: bookingId,
    form_m_number: freightDetails?.form_m,
    bankers_acceptance_number: freightDetails?.bankers_number
  }

  const confirm = () => {
    freightDetails?.air_freight_type === 'porttoport'
      ? bookingConfirmation(_ptpConfirmation, bookingId)
      : freightDetails?.air_freight_type === 'doortoport'
      ? bookingConfirmation(_dtpConfirmation, bookingId)
      : freightDetails?.air_freight_type === 'doortodoor'
      ? bookingConfirmation(_dtdConfirmation, bookingId)
      : freightDetails?.air_freight_type === 'porttodoor' && bookingConfirmation(_ptdConfirmation, bookingId)
  }

  return (
    <>
      <Modal isOpen={showSummaryModal} onRequestClose={() => closeBookingSummaryModal()} style={customStyles} className={'newratemodal'}>
        <>
          <div className='flex py-4 px-8 items-center bottom-divider'>
            <p className='text-lg black-text-2 font-medium'>
              Booking Summary (
              {freightDetails?.air_freight_type === 'porttoport' ? (
                <>Port To Port</>
              ) : freightDetails?.air_freight_type === 'doortodoor' ? (
                <>Door To Door</>
              ) : freightDetails?.air_freight_type === 'doortoport' ? (
                <>Door To Port</>
              ) : (
                freightDetails?.air_freight_type === 'porttodoor' && <>Port To Door</>
              )}
              )
            </p>
            <img src={close} alt='' className='ml-auto cursor-pointer' onClick={() => closeBookingSummaryModal()}></img>
          </div>

          <div className='flex flex-col gap-y-5 p-4 md:p-8 '>
            <div className='green-br p-4'>
              <div className='grid grid-cols-2'>
                <div className='flex gap-x-1.5 items-center'>
                  <div>
                    <img className='min-w-[32px]' src={port} alt='' />
                  </div>
                  <div>
                    <p className='grey-text font-light text-xs md:text-sm mb-1'>Origin Port</p>
                    <p className='black-text-4 text-sm font-medium'>
                      {freightDetails?.air_freight_type === 'porttoport'
                        ? freightDetails?.origin_port_code?.label ?? freightDetails?.origin_port_code
                        : freightDetails?.air_freight_type === 'doortodoor'
                        ? freightDetails?.pickup_location?.label ?? freightDetails?.pickup_location
                        : freightDetails?.air_freight_type === 'doortoport'
                        ? freightDetails?.pickup_location?.label ?? freightDetails?.pickup_location
                        : freightDetails?.air_freight_type === 'porttodoor'
                        ? freightDetails?.origin_port_code?.label ?? freightDetails?.origin_port_code
                        : ''}
                    </p>
                  </div>
                </div>
                <div className='flex gap-x-1.5 items-center'>
                  <div>
                    <img className='min-w-[32px]' src={destination} alt='' />
                  </div>
                  <div>
                    <p className='grey-text font-light text-xs md:text-sm mb-1'>Destination Port</p>
                    <p className='black-text-4 text-sm font-medium'>
                      {freightDetails?.air_freight_type === 'porttoport'
                        ? freightDetails?.destination_port_code?.label ?? freightDetails?.destination_port_code
                        : freightDetails?.air_freight_type === 'doortodoor'
                        ? freightDetails?.delivery_location?.label ?? freightDetails?.delivery_location
                        : freightDetails?.air_freight_type === 'doortoport'
                        ? freightDetails?.destination_port_code?.label ?? freightDetails?.destination_port_code
                        : freightDetails?.air_freight_type === 'porttodoor'
                        ? freightDetails?.delivery_location?.label ?? freightDetails?.delivery_location
                        : ''}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Delivery Charges */}
          <div className='px-4 md:px-8 md:py-4 flex flex-col pb-2'>
            <div>
              <div
                className='solid-br py-2 px-4 flex justify-between items-center rounded cursor-pointer'
                onClick={() => setShowChargeDetails(!showChargeDetails)}
              >
                <div className=''>
                  <p className='black-text-4 font-normal'>Delivery Details</p>
                  <p className='black-text font-light text-xs'>Includes details of Contact Person</p>
                </div>
                <div>
                  <img src={showChargeDetails ? chevronUp : chevronDown} alt='' />
                </div>
              </div>
              {showChargeDetails && (
                <div className='left-divider bottom-divider right-divider p-4 '>
                  <div className='grid grid-cols-2 gap-4 py-3'>
                    <div>
                      <p className='grey-text font-light text-sm'>Contact Name</p>
                      <p className='black-text-4 font-normal text-sm capitalize'>
                        {freightDetails?.consignee_name ? freightDetails?.consignee_name : 'N/A'}
                      </p>
                    </div>
                    <div>
                      <p className='grey-text font-light text-sm'>Contact Email</p>
                      <p className='black-text-4 font-normal text-sm'>{freightDetails?.consignee_email ? freightDetails?.consignee_email : 'N/A'}</p>
                    </div>
                    <div>
                      <p className='grey-text font-light text-sm'>Contact Phone</p>
                      <p className='black-text-4 font-normal text-sm'>
                        {freightDetails?.consignee_phone?.phone ? freightDetails?.consignee_phone?.phone : 'N/A'}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Shipment Details */}
          <div className='px-4 md:px-8 md:py-4 flex flex-col pb-6'>
            {freightDetails?.container_details?.length > 1 ? (
              <div className='top-divider left-divider bottom-divider right-divider p-4 rounded'>
                <p className='black-text-4 font-normal'>{`Shipment Details for ${converter.toWords(
                  freightDetails?.container_details?.length
                )} pallets`}</p>
                <div>
                  {freightDetails?.container_details?.map((item: any, idx) => {
                    return (
                      <>
                        <div
                          className='flex solid-br px-4 py-4 rounded cursor-pointer bottom-divider mt-5'
                          style={{ width: '-webkit-fill-available' }}
                          onClick={() => toggleAccordion(idx)}
                        >
                          <p className='black-text-3 text-sm font-light capitalize'>Details for Pallet {idx + 1}</p>
                          <div className='ml-auto mt-2'>
                            <img src={accordion[idx] ? chevronUp : chevronDown} alt='' width={16} />
                          </div>
                        </div>
                        {accordion[idx] && (
                          <>
                            <div className='grid grid-cols-2 text-sm pt-6 pb-10 gap-x-4 px-6 gap-y-[26px] right-divider left-divider bottom-divider'>
                              <>
                                <div>
                                  <p className='grey-text font-light pb-1.5'>Volumetric Weight (CM^3)</p>
                                  <p className='black-text-3'>{item?.container_weight ? item?.container_weight : 'N/A'}</p>
                                </div>
                                <div>
                                  <p className='grey-text font-light pb-1.5'>Width of Cargo (CM)</p>
                                  <p className='black-text-3'>{item?.container_width ? item?.container_width : 'N/A'}</p>
                                </div>
                                <div>
                                  <p className='grey-text font-light pb-1.5'>Height of Cargo (CM)</p>
                                  <p className='black-text-3'>{item?.container_height ? item?.container_height : 'N/A'}</p>
                                </div>
                                <div>
                                  <p className='grey-text font-light pb-1.5'>Length of Cargo (CM)</p>
                                  <p className='black-text-3'>{item?.container_length ? item?.container_length : 'N/A'}</p>
                                </div>
                              </>
                            </div>
                          </>
                        )}
                      </>
                    )
                  })}
                </div>
              </div>
            ) : (
              <>
                <div>
                  <div
                    className='solid-br py-2 px-4 flex justify-between items-center rounded cursor-pointer'
                    onClick={() => setShowShipmentDetails(!showShipmentDetails)}
                  >
                    <div className='rounded'>
                      <p className='black-text-4 font-normal'>Shipment Details</p>
                      <p className='black-text font-light text-xs'>Includes details of all cargoes</p>
                    </div>
                    <span>
                      <img src={showChargeDetails ? chevronUp : chevronDown} alt='' />
                    </span>
                  </div>
                  {showShipmentDetails && (
                    <div className='left-divider bottom-divider right-divider p-4 '>
                      <div className='grid grid-cols-2 gap-4 py-3'>
                        <div>
                          <p className='grey-text font-normal text-xs'>Number of Cargoes</p>
                          <p className='black-text-4 font-normal text-sm'>{freightDetails?.clickCount}</p>
                        </div>
                        <div>
                          <p className='grey-text font-normal text-xs'>Goods Type</p>
                          <p className='black-text-4 font-normal text-sm'>
                            {freightDetails?.goods_type?.value === 'general_cargo' ? (
                              <>General Cargo</>
                            ) : (
                              freightDetails?.goods_type?.value === 'hazadous' && <>Hazardous Cargo</>
                            )}
                          </p>
                        </div>
                        <div>
                          <p className='grey-text font-normal text-xs'>Form M</p>
                          <p className='black-text-4 font-normal text-sm'>{freightDetails?.form_m_number}</p>
                        </div>
                        <div>
                          <p className='grey-text font-normal text-xs'>Banker's Acceptance number</p>
                          <p className='black-text-4 font-normal text-sm'>{freightDetails?.bankers_acceptance_number}</p>
                        </div>
                        <div>
                          <p className='grey-text font-normal text-xs'>Volumetric Cargo Weight</p>
                          <p className='black-text-4 font-normal text-sm'>{freightDetails?.container_details?.[0]?.container_weight}</p>
                        </div>
                        <div>
                          <p className='grey-text font-normal text-xs'>Cargo Length</p>
                          <p className='black-text-4 font-normal text-sm'>{freightDetails?.container_details?.[0]?.container_length}</p>
                        </div>
                        <div>
                          <p className='grey-text font-normal text-xs'>Cargo Width</p>
                          <p className='black-text-4 font-normal text-sm'>{freightDetails?.container_details?.[0]?.container_width}</p>
                        </div>
                        <div>
                          <p className='grey-text font-normal text-xs'>Cargo Height</p>
                          <p className='black-text-4 font-normal text-sm'>{freightDetails?.container_details?.[0]?.container_height}</p>
                        </div>
                        <div>
                          <p className='grey-text font-normal text-xs'>Goods Weight</p>
                          <p className='black-text-4 font-normal text-sm'>{freightDetails?.goods_weight} KG</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          <div className='py-6 px-8 shadow-[0_-3px_32px_-12px_rgba(0,0,0,0.18)]'>
            <div className='flex flex-col gap-y-4'>
              <PrimaryButtons
                title='Confirm Booking'
                disabled={false}
                loading={confirming_booking}
                icon={arrowright}
                iconRight={true}
                isExtraText={true}
                class_names={'newratebg'}
                onClick={() => confirm()}
              />
              <OutlineButton
                title='Close'
                loading={false}
                disabled={false}
                icon={''}
                onClick={() => closeBookingSummaryModal()}
                style={{ width: '100%', color: '#6B7280', backgroundColor: '#F9FAFB' }}
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  )
}

export default BookingSummaryModal
