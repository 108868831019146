import React from 'react'
import AirTermsAndCiond from './AirTermsAndCiond'
import ImportTermsAndCond from './ImportTermsAndCond'
import ExportTermsAndCond from './ExportTermsAndCond'
interface Props {
  booking_details: any
}

const NigeriaTermsAndCond = ({ booking_details }: Props) => {
  return (
    <>
      {booking_details?.shipment_transport_type == 'air_freight' ? (
        <AirTermsAndCiond />
      ) : booking_details?.shipment_type == 'import' ? (
        <ImportTermsAndCond />
      ) : (
        <ExportTermsAndCond />
      )}
    </>
  )
}

export default NigeriaTermsAndCond
