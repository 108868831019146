import React, { useState } from 'react'
import UnitTermsAndCond from './UnitTermsAndCond'
import AirTermsAndCiond from './AirTermsAndCiond'
import ImportTermsAndCond from './ImportTermsAndCond'
import ExportTermsAndCond from './ExportTermsAndCond'
import NigeriaTermsAndCond from './NigeriaTermsAndCond'
import GhanaTermsAndCond from './GhanaTermsAndCond'
import { useSelector } from 'react-redux'
import { branch_to_countries } from 'helpers/branchToCountriesHelper'
interface Props {
  booking_details: any
}

const TermsAndConditionsComp = ({ booking_details }: Props) => {
  console.log(booking_details.branch)
  return (
    <>
      {branch_to_countries[booking_details?.branch] == 'NG' ? (
        <NigeriaTermsAndCond booking_details={booking_details} />
      ) : branch_to_countries[booking_details?.branch] == 'GH' ? (
        <GhanaTermsAndCond booking_details={booking_details} />
      ) : (
        <NigeriaTermsAndCond booking_details={booking_details} />
      )}
    </>
  )
}

export default TermsAndConditionsComp
