import React from 'react'

//libraries
import moment from 'moment'

//icons
import trailball from 'assets/icons/trailball.svg'
import trailpoint from 'assets/icons/trailpoint.svg'
import trailline from 'assets/icons/trail-line.svg'

const ShipmentTrackingAir = ({ airtrack_data }) => {
  return (
    <div className='solid-br rounded no-inner'>
      <p className='black-text-3 p-6 font-normal bottom-divider-2'>Shipment Tracking Log</p>
      <div>
        <div className='top-divider p-6 relative'>
          {airtrack_data?.map((item) => (
            <>
              <div className='mb-8 flex items-center'>
                <div className='mr-8'>
                  <p className={`black-text-3 font-light text-base text-[#344336] w-[42px] max-w-[42px] ${item?.is_ticked ? '' : ''}`}>
                    {item?.is_ticked ? moment.utc(item.ticked_date).utcOffset('+0100').format('HH:mm') : '...'}
                  </p>
                  <p className='text-[13px] grey-text w-[42px] min-w-[50px]'>{item?.is_ticked ? moment(item.ticked_date).format('MMM DD') : ''}</p>
                </div>
                <span>
                  <img src={item?.is_ticked ? trailpoint : trailball} className={`${!item?.is_ticked ? 'ml-1 min-w-2' : 'min-w-4'}`} alt='' />
                </span>
                <p className='ml-4 text-[15px] font-light black-text-3'>{item.name}</p>
              </div>
            </>
          ))}
          <img
            className={`absolute z-[-1] top-1 left-[114px]`}
            style={{
              height: airtrack_data?.length * 65,
              width: '1px',
            }}
            src={trailline}
            alt=''
          />
        </div>
      </div>
    </div>
  )
}

export default ShipmentTrackingAir
