import { useState } from 'react'

//icons
import search from 'assets/icons/search.svg'

//components
import PrimaryButtons from 'components/buttons/PrimaryButtons'

//types
import { ShipmentUploadSearchType, document_name } from './type'

const ShipmentUploadSearch = ({ openModal, setFilteredDocuments, documents }: ShipmentUploadSearchType) => {
  const [query, setQuery] = useState('')

  const handleSearch = (e) => {
    const value = e.target.value
    setQuery(value)

    const filtered = documents.filter(
      (item) =>
        document_name?.[item.document_name]?.toLowerCase().includes(value.toLowerCase()) ||
        item.document_name?.toLowerCase().includes(value.toLowerCase())
    )
    setFilteredDocuments(filtered)
  }

  return (
    <div className='flex flex-col gap-y-4 sm:gap-y-0 sm:flex-row sm:justify-between sm:items-center mb-8'>
      <div className='flex items-center gap-x-1 form-input px-4 py-[9px] custom-input w-full black-text sm:w-[264px] order-2 sm:order-[-1]'>
        <div>
          <img src={search} alt=''></img>
        </div>
        <input placeholder='Search for a document' className='' value={query} onChange={handleSearch} />
      </div>
      <PrimaryButtons title='Upload Documents' disabled={false} onClick={() => openModal()} style={{ backgroundColor: '#37B248' }} />
    </div>
  )
}

export default ShipmentUploadSearch
