import { call, put, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify'

//services
import quoteServices from 'api/services/rate/quote'

//helpers
import { errorHandler } from 'helpers/errorHandler'

//redux
import { GET_QUOTES, GET_QUOTE_BY_ID, ACCEPT_QUOTE, REQUEST_FOLLOW_UP, UPDATE_QUOTE_STATUS, REJECT_QUOTE } from './constants'
import { ResponseGenerator } from 'store/type'
import {
  getMyQuotesSuccess,
  getQuotesByIdSuccess,
  acceptQuoteSuccess,
  requestFollowUpSuccess,
  quoteApiError,
  updateQuoteStatusFailure,
  acceptQuoteFailure,
} from 'store/actions'

function* initiateQuotesRequest({ payload }: any) {
  const { data } = payload
  try {
    const response: ResponseGenerator = yield call(quoteServices.getMyQuotes, data)

    if (response.data) {
      yield put(getMyQuotesSuccess(response.data))
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(quoteApiError(message))
  }
}

function* getQuoteById({ payload }: any) {
  try {
    const { id } = payload

    const response: ResponseGenerator = yield call(quoteServices.getQuoteById, id)

    if (response.data) {
      yield put(getQuotesByIdSuccess(response.data))
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(quoteApiError(message))
  }
}

function* acceptQuote({ payload }: any) {
  try {
    const { data } = payload
    const response: ResponseGenerator = yield call(quoteServices.updateQuoteStatus, data)
    if (response.data) {
      yield put(acceptQuoteSuccess(response.data))

      toast.success('Quote Accepted successfully')
      window.location.reload()
    }
  } catch (error) {
    yield put(acceptQuoteFailure(error.response.data))
  }
}

function* rejectQuote({ payload }: any) {
  try {
    const { data } = payload
    const response: ResponseGenerator = yield call(quoteServices.updateQuoteStatus, data)
    if (response.data) {
      yield put(acceptQuoteFailure(response.data))
      window.location.reload()
      toast.success('Quote Rejected successfully')
    }
  } catch (error) {
    yield put(acceptQuoteFailure(error.response.data))
  }
}

function* requestFollowUp({ payload }: any) {
  try {
    const { data } = payload

    const response: ResponseGenerator = yield call(quoteServices.requestFollowUp, data)

    if (response.data) {
      yield put(requestFollowUpSuccess(response.data))
      toast.success('Follow up request sent successfully')
      window.location.reload()
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(quoteApiError(message))
  }
}

function* updateQuoteSta({ payload }: any) {
  try {
    const { data } = payload
    yield call(quoteServices.updateQuoteStatus, data)
  } catch (error) {
    yield put(updateQuoteStatusFailure(error))
  }
}

function* quoteSaga() {
  yield takeEvery(GET_QUOTES.REQUEST, initiateQuotesRequest)
  yield takeEvery(GET_QUOTE_BY_ID.REQUEST, getQuoteById)
  yield takeEvery(ACCEPT_QUOTE.REQUEST, acceptQuote)
  yield takeEvery(REJECT_QUOTE.REQUEST, rejectQuote)
  yield takeEvery(REQUEST_FOLLOW_UP.REQUEST, requestFollowUp)
  yield takeEvery(UPDATE_QUOTE_STATUS.REQUEST, updateQuoteSta)
}

export default quoteSaga
