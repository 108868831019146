import { get, patch, post, put } from 'helpers/axios'
import { getMyQuotesUrl, getQuoteByIdUrl, linkUserToQuoteUrl, acceptQuoteURl, requestFollowUpUrl, updateQuoteStatusUrl } from 'api/endpoints'

const getMyQuotes = (query:string) => {
  return get(`${getMyQuotesUrl}?${query}`)
}

const getQuoteById = (id: string) => {
  return get(getQuoteByIdUrl + `/${id}`)
}

const linkUserToQuote = (data: any) => {
  const { id, token } = data
  let auth = token
    ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    : {}

  return put(linkUserToQuoteUrl + `/${id}`, {}, auth)
}

const acceptQuote = (id: number) => {
  return post(acceptQuoteURl + `/${id}`, {quote_id: id})
}

const requestFollowUp = (data: any) => {
  return put(requestFollowUpUrl + `/${data.id}`, data.data)
}

const updateQuoteStatus = (data: {id:number; state: {status: string; reason: string}}) => {
  return put(updateQuoteStatusUrl + `/${data.id}`, data.state)
}

const quoteServices = {
  getMyQuotes,
  getQuoteById,
  linkUserToQuote,
  acceptQuote,
  requestFollowUp,
  updateQuoteStatus
}

export default quoteServices
