import React, { useState } from 'react'

import disclaimer from 'assets/icons/circledisclaimer-white.svg'
import EnquiryModal from 'components/ofap/EnquiryModal'

const AirFreightWeightInfo = () => {
  const [showContact, setShowContact] = useState(false)

  const closeModal = () => setShowContact(false)

  return (
    <>
      <div className='flex gap-x-2 bg-[#004300] rounded text-white py-3 px-4 my-5'>
        <span>
          <img src={disclaimer} alt='' className='mt-[2px] min-w-[16px]' />
        </span>
        <p className='text-sm font-light'>
          <span className='font-medium uppercase manro manro'>please note! </span>
          <span className='manro'>
            Are you shipping over 9,000 KG?, Kindly contact support{' '}
            <a
              href='mailto:“oluwatobim@oneport365.com?subject=Shipment%20weighs%20more%20than%209000kg'
              // onClick={() => setShowContact(true)}
              className='text-[#98FF9B] underline cursor-pointer'
            >
              here
            </a>
          </span>
        </p>
      </div>
      <EnquiryModal isOpen={showContact} closeModal={closeModal} shouldCloseModal={true} setShowContact={setShowContact} />
    </>
  )
}

export default AirFreightWeightInfo
