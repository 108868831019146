import React from 'react'
import notice from '../../assets/icons/notice.svg'
import AirQuoteNotes from './AirQuoteNotes'
import ImportQuoteNotes from './ImportQuoteNotes'
import ExportTermsAndCond from './TermsAndConditions/ExportTermsAndCond'
import ExportQuotesNotes from './ExportQuotesNotes'
interface Props {
  booking_details: any
}
const QuoteNote = ({ booking_details }: Props) => {
  return (
    <>
      {booking_details?.shipment_transport_type == 'air_freight' ? (
        <AirQuoteNotes booking_details={booking_details} />
      ) : booking_details?.shipment_type == 'import' ? (
        <ImportQuoteNotes booking_details={booking_details} />
      ) : (
        <ExportQuotesNotes booking_details={booking_details} />
      )}
    </>
  )
}

export default QuoteNote
