import React from 'react'
import { capitalize, formatShipmentType, formatValue } from 'util/format'
import BookingDetailComp from './BookingDetailComp'
import { airFreightOriginDestinationFormat } from 'helpers/airFreightOriginDestinationFormat'

interface BookingDetailsProp {
  companyDetails?: {
    firstname: string
    lastname: string
    email: string
  }
  bookingDetails?: {
    goods_type: string
    shipment_type: string
    shipment_transport_type: string
    container_details: { container_size: string }[]
    origin_port_code: string
    destination_port_code: string
    origin_port: string
    destination_port: string
    goods_weight: string
    air_cargo_details: any
  }
  afapType: any
  single_booking?: any
  isVisible: boolean
}

const pickupLabelMap = {
  port_to_port: 'Pick up Airport',
  door_to_port: 'Pick up Address',
  door_to_door: 'Pick up Address',
  port_to_door: 'Pick up Airport',
};

const destinationLabelMap = {
  port_to_port: 'Destination Airport',
  door_to_port: 'Destination Airport',
  door_to_door: 'Destination Address',
  port_to_door: 'Destination Address',
};

const defaultPickupLabel = 'Pick Up Location';
const defaultDestinationLabel = 'Destination Location';

const AirFreightBookingDetails = ({ companyDetails, isVisible, afapType, bookingDetails, single_booking }: BookingDetailsProp) => {
  const pickupLabelMap = {
    port_to_port: 'Pick up Airport',
    door_to_port: 'Pick up Address',
    door_to_door: 'Pick up Address',
    port_to_door: 'Pick up Airport',
  }

  const destinationLabelMap = {
    port_to_port: 'Destination Airport',
    door_to_port: 'Destination Airport',
    door_to_door: 'Destination Address',
    port_to_door: 'Destination Address',
  }

  const defaultPickupLabel = 'Pick Up Location'
  const defaultDestinationLabel = 'Destination Location'

  console.log('single booking', single_booking)

  return (
    <div className={`transition-all py-6 duration-300  ease-in-out ${isVisible ? 'block  flex flex-col gap-[32px]' : 'hidden overflow-hidden'}`}>
      <div className='grid grid-cols-2 lg:grid-cols-4 gap-x-10 gap-y-[32px]'>
        <BookingDetailComp label='Customer Name' details={`${companyDetails?.firstname} ${companyDetails?.lastname}`} email={false} />

        <BookingDetailComp label='Email Address' details={`${companyDetails?.email}`} email={true} />

        <BookingDetailComp label='Commodity' details={`${single_booking?.commodity_description}`} email={false} />

        <BookingDetailComp
          label='Service Type'
          details={formatShipmentType(`${single_booking?.shipment_type} ${single_booking?.shipment_transport_type}`)}
          email={false}
        />

        <BookingDetailComp
          label={`${pickupLabelMap[afapType] || defaultPickupLabel}`}
          details={airFreightOriginDestinationFormat(afapType, 'origin', single_booking)}
        />

        <BookingDetailComp
          label={`${destinationLabelMap[afapType] || defaultDestinationLabel}`}
          details={airFreightOriginDestinationFormat(afapType, 'destination', single_booking)}
        />
        <BookingDetailComp label='Total Weight of goods (KG)' details={`${single_booking?.goods_weight}`} />

        {/* <BookingDetailComp label='Number of Pallets' details={`${single_booking?.air_cargo_details?.length}`} /> */}
      </div>
    </div>
  )
}

export default AirFreightBookingDetails
