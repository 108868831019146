import React from 'react'
import UnitTermsAndCond from './UnitTermsAndCond'

const AirTermsAndCiond = () => {
  return (
    <div className='text-sm black-text  '>
      <h1 className='text-[#4B5563] text-[10px] md:text-[20px] ' style={{ fontWeight: 500 }}>
        ONEPORT365 TERMS AND CONDITIONS
      </h1>

      <div className='border-[#F3F4F6] min-w-[300px]  border-[1.5px] rounded-[16px] mt-[24px] py-[10px] px-[24px] '>
        <div className=' flex flex-col gap-[8px]  '>
          <UnitTermsAndCond number='1' terms=' Above rates are for cargo details as provided by you.' />

          <UnitTermsAndCond number='2' terms=' Above quote is/are subject to VAT.' />

          <UnitTermsAndCond
            number='3'
            terms='Above quoted rates are on Door-to-Door basis excludes of Duties at
          the time of exports.'
          />

          <UnitTermsAndCond number='4' terms=' Standard Trading Terms and Conditions of Oneport365 applies.' />

          <UnitTermsAndCond
            number='5'
            terms='Above rates excludes services like packing, re-packing, Customs
          Inspection etc which may be charged additional(if required) with
          prior customer approval.'
          />

          <UnitTermsAndCond number='6' terms='Above rates do not cover Insurance charges.' />

          <UnitTermsAndCond
            number='7'
            terms='Above rates does not include any additional services required
          e.g.- special handling, week-end pick-up/delivery which has not
          been agreed and same will be charged as mutually agreed before
          services are rendered.'
          />

          <UnitTermsAndCond
            number='8'
            terms=' Above rates apply for weight/volume (whichever is higher). Rates
          are based on ratio 1:6.'
          />

          <UnitTermsAndCond
            number='9'
            terms='Quoted rates are valid for a period of one month and will need
          prior approval from Oneport365 incase further extension is
          required.'
          />

          <UnitTermsAndCond
            number='10'
            terms=' Charges are based on shipment details provided by you: if
          quantity/weight will vary our quotation will change accordingly.'
          />

          <UnitTermsAndCond
            number='11'
            terms='Pricing team has the right to re-price if the actual cargo details
          differ from the information indicated in enquiry.'
          />

          <UnitTermsAndCond
            number='12'
            terms='Unless otherwise specified, any haulage included within the quote
          is based upon standard roadside only and between business hours
          Monday to Friday.'
          />
        </div>
      </div>
    </div>
  )
}

export default AirTermsAndCiond
