import React from 'react'
import { Routes, Route } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import PublicRoute from './PublicRoute'
import AuthRoute from './AuthRoute'

//Coming soon
import ComingSoon from 'components/partials/ComingSoon'

//Authentication
import SignIns from 'pages/auth/SignIns'
import SignsUp from 'pages/auth/SignsUp'
import EmailVerifications from 'pages/auth/EmailVerifications'
import PasswordConfirmation from 'pages/auth/PasswordConfirmation'
import ForgotPasswords from 'pages/auth/ForgotPasswords'
import SignUpConfirmation from 'pages/auth/SignUpConfirmation'

//Booking
import Booking from 'pages/booking/Booking'
import BookingDetailsRoot from 'pages/booking/BookingDetailsRoot'

//404 page
import NotFound from 'pages/notFound'

//Rate Request
import RateRequest from 'pages/rate/RateRequest'

//Rates
import Rate from 'pages/rates/Rate'
import DashboardRate from 'pages/rate/DashboardRate'
import RateConfirmation from 'pages/rate/RateConfirmation'
// import RecentlySearched from "pages/rate/RecentlySearched";

//Shipping liner
import ShippingLiner from 'pages/shippingLiner/ShippingLiner'

//Additional services
import AdditionalDetails from 'pages/additionalDetails/AdditionalDetails'

//Dashboard
import Dashboards from 'pages/dashboard/Dashboards'

//Shipment
import Shipments from 'pages/shipment/Shipments'
import ShipmentDataSheet from 'pages/shipmentDataSheet/ShipmentDataSheet'
import ShipmentDetailsRoot from 'pages/shipment/ShipmentDetailsRoot'
import InvalidShipment from 'pages/shipment/InvalidShipment'

//Tracking
import OpenContainers from 'pages/dock/OfapLink/OpenContainers'

//Tradefinance
import TradeFinance from 'pages/tradeFinance/TradeFinance'

//Document
import Documents from 'pages/document/Documents'

import OpenDocumentUpload from 'components/shipment/afapShipment/OpenDocumentUpload'

//Settings
import Settings from 'pages/settings/Settings'

import BookingDetails from 'pages/booking/BookingDetails'

//Dock
import Dock from 'pages/dock/Dock/Dock'

//containers
import Containers from 'pages/containers/Containers'
import ContainerDetails from 'pages/containers/ContainerDetails'
import NewContainerDetails from 'pages/containers/NewContainerDetails'
import MasterMap from 'components/maps/MasterMap'

//Teams
import Team from 'pages/team/Team'
import TeamVerification from 'pages/auth/TeamVerification'

//freight details
import FreightDetails from 'pages/ofap/FreightDetails'
import FreightRates from 'pages/ofap/FreightRates'
import Haulage from 'pages/haulage/Haulage'
import InHaulage from 'pages/haulage/InHaulage'
import ShipmentInformation from 'pages/ofap/ShipmentInformation'
import OpenShipmentInformation from 'pages/dock/OfapLink/OpenShipmentInformation'
import OpenFreightRates from 'pages/dock/OfapLink/OpenFreightRates'
import CustomBrokerage from 'pages/customBrokerage/CustomBrokerage'
import InCustomBrokerage from 'pages/customBrokerage/InCustomBrokerage'
import AirFreightDetails from 'pages/afap/AirFreightDetails'
import InAirFreightDetails from 'pages/afap/InAirFreightDetails'
import Quote from 'pages/quote/Quote'
//scroll manager
import { ScrollManager } from 'components/scrollManager/ScrollManager'
import QuoteDetails from 'pages/quote/QuoteDetails'

const About = React.lazy(() => import('pages/dock/About/About'))
const Contact = React.lazy(() => import('pages/dock/Contact/Contact'))
const AirFreight = React.lazy(() => import('pages/dock/services/AirFreight'))
const OceanFreight = React.lazy(() => import('pages/dock/services/OceanFreight'))
const CustomsBrokerage = React.lazy(() => import('pages/dock/services/CustomsBrokerage'))
const InlandLogistics = React.lazy(() => import('pages/dock/services/InlandLogistics'))

//Terms
const Terms = React.lazy(() => import('pages/terms/Terms'))
const PrivacyPolicy = React.lazy(() => import('pages/terms/PrivacyPolicy'))

const Spinner = () => <div className='spinner spinner-green'></div>

const Router = () => {
  return (
    <ScrollManager>
      <React.Suspense
        fallback={
          <div className='flex items-center justify-center py-4'>
            <Spinner />
          </div>
        }
      >
        <Routes>
          {/* authentication */}
          <Route
            path='/signin'
            element={
              <AuthRoute>
                <SignIns />
              </AuthRoute>
            }
          />
          <Route
            path='/signup'
            element={
              <AuthRoute>
                <SignsUp />
              </AuthRoute>
            }
          />
          <Route
            path='/verify-email'
            element={
              <AuthRoute>
                <EmailVerifications />
              </AuthRoute>
            }
          />
          <Route
            path='/forgot-password'
            element={
              <AuthRoute>
                <ForgotPasswords />
              </AuthRoute>
            }
          />

          {/* home  */}

          {/* New Landing*/}
          <Route path='/' element={<Dock />} />
          <Route path='/about' element={<About />} />
          <Route path='/services/ocean-freight' element={<OceanFreight />} />
          <Route path='/services/air-freight' element={<AirFreight />} />
          <Route path='/services/inland-logistics' element={<InlandLogistics />} />
          <Route path='/services/custom-brokerage' element={<CustomsBrokerage />} />
          <Route path='/contact' element={<Contact />} />

          {/* Dock */}

          {/* dashboard  */}

          <Route
            path='/dashboard'
            element={
              <ProtectedRoute>
                <Dashboards />
              </ProtectedRoute>
            }
          />

          {/* shipment  */}
          <Route
            path='/shipments'
            element={
              <ProtectedRoute>
                <Shipments />
              </ProtectedRoute>
            }
          />
          <Route
            path='/shipment-datasheet/:id'
            element={
              <ProtectedRoute>
                <ShipmentDataSheet />
              </ProtectedRoute>
            }
          />
          <Route
            path='/shipments/:id'
            element={
              <ProtectedRoute>
                <ShipmentDetailsRoot />
              </ProtectedRoute>
            }
          />
          <Route
            path='/invalidshipment'
            element={
              <ProtectedRoute>
                <InvalidShipment />
              </ProtectedRoute>
            }
          />

          {/* Rate request */}
          {/* <Route
            path={"/booking/details"}
            element={
              <ProtectedRoute>
                <Rate />
              </ProtectedRoute>
            }
          /> */}
          <Route
            path={'/booking/shipping-liners'}
            element={
              <ProtectedRoute>
                <ShippingLiner />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path={"/booking/additional-services"}
            element={
              <ProtectedRoute>
                <AdditionalDetails />
              </ProtectedRoute>
            }
          /> */}

          <Route path='/get-rate' element={<RateRequest />} />

          {/* <Route
          path={"/new-shipment"}
          element={
            <ProtectedRoute>
              <DashboardRateRequest />
            </ProtectedRoute>
          }
        /> */}

          {/* <Route
          path="/new-shipment/:id"
          element={
            <ProtectedRoute>
              <DashboardRateRequest />
            </ProtectedRoute>
          }
        /> */}

          {/* Rates */}
          <Route
            path='/rates/:id'
            element={
              <ProtectedRoute>
                <DashboardRate />
              </ProtectedRoute>
            }
          />
          <Route
            path='/rates-confirmation/:id'
            element={
              <ProtectedRoute>
                <RateConfirmation />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/booking-confirmation/:id"
            element={
              <ProtectedRoute>
                <BookingConfirmation />
              </ProtectedRoute>
            }
          /> */}
          {/* Team Member Verification */}
          <Route
            path='/team-verification'
            element={
              <ProtectedRoute>
                <TeamVerification />
              </ProtectedRoute>
            }
          />
          <Route path='/signup-success' element={<SignUpConfirmation />} />
          <Route path='/password-confirmation' element={<PasswordConfirmation />} />

          {/* Terms */}
          <Route path='/terms-of-service' element={<Terms />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />

          {/* Custom Brokerage */}
          <Route
            path='/custom-brokerage'
            element={
              <ProtectedRoute>
                <CustomBrokerage />
              </ProtectedRoute>
            }
          />
          <Route
            path='/custom-brokerage/:id'
            element={
              <ProtectedRoute>
                <CustomBrokerage />
              </ProtectedRoute>
            }
          />
          <Route
            path='/customs-brokerage/:id'
            element={
              <ProtectedRoute>
                <InCustomBrokerage />
              </ProtectedRoute>
            }
          />

          {/* Haulage */}
          <Route
            path='/haulage-details'
            element={
              <ProtectedRoute>
                <Haulage />
              </ProtectedRoute>
            }
          />
          <Route
            path='/haulage-details/:id'
            element={
              <ProtectedRoute>
                <Haulage />
              </ProtectedRoute>
            }
          />
          <Route
            path='/haulages-details/:id'
            element={
              <ProtectedRoute>
                <InHaulage />
              </ProtectedRoute>
            }
          />
          {/* ofap */}
          <Route
            path='/freight-details'
            element={
              <ProtectedRoute>
                <FreightDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path='/freight-details/:id'
            element={
              <ProtectedRoute>
                <FreightDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path='/freight-rates/:id'
            element={
              <ProtectedRoute>
                <FreightRates />
              </ProtectedRoute>
            }
          />
          <Route path='/openfreight-rates/:id' element={<OpenFreightRates />} />
          <Route path='/public-document-upload/:id' element={<OpenDocumentUpload />} />
          <Route
            path='/shipment-information/:id'
            element={
              <ProtectedRoute>
                <ShipmentInformation />
              </ProtectedRoute>
            }
          />
          <Route path='/openshipment-information/:id' element={<OpenShipmentInformation />} />

          {/* AFAP */}
          <Route
            path='/air-freight-details'
            element={
              <ProtectedRoute>
                <AirFreightDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path='/air-freight-details/:id'
            element={
              <ProtectedRoute>
                <AirFreightDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path='/air-freight-detail/:id'
            element={
              <ProtectedRoute>
                <InAirFreightDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path='/invoice/trade-finance'
            element={
              <ProtectedRoute>
                <TradeFinance />
              </ProtectedRoute>
            }
          />

          {/* document  */}
          <Route
            path='/documents'
            element={
              <ProtectedRoute>
                <Documents />
              </ProtectedRoute>
            }
          />

          {/* settings  */}
          <Route
            path='/settings'
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />

          <Route
            path='/settings/team'
            element={
              <ProtectedRoute>
                <Team />
              </ProtectedRoute>
            }
          />

          {/* containers  */}
          <Route
            path='/container'
            element={
              <ProtectedRoute>
                <Containers />
              </ProtectedRoute>
            }
          />

          <Route path='/open-container' element={<OpenContainers />} />

          <Route
            path='/container/:id'
            element={
              <ProtectedRoute>
                <NewContainerDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path='/mapframe/:id'
            element={
              <ProtectedRoute>
                <MasterMap />
              </ProtectedRoute>
            }
          />

          {/* Booking */}
          <Route
            path='/booking'
            element={
              <ProtectedRoute>
                <Booking />
              </ProtectedRoute>
            }
          />

          {/* Booking details */}
          <Route
            path='/booking/:id'
            element={
              <ProtectedRoute>
                {/* <BookingDetails /> */}
                <BookingDetailsRoot />
              </ProtectedRoute>
            }
          />

          {/* Quote */}
          <Route
            path='/quote'
            element={
              <ProtectedRoute>
                <Quote/>
              </ProtectedRoute>
            }
          />

<Route
            path='/quote/:id'
            element={
              <ProtectedRoute>
                <QuoteDetails/>
              </ProtectedRoute>
            }
          />

          

          {/* not found */}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </ScrollManager>
  )
}

export default Router
