import { GET_BOOKINGS, API_ERROR, GET_BOOKING_BY_ID } from 'store/bookings/constants'

const initialState = {
  error: null,
  loading: true,
  bookings: [],
  total_bookings: null,
  table_loading: false,
  single_booking: {}
  // single_booking: {},
}

const bookings = (state = initialState, action) => {
  console.log(action.payload?.data)
  switch (action.type) {
    case GET_BOOKINGS.SUCCESS:
      return {
        ...state,
        loading: false,
        bookings: action.payload.bookings,
        total_bookings: action.payload.total_bookings,
        table_loading: false,
      }

    case GET_BOOKINGS.REQUEST:
      return {
        ...state,
        table_loading: true,
      }
      case GET_BOOKING_BY_ID.REQUEST:
        return {
          ...state,
          loading: true
        }
      case GET_BOOKING_BY_ID.SUCCESS:
        return{
          ...state,
          laoding: false,
          single_booking: action.payload?.data
        }
    case API_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default bookings
