interface QuoteActionCompProps {
  onSuccess: () => void
  onShowRejectModal: () => void
  generatePdf: () => Promise<void>
  status: string
}

const QuoteActionComp = ({ onSuccess, onShowRejectModal, generatePdf, status }: QuoteActionCompProps) => {
  return (
    <div className='hidden md:flex flex-col lg:flex-row gap-[16px]'>
      {status === 'pending' && (
        <div className='flex flex-row gap-[16px] mt-[10px] md:mt-0'>
          <button
            onClick={onShowRejectModal}
            className={`rounded-[26px] inline-flex gap-[8px] items-center h-[36px] text-[#AD0013] border-[#AD0013] border-[1.5px] py-[11px] px-[8px]`}
          >
            <svg
              width='10'
              height='10'
              viewBox='0 0 10 10'
              fill='none'
              xmlns='
                    http://www.w3.org/2000/svg'
            >
              <path d='M8.99654 1.00586L1.00488 8.99752' stroke='#AD0013' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
              <path d='M8.99833 9L1 1' stroke='#AD0013' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
            </svg>
            <span className='word whitespace-nowrap'>Reject Quote</span>
          </button>

          <button
            onClick={onSuccess}
            className={`rounded-[26px] inline-flex gap-[8px] items-center h-[36px] text-[#059C01] border-[#059C01] border-[1.5px] py-[11px] px-[8px]`}
          >
            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M1.99902 8L6.00071 12L14.0007 4' stroke='#059C01' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
            </svg>
            <span className='word whitespace-nowrap'>Accept Quote</span>
          </button>
        </div>
      )}

      <div className='border border-[#e5e7eb] cursor-pointer rounded-[8px] min-w-[104px] max-w-[104px] min-h-[36px] max-h-[36px] lg:inline-flex items-center gap-[8px] py-[11px] hidden'>
        <span className='pl-[9px]'>
          <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M10.1019 12.8633L10.1019 2.8291' stroke='#296FD8' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
            <path
              d='M12.5322 10.4238L10.1022 12.8638L7.67223 10.4238'
              stroke='#296FD8'
              stroke-width='1.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
            <path
              d='M13.963 6.77344H14.7405C16.4364 6.77344 17.8105 8.1476 17.8105 9.84427V13.9143C17.8105 15.6059 16.4397 16.9768 14.748 16.9768H5.46471C3.76888 16.9768 2.39388 15.6018 2.39388 13.9059L2.39388 9.8351C2.39388 8.14427 3.76555 6.77344 5.45638 6.77344L6.24138 6.77344'
              stroke='#296FD8'
              stroke-width='1.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </span>
        <span onClick={generatePdf} className='pr-[9px] text-[12px] text-[#296FD8]'>
          Download
        </span>
      </div>
    </div>
  )
}

export default QuoteActionComp
