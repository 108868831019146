import React from 'react'
import notice from '../../assets/icons/notice.svg'

interface Props {
  booking_details: any
}
const AirQuoteNotes = ({ booking_details }: Props) => {
  return (
    <div className='relative bg-[#004300] py-[24px] px-[32px]  flex items-start gap-[10px]  rounded-[12px] '>
      <img src={notice} alt='' className='min-w-[20px]'></img>

      <div className='flex flex-col gap-[20px] text-[12px] md:text-[14px]  '>
        <p className='text-[#E5E7EB]'>
          Please note this offer is firm for acceptance within 48hours, otherwise above offer will be considered as invalid. Rates advised is subject
          to prevailing parallel market rate at time of invoice. Freight advised is subject to chargeable weight as declared by airline. Above tariff
          is not applicable to non-compliant shipments without Form Ms, PAARs.
        </p>
        <p className='text-[#E5E7EB]'>
          NOTE: duty and tax not inclusive in the rates advised. They will be advised when you provide the CIF value and H.S code We do trust that
          this offer meets your requirements. Please, contact us if any further explanation is required.
        </p>
      </div>
    </div>
  )
}

export default AirQuoteNotes
