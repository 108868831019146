import { useEffect, useState, useRef } from 'react'
import Layout from 'components/layout/Layout'
import arrowL from 'assets/icons/arrow-left2.svg'
import { useNavigate, useParams } from 'react-router-dom'
import RejectPromptQuote from 'components/quote/RejectPromptQuote'
import SuccessNotificationQuoteModal from '../../components/quote/SuccessNotificationQuoteModal'
import RejectNotificationQuoteModal from 'components/quote/RejectNotificationQuoteModal'
import { rejectQuote, acceptQuote, getBookingById, getQuotesById, getShipmentById, getShipmentsById } from 'store/actions'
import { connect } from 'react-redux'
import { quoteData } from 'store/quote/types'
import ViewQuoteTable from '../../components/quote/ViewQuoteTable/ViewQuoteTable'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import BookingDetails from 'components/booking/BookingDetails'
import LoadingSpinner from 'components/partials/LoadingSpinner'
import TermsAndConditionsComp from 'components/quote/TermsAndConditions'
import QuoteNote from 'components/quote/QuoteNote'
import QuoteAddress from 'components/quote/QuoteAddress'
import QuoteActionComp from 'components/quote/QuoteActionComp'
import MobileActionComp from 'components/quote/MobileActionComp'

const QuoteDetails = ({ getQuotesById, quote_data, getBookingById, single_booking, error, acceptQuote, loading }: any) => {
  const navigate = useNavigate()
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [showSucessModal, setShowSuccessModal] = useState(false)
  const [showRejectNotificationModal, setShowRejectNotificationModal] = useState(false)
  const [filteredData, setFilteredData] = useState<quoteData[]>([])
  const [status, setStatus] = useState(quote_data[0]?.status)
  const [shareModalVisible, setShareModalVisible] = useState(false)
  const { id } = useParams()
  const pdfRef = useRef<HTMLDivElement>(null)

  console.log('country', localStorage.getItem('country'))

  useEffect(() => {
    if (quote_data[0]?.status === '') {
      setStatus(quote_data[0]?.status)
    }
    setStatus(quote_data[0]?.status)
  }, [quote_data[0]?.status, status])

  useEffect(() => {
    getQuotesById(id as unknown as number)
  }, [])

  useEffect(() => {
    if (quote_data?.length > 0) {
      getBookingById(quote_data[0]?.shipment_id as unknown as number, false)
    }
  }, [quote_data])

  useEffect(() => {
    if (quote_data! == 0) {
      setFilteredData(quote_data)
    }
  }, [quote_data])

  const onReject = () => {
    setShowRejectModal(false)
    setShowRejectNotificationModal(true)
  }

  const onSuccess = () => {
    if (!error) {
      onSuccessHandle()
    }
    return
  }

  const generatePDF = async () => {
    const input = pdfRef.current
    if (!input) return

    // Temporarily remove overflow and set height to auto
    const originalOverflow = input.style.overflow
    const originalHeight = input.style.height
    input.style.overflow = 'visible'
    input.style.height = 'auto'

    // Get the device pixel ratio to scale the canvas
    const scale = window.devicePixelRatio

    // Create a canvas with appropriate scaling
    const canvas = await html2canvas(input, {
      scale,
      useCORS: true, // Enable cross-origin images
    })

    const imgData = canvas.toDataURL('image/png')

    // Create a new jsPDF instance
    const pdf = new jsPDF('p', 'mm', 'a4')

    // Calculate the dimensions of the image and PDF
    const pdfWidth = pdf.internal.pageSize.getWidth()
    const pdfHeight = pdf.internal.pageSize.getHeight()

    // Calculate the height of the canvas and number of pages required
    const canvasWidth = canvas.width
    const canvasHeight = canvas.height
    const imgHeight = (pdfWidth * canvasHeight) / canvasWidth
    const pageCount = Math.ceil(imgHeight / pdfHeight)

    // Add the image to the PDF with proper scaling and multiple pages
    for (let i = 0; i < pageCount; i++) {
      const position = -i * pdfHeight
      pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight)
      if (i < pageCount - 1) {
        pdf.addPage()
      }
    }

    // Save the PDF
    pdf.save('OnePort_quote.pdf')

    // Restore original overflow and height
    input.style.overflow = originalOverflow
    input.style.height = originalHeight
  }

  const onSuccessHandle = () => {
    const data = {
      id,
      state: {
        reason: 'accepted quote',
        status: 'accepted',
      },
    }
    acceptQuote(data)
    setShowSuccessModal(true)
  }

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Layout>
          <div className='pt-[50px] font-inter px-[5px] md:pl-[40px] dashboard-content-scroll'>
            <div className='flex items-center gap-x-1 mb-2 pl-[20px] cursor-pointer w-fit' onClick={() => navigate('/quote')}>
              <div className=''>
                <img src={arrowL} alt=''></img>
              </div>

              <p className='text-sm grey-text'>Back To Quotes</p>
            </div>

            <div className='flex flex-col gap-[20px] md:gap-0 md:flex-row justify-between pl-[20px] md:pl-[32px] pr-[64px] py-[15px] items-start md:items-center'>
              <div>
                <h2 className='text-[20px]'>
                  Quote Detail <span className='text-[#6B7280]'>{quote_data[0]?.quote_number}</span>
                  {'  '}
                </h2>
              </div>

              <QuoteActionComp generatePdf={generatePDF} status={status} onSuccess={onSuccess} onShowRejectModal={() => setShowRejectModal(true)} />
            </div>

            <div ref={pdfRef} className='border border-[#e5e7eb]  md:mt-[40px]  rounded-[12px]'>
              <div className='lg:p-[32px] p-4 2xl:mx-auto  flex flex-col gap-[48px]'>
                <QuoteAddress booking_details={single_booking}/>
                <BookingDetails
                  single_booking={single_booking}
                  bookingDetails={single_booking?.user_details ? single_booking?.user_details[0] : {}}
                  companyDetails={single_booking?.user_details ? single_booking?.user_details[0] : {}}
                  airFreightType={single_booking?.air_freight_data?.air_freight_type}
                />

                {quote_data.length > 0 ? (
                  quote_data?.map((item, _i) => (
                    <div className='relative mt-[48px] flex flex-col gap-[48px] md:pl-[24px] lg:pl-0' key={_i}>
                      {item.quotes_table_details?.map((item, _i) => (
                        <div className='space-y-[8px]' key={_i}>
                          <p className='text-[14px] text-[#9CA3AF]'>Quote Breakdown</p>
                          <h2 className=''>{item.table_name}</h2>
                          <ViewQuoteTable item={item} table_name={item.table_name} row_data={item.shipment_quotes_rows_details} />
                        </div>
                      ))}
                    </div>
                  ))
                ) : (
                  <></>
                )}

                <QuoteNote booking_details={single_booking} />
                <TermsAndConditionsComp booking_details={single_booking} />
              </div>
            </div>

            {/* <MobileActionComp generatePDF={generatePDF} /> */}

            {showRejectModal && <RejectPromptQuote quoteId={id as unknown as number} onReject={onReject} close={() => setShowRejectModal(false)} />}
            {showSucessModal && <SuccessNotificationQuoteModal />}
            {showRejectNotificationModal && <RejectNotificationQuoteModal close={() => setShowRejectNotificationModal(false)} />}
          </div>
        </Layout>
      )}
    </>
  )
}

const mapStateToProps = (state: any) => {
  const { my_quotes, quote_data, error, loading } = state.quote
  const { shipment_data } = state.shipment
  const { single_booking } = state.bookings
  return { my_quotes, quote_data, shipment_data, single_booking, error, loading }
}

export default connect(mapStateToProps, {
  getQuotesById,
  rejectQuote,
  getShipmentsById,
  getBookingById,
  acceptQuote,
})(QuoteDetails)
