import React, { useState } from 'react'
import { capitalize, formatShipmentType, formatValue } from 'util/format'
import OceanFreightBookingDetails from './OceanFreightBookingDetails'
import HaulageBookingDetails from './HaulageBookingDetails'
import AirFreightBookingDetails from './AirFreightBookingDetails'
import CBBookingDetails from './CBBookingDetails'
import BookingDetailComp from './BookingDetailComp'

interface BookingDetailsProps {
  companyDetails?: {
    firstname: string
    lastname: string
    email: string
  }
  bookingDetails?: {
    goods_type: string
    shipment_type: string
    shipment_transport_type: string
    container_details: { container_size: string }[]
    origin_port_code: string
    destination_port_code: string
    origin_port: string
    destination_port: string
    goods_weight: string
    air_cargo_details: any
    brokerage_type: string
    delivery_location: string
    port_of_discharge: string
    pickup_location: string
  }
  airFreightType?: any
  single_booking?: any
}

const BookingDetails: React.FC<BookingDetailsProps> = ({ companyDetails, bookingDetails, single_booking, airFreightType }) => {
  const [isVisible, setIsVisible] = useState(true)

  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  return (
    <div className='w-full bg-[#F9FAFB] rounded-[16px] px-[24px] py-[20px]'>
      <div className='font-normal text-[1rem] flex justify-between items-center'>
        <p>Booking Details</p>
        <img className='cursor-pointer' src={isVisible ? '/arrow_down.png' : '/arrow_up.png'} alt='toggle arrow' onClick={toggleVisibility} />
      </div>
      {single_booking?.shipment_transport_type === 'ocean_freight' ? (
        <OceanFreightBookingDetails
          single_booking={single_booking}
          companyDetails={companyDetails}
          bookingDetails={bookingDetails}
          isVisible={isVisible}
        />
      ) : single_booking?.shipment_transport_type === 'air_freight' ? (
        <AirFreightBookingDetails
          single_booking={single_booking}
          companyDetails={companyDetails}
          bookingDetails={bookingDetails}
          afapType={airFreightType}
          isVisible={isVisible}
        />
      ) : single_booking?.shipment_transport_type === 'haulage' ? (
        <HaulageBookingDetails
          single_booking={single_booking}
          companyDetails={companyDetails}
          bookingDetails={bookingDetails}
          isVisible={isVisible}
        />
      ) : single_booking?.shipment_transport_type === 'customs_brokerage' ? (
        <CBBookingDetails single_booking={single_booking} companyDetails={companyDetails} bookingDetails={bookingDetails} isVisible={isVisible} />
      ) : (
        <OceanFreightBookingDetails
          single_booking={single_booking}
          companyDetails={companyDetails}
          bookingDetails={bookingDetails}
          isVisible={isVisible}
        />
      )}
    </div>
  )
}

export default BookingDetails
