import React from 'react'
import UnitTermsAndCond from './UnitTermsAndCond'

const ImportTermsAndCond = () => {
  return (
    <div className='text-sm black-text  '>
      <h1 className='text-[#4B5563] text-[10px] md:text-[20px] ' style={{ fontWeight: 500 }}>
        ONEPORT365 TERMS AND CONDITIONS
      </h1>

      <div className='border-[#F3F4F6] min-w-[300px]  border-[1.5px] rounded-[16px] mt-[24px] py-[10px] px-[24px] '>
        <div className=' flex flex-col gap-[8px]  '>
          <UnitTermsAndCond
            number='1'
            terms='All documents related to clearing shipments is to be provided by the CLIENT where we do not handle documentation.'
          />

          <UnitTermsAndCond number='2' terms='CLIENT to ensure that all the details are correct and in order.' />

          <UnitTermsAndCond
            number='3'
            terms='CLIENT ensures that all permits required for the Importation of their commodity are provided and valid at the time of Import.'
          />

          <UnitTermsAndCond number='4' terms='The Goods imported are the same as the Goods declared on all Documents provided.' />

          <UnitTermsAndCond number='5' terms='CLIENT to ensure timely payment of Duty.' />

          <UnitTermsAndCond
            number='6'
            terms='CLIENT to offload SERVICE PROVIDER’s Trucks within 24Hrs of arrival at Facility, Truck demurrage per day applies for  
           delays@N30,000.'
          />

          <UnitTermsAndCond number='7' terms='CLIENT to provide Delivery address accurately on the DATASHEET.' />

          <UnitTermsAndCond
            number='8'
            terms='Any force majeure, incident or accident will be notified to Client and necessary solutions will be provided.'
          />

          <UnitTermsAndCond number='9' terms='Counter for number of days shall halt where we have to resolve queries or incomplete documentation.' />

          <UnitTermsAndCond number='10' terms='For NAFDAC regulated items;' />

          <UnitTermsAndCond
            number='11'
            terms='Client to provide samples within 24 hrs of discharge of cargo at warehouse for submission to NAFDAC for 2nd stamping to effect 
           full release.'
          />

          <UnitTermsAndCond
            number='12'
            terms='Client to ensure quantities supplied as samples fit item of import & batch number as per the NAFDAC requirements.'
          />
          <UnitTermsAndCond
            number='13'
            terms='Client to ensure all documentation regarding NAFDAC certificates, Current year import permits, applicable HS codes are valid as per  
           the standard import guidelines requirements.'
          />
          <UnitTermsAndCond
            number='14'
            terms='For chemicals requiring EUC certificates Client to ensure provision of certificates ahead of customs clearing'
          />
          <UnitTermsAndCond number='15' terms='All shipments from China and lndia must be accompanied by laboratory stamped CRIA' />
        </div>
      </div>
    </div>
  )
}

export default ImportTermsAndCond
