import React from 'react'
import { useSelector } from 'react-redux'

//icons
import cargoInc from 'assets/icons/cargoInc.svg'
import cargoDec from 'assets/icons/cargoDec.svg'

//components
import CustomInput from 'components/textInputs/CustomInput'
import CustomRadio from 'components/selectInputs/CustomRadio'
import CustomDefaultSelect from 'components/selectInputs/CustomDefaultSelect'
import CustomPhoneInput from 'components/textInputs/CustomPhoneInput'
import AFAPNotification from 'components/airfreight/AFAPNotification'

interface AirFreightShipmentDetailsProps {
  errors: any
  control: any
  airFreightCategory?: string
  booking_data?: any
  addNewPallet?: any
  removePallet?: any
  clickCount?: any
  setClickCount?: any
  palletSize?: any
  setPalletSize?: any
  removeLastPallet?: () => void
  reset?: any
  setCargoVolume?: any
  resetPallet: () => void
}

const AirFreightShipmentDetails = ({
  airFreightCategory,
  errors,
  control,
  addNewPallet,
  removePallet,
  removeLastPallet,
  clickCount,
  setClickCount,
  palletSize,
  setPalletSize,
  reset,
  setCargoVolume,
  resetPallet,
}: AirFreightShipmentDetailsProps) => {
  let booking_data = useSelector((state: any) => state.booking.booking_data)

  const dispatchOptions = [
    { label: 'Dispatch Vans', value: 'dispatch_vans' },
    { label: 'Bikes', value: 'bikes' },
  ]

  const commodityOptions = [
    { label: 'General Cargo', value: 'general_cargo' },
    { label: 'Hazardous Cargo', value: 'hazardous' },
  ]

  console.log('palletSize', palletSize)

  const handleAddPallet = () => {
    // Increment the click count
    setClickCount(clickCount + 1)
    !palletSize && addNewPallet()
  }

  return (
    <div className='mt-10'>
      <h2 className='text-xl black-text-2 font-medium'>Shipment details</h2>
      <p className='black-text-4 text-sm font-light mt-1.5'>Please provide your shipment details</p>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 mt-11'>
        <div>
          <CustomInput
            control={control}
            name={`goods_weight`}
            id={`goods_weight`}
            label={'Total Weight of Goods (KG)'}
            placeholder={''}
            isRequired={true}
            type={'number'}
            errors={errors}
            isDisabled={false}
            defaultValue={booking_data?.goods_weight}
            min={'10'}
            max={'9000'}
            icon=''
            place='afap'
          />
        </div>

        <CustomDefaultSelect
          control={control}
          name={`goods_type`}
          id={`goods_type`}
          label={'Commodity Type'}
          placeholder={''}
          isRequired={true}
          errors={errors}
          isDisabled={false}
          options={commodityOptions}
          defaultValue={{}}
          icon=''
        />

        <CustomDefaultSelect
          control={control}
          name={`package_pickup_medium`}
          id={`package_pickup_medium`}
          label={'Package Pickup Medium'}
          placeholder={''}
          isRequired={true}
          errors={errors}
          isDisabled={false}
          options={dispatchOptions}
          defaultValue={{}}
          icon=''
        />

        <CustomInput
          control={control}
          name={'commodity_description'}
          id={'commodity_description'}
          label={'Commodity Description'}
          placeholder={''}
          isRequired={true}
          type={'text'}
          errors={errors}
          isDisabled={false}
          defaultValue={booking_data?.commodity_description}
          min={''}
          max={''}
          icon=''
        />
        <CustomInput
          control={control}
          name={'form_m_number'}
          id={'form_m_number'}
          label={'Form M (Optional)'}
          placeholder={'Enter number'}
          isRequired={false}
          type={'text'}
          errors={errors}
          isDisabled={false}
          defaultValue={booking_data?.form_m_number}
          min={''}
          max={''}
          icon=''
        />

        <CustomInput
          control={control}
          name={'bankers_acceptance_number'}
          id={'bankers_acceptance_number'}
          label={"Banker's Acceptance number (Optional)"}
          placeholder={'Enter number'}
          isRequired={false}
          type={'text'}
          errors={errors}
          isDisabled={false}
          defaultValue={booking_data?.bankers_acceptance_number}
          min={''}
          max={''}
          icon=''
        />
      </div>

      <h2 className='text-xl black-text-2 font-medium mt-10'>
        Contact details<span className='text-[#9CA3AF] font-light'> (Optional)</span>
      </h2>
      <p className='black-text-4 text-sm font-light mt-1.5'>Please provide the sender/receiver details for this shipment </p>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 mt-11 mb-5'>
        <CustomInput
          control={control}
          name={`consignee_name`}
          id={`consignee_name`}
          label={"Contact's Name"}
          placeholder={''}
          isRequired={false}
          type={'text'}
          errors={errors}
          isDisabled={false}
          defaultValue={booking_data?.consignee_name}
          min={''}
          max={''}
          icon=''
        />

        <CustomInput
          control={control}
          name={`consignee_email`}
          id={`consignee_email`}
          label={"Contact's Email"}
          placeholder={''}
          isRequired={false}
          type={'email'}
          errors={errors}
          isDisabled={false}
          defaultValue={booking_data?.consignee_email}
          min={''}
          max={''}
          icon=''
        />

        <CustomPhoneInput
          control={control}
          name={'consignee_phone'}
          id={'consignee_phone'}
          label={'Phone Number'}
          isRequired={false}
          defaultValue={''}
          placeholder={'Enter phone number'}
          isDisabled={false}
          errors={errors}
        />
      </div>
      <hr />
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 mt-8'>
        <div>
          <p className='text-[#6B7280] font-light mb-1'>Number of Pallets</p>
          <div className='flex rounded-md new-solid-br p-2.5 font-light text-[#1F2937] w-full'>
            <p>{clickCount}</p>
            <div className='mt-1 ml-auto'>
              <img onClick={handleAddPallet} src={cargoInc} alt='inc' className='mr-2' />
              <img onClick={removeLastPallet} src={cargoDec} alt='dec' className='mr-2' />
            </div>
          </div>
        </div>

        <div className='mb-5'>
          <div className='new-solid-br  p-4 rounded-md'>
            <div className=''>
              <p className='text-xs font-medium black-text mb-2'>Is each pallet of the same size?</p>
              <div className='grid grid-cols-2'>
                <div className='mr-1'>
                  <CustomRadio
                    selected={palletSize}
                    label={'Yes, It is'}
                    onClick={() => {
                      setPalletSize(true)
                      setClickCount(1)
                      resetPallet()
                    }}
                  />
                </div>

                <div className='ml-1'>
                  <CustomRadio
                    selected={!palletSize}
                    label={'No, It isnt'}
                    onClick={() => {
                      setPalletSize(false)
                      setClickCount(2)
                      resetPallet()
                      setCargoVolume({})
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='py-3'>
        {palletSize === false ? (
          <AFAPNotification text=' Each cargo has a different volumetric weight and size. And would be charged based on the higher weight between actual weight and volumetric weight' />
        ) : (
          <AFAPNotification text=' Each cargo has the same volumetric weight and size. And would be charged based on the higher weight between actual weight and volumetric weight' />
        )}
      </div>
    </div>
  )
}

export default AirFreightShipmentDetails
