export interface Document {
  _id: string
  shipment_id: string
  is_admin_upload: boolean
  is_deleted: boolean
  user_id: string
  document_name: string
  original_file_name: string
  document_location: string
  createdAt: string
  updatedAt: string
  __v: number
}

interface DeleteShipmentDocFunction {
  (id: string, callback: any): {
    type: string
    payload: {
      id: string
      callback: any
    }
  }
}

export type ShipmentUploadSearchType = {
  documents: Document[]
  openModal: () => void
  setFilteredDocuments: React.Dispatch<React.SetStateAction<Document[]>>
}

export type UploadDocumentType = {
  documents: Document[]
  filteredDocuments: Document[]
  upload_loading: boolean
  delete_shipdoc_loading: boolean
  deleteShipmentDoc: DeleteShipmentDocFunction
  setModalMode: React.Dispatch<React.SetStateAction<string>>
  openUploadModal: React.Dispatch<React.SetStateAction<boolean>>
  setUpdateDocDetails: React.Dispatch<React.SetStateAction<{}>>
}

export type ActionProps = {
  docId: string
  delete_shipdoc_loading: boolean
  linkView: string
  deleteDoc: (id: string) => void
  setModalMode: React.Dispatch<React.SetStateAction<string>>
  openUploadModal: React.Dispatch<React.SetStateAction<boolean>>
  // setUpdateDocDetails: React.Dispatch<React.SetStateAction<{}>>
}

export const document_name = {
  proforma_invoice: 'Proforma Invoice',
  product_certificate: 'Product Certificate',
  marine_insurance_certificate: 'Marine Insurance Certificate',
  form_M: 'Form M',
  bl: 'BL',
  commercial_invoice: 'Commercial Invoice (include NXP number)',
  packing_list: 'Packing List',
  combined_certificate: 'Combined Certificate',
  paar: 'Pre-Arrival Assessment Report',
  hs_code: 'HS Code',
  regulatory_permit: 'Regulatory Permit',
  bill_of_lading: 'Bill Of Lading',
  nafdac: 'NAFDAC',
  soncap: 'SONCAP',
  nxp_document: 'Approved NXP Form',
  shipping_datasheet: 'Shipping Datasheet',
  cci: 'CCI Clean Certificate of inspection',
  sgd: 'SGD And Inspection Act',
  custom_export_clearance: 'Custom Export Clearance',
  obl: 'OBL / SEAWAY BILL / TELEX',
  post_shipment_docs: 'Post Shipment Documents',
  nepc: 'Nigerian Export Promotion Council (NEPC)',
  cac: 'Customer Acquisition Cost (CAC)',
  commercial_invoice_air: 'Commercial Invoice',
  airway_bill: 'Airway Bill',
  airline_schedule: 'Airline schedule',
}

export const export_air_freight_documents = [
  { label: 'Commercial Invoice', value: 'commercial_invoice_air' },
  { label: 'Airway Bill', value: 'airway_bill' },
  { label: 'Airline schedule', value: 'airline_schedule' },
  { label: 'Form M', value: 'form_M' },
]
export const import_air_freight_documents = [
  { label: 'Packing List', value: 'packing_list' },
  { label: 'Commercial Invoice', value: 'commercial_invoice_air' },
  { label: 'Airway Bill ', value: 'airway_bill' },
  { label: 'Airline schedule', value: 'airline_schedule' },
  { label: 'Form M', value: 'form_M' },
]

export const import_ocean_freight_documents = [
  { label: 'Proforma Invoice', value: 'proforma_invoice' },
  { label: 'Product Certificate ', value: 'product_certificate' },
  { label: 'Marine Insurance Certificate', value: 'marine_insurance_certificate' },
  { label: 'Form M', value: 'form_M' },
  { label: 'BL', value: 'bl' },
  { label: 'Commercial Invoice (include NXP number)', value: 'commercial_invoice' },
  { label: 'Packing List', value: 'packing_list' },
  { label: 'Combined Certificate', value: 'combined_certificate' },
  { label: 'Pre-Arrival Assessment Report', value: 'paar' },
  { label: 'HS Code', value: 'hs_code' },
  { label: 'Regulatory Permit', value: 'regulatory_permit' },
  { label: 'Bill Of Lading', value: 'bill_of_lading' },
  { label: 'NAFDAC', value: 'nafdac' },
  { label: 'SONCAP', value: 'soncap' },
]

export const export_ocean_freight_documents = [
  { label: 'Approved NXP Form', value: 'nxp_document' },
  { label: 'Proforma Invoice', value: 'proforma_invoice' },
  { label: 'Shipping Datasheet', value: 'shipping_datasheet' },
  { label: 'Packing List', value: 'packing_list' },
  { label: 'Commercial Invoice (include NXP number)', value: 'commercial_invoice' },
  { label: 'CCI Clean Certificate of inspection', value: 'cci' },
  { label: 'SGD And Inspection Act', value: 'sgd' },
  { label: 'Custom Export Clearance', value: 'custom_export_clearance' },
  { label: 'OBL / SEAWAY BILL / TELEX', value: 'obl' },
  { label: 'Post Shipment Documents', value: 'post_shipment_docs' },
  { label: 'Nigerian Export Promotion Council (NEPC)', value: 'nepc' },
  { label: 'Customer Acquisition Cost (CAC)', value: 'cac' },
  { label: 'Bill Of Lading', value: 'bill_of_lading' },
  { label: 'NAFDAC', value: 'nafdac' },
  { label: 'SONCAP', value: 'soncap' },
]
