import OutlineButton from "components/buttons/OutlineButton"
import Modal from "react-modal"
import { useNavigate } from "react-router-dom"


const SuccessNotificationQuoteModal = () => {

  const navigate = useNavigate()

  const customStyles = { 
    content: {
      top: '45%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      height: "367px",
      width: "434px",
      marginTop: "44px",
      padding: "0px",
      border: "30px",
      borderColor: "#296FD8",
      overflow: "hidden"
    },
    overlay: {
      zIndex: '90',
      backgroundColor: 'rgba(6, 24, 2, 0.55)',
    },
  }
  return (
    <Modal style={customStyles} isOpen>
       <div className="flex flex-col">
          <div className="flex-1 pt-[64px] pb-[48px]">
             <div className="bg-[#F5FFED] rounded-[13px] flex justify-center items-center mx-auto object-center w-[80px] h-[80px]">
              <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.9987 37.1663C29.1654 37.1663 36.6654 29.6663 36.6654 20.4997C36.6654 11.333 29.1654 3.83301 19.9987 3.83301C10.832 3.83301 3.33203 11.333 3.33203 20.4997C3.33203 29.6663 10.832 37.1663 19.9987 37.1663Z" stroke="#139C33" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.918 20.5008L17.6346 25.2175L27.0846 15.7842" stroke="#139C33" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div className="flex flex-col gap-y-[24px] pt-[24px]">
              <h2 className="text-center">Quote Accepted</h2>
              <p className="text-center mx-auto max-w-[291px] text-[12px] text-[#4B5563]">Your Quote has been Accepted successfully, click the button below to go back to the dashboard.</p>
            </div>
            
          </div>
          <div className="px-[24px] pb-[24px]">
          <OutlineButton title='Back to Dashboard' loading={false} disabled={false} icon={''} onClick={() => navigate("/dashboard")} style={{ color: '#00861E', width: '100%' }} />
          </div>
       </div>
    </Modal>
  )
}

export default SuccessNotificationQuoteModal