import { formatContainerDetails, formatShipmentType } from 'util/format'
import BookingDetailComp from './BookingDetailComp'

interface BookingDetailsProps {
  companyDetails?: {
    firstname: string
    lastname: string
    email: string
  }
  bookingDetails?: {
    goods_type: string
    shipment_type: string
    shipment_transport_type: string
    container_details: { container_size: string }[]
    origin_port_code: string
    destination_port_code: string
    origin_port: string
    destination_port: string
  }
  isVisible: boolean
  single_booking: any
}
const OceanFreightBookingDetails = ({ companyDetails, bookingDetails, isVisible, single_booking }: BookingDetailsProps) => {
  return (
    <div className={`transition-all py-6 duration-300  ease-in-out ${isVisible ? 'block  flex flex-col gap-[32px]' : 'hidden overflow-hidden'}`}>
      <div className='grid grid-cols-2 lg:grid-cols-4 gap-x-10 gap-y-[32px]'>
        <BookingDetailComp label='Customer Name' details={`${companyDetails?.firstname} ${companyDetails?.lastname}`} email={false} />

        <BookingDetailComp label='Email Address' details={`${companyDetails?.email}`} email={true} />

        <BookingDetailComp label='Commodity' details={`${single_booking?.commodity_description}`} email={false} />

        <BookingDetailComp
          label='Service Type'
          details={formatShipmentType(`${single_booking?.shipment_type} ${single_booking?.shipment_transport_type}`)}
          email={false}
        />

        <BookingDetailComp label='Port of Loading' details={single_booking ? single_booking?.origin_port : 'N/A'} email={false} />

        <BookingDetailComp label='Destination Port' details={single_booking ? single_booking?.destination_port : 'N/A'} email={false} />

        <BookingDetailComp label='Container Size' details={formatContainerDetails(single_booking?.container_details || [])} email={false} />
      </div>
    </div>
  )
}

export default OceanFreightBookingDetails
