export const oneport_branches = ['LOS', 'PHC', 'KAN', 'ACC', 'NBO']

export const oneport_countries = {
  nigeria: ['LOS', 'PHC', 'KAN'],
  ghana: ['ACC'],
  kenya: ['NBO'],
}

export const branch_to_countries = {
  LOS: 'NG',
  PHC: 'NG',
  KAN: 'NG',
  ACC: 'GH',
  NBO: 'KE',
}
