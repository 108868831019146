import React from 'react'
import { capitalize, formatShipmentType, formatValue } from 'util/format'
import BookingDetailComp from './BookingDetailComp'

interface BookingDetailsProps {
  companyDetails?: {
    firstname: string
    lastname: string
    email: string
  }
  bookingDetails?: {
    goods_type: string
    shipment_type: string
    shipment_transport_type: string
    container_details: { container_size: string }[]
    origin_port_code: string
    destination_port_code: string
    origin_port: string
    destination_port: string
    brokerage_type: string
    delivery_location: string
    port_of_discharge: string
    pickup_location: string
  }
  isVisible: boolean
  single_booking: any
}

const CBBookingDetails = ({ companyDetails, bookingDetails, isVisible, single_booking }: BookingDetailsProps) => {
  console.log(single_booking)
  return (
    <div className={`transition-all py-6 duration-300  ease-in-out ${isVisible ? 'block  flex flex-col gap-[32px]' : 'hidden overflow-hidden'}`}>
      <div className='grid grid-cols-2 lg:grid-cols-4 gap-x-10 gap-y-[32px]'>
        <BookingDetailComp label='Customer Name' details={`${companyDetails?.firstname} ${companyDetails?.lastname}`} email={false} />

        <BookingDetailComp label='Email Address' details={`${companyDetails?.email}`} email={true} />

        <BookingDetailComp label='Commodity' details={`${single_booking?.goods_type}`} email={false} />

        <BookingDetailComp
          label='Service Type'
          details={formatShipmentType(`${single_booking?.shipment_type} ${single_booking?.shipment_transport_type}`)}
          email={false}
        />

        <BookingDetailComp
          label={`${single_booking?.shipment_type == 'import' ? 'Port Name' : 'Origin Port'}`}
          details={single_booking?.shipment_type == 'import' ? `${single_booking?.pickup_location}` : `${single_booking?.origin_port_code}`}
          email={false}
        />

        <BookingDetailComp
          label={single_booking?.shipment_type == 'import' ? 'Destination' : 'Destination Port'}
          details={single_booking?.shipment_type == 'import' ? `${single_booking?.destination_port_code}` : `${single_booking?.delivery_location}`}
          email={false}
        />
        {single_booking?.shipment_type == 'import' && <BookingDetailComp label='CB Type' details={`${single_booking?.brokerage_type}`} />}

        <BookingDetailComp
          label='Container Size'
          details={`${formatValue(single_booking?.container_details?.[0]?.container_size)} X ${formatValue(
            single_booking?.container_details?.length
          )}`}
          email={false}
        />
      </div>
    </div>
  )
}

export default CBBookingDetails
