import OneportLogo from "assets/logos/oneport-logo.svg"
import { branch_to_countries } from "helpers/branchToCountriesHelper"
import NigeriaQuoteAddress from "./NigeriaQuoteAddress"
import GhanaQuoteAddress from "./GhanaQuoteAddress"
import { bookingQuoteDetails } from '../../store/booking/types';
import KenyaQuoteAddress from "./KenyaQuoteAddress";

interface QuoteAddressProps {
  booking_details: bookingQuoteDetails
}

const QuoteAddress = ({booking_details}: QuoteAddressProps) => {
  
  return (
    <>
    {branch_to_countries[booking_details?.branch] == "NG" ? (
      <NigeriaQuoteAddress/>
    ) : 
    branch_to_countries[booking_details?.branch] == "GH" ? (
     <GhanaQuoteAddress/>
    ):
    branch_to_countries[booking_details?.branch] == "KE" ? (
      <KenyaQuoteAddress/>
    )
    : (
      <NigeriaQuoteAddress/>
    )}
    </>
  )
}

export default QuoteAddress