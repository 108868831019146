import React from 'react';
interface Props {
  number: string;
  terms: string;
}
const UnitTermsAndCond = ({ terms, number }: Props) => {
  return (
    <div className="flex flex-row items-center  gap-[16px] ">
      <div className="flex gap-[39px] items-center">
        <p className="black-text text-sm font-medium  w-[17px]">{number}.</p>
        {/* line */}
        <div className="w-[2px] h-[36px] bg-[#F3F4F6]"></div>
      </div>

      <p className="text-[14px] text-[#1F2937] font-medium">{terms}</p>
    </div>
  );
};

export default UnitTermsAndCond;
