import React, { useState } from 'react'
import { useSelector } from 'react-redux'

//icons
import bigArrow from 'assets/icons/bigarrow-right.svg'
import chevronDown from 'assets/icons/chevron-down.svg'
import chevronUp from 'assets/icons/chevron-up.svg'

//libraries
import moment from 'moment'

//components
import ShipmentTrackingAir from 'components/shipment/afapShipment/ShipmentTrackingAir'

const ShipmentDetailAirDTP = ({ shipdata, booking, shipCategory, airtrack_data }) => {
  const accordionData = shipdata?.air_cargo_details

  let user = useSelector((state: any) => state.auth.user_data)
  // @ts-ignore
  user = user ? user : JSON.parse(localStorage.getItem('user_data'))

  const [accordion, setAccordion] = useState({})
  const [showDetails, setShowDetails] = useState(false)

  const toggleAccordion = (id) => {
    setAccordion((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  return (
    <div>
      <div className='grid grid-cols-1 gap-y-6 xl:gap-y-8 xl:grid-cols-3 xl:gap-x-8 mb-10 xl:mb-0 items-start'>
        <div className='col-span-2'>
          <div className='solid-br rounded'>
            <div className='pt-4 pb-4 xl:pt-[17px] xl:pb-[16px] border-solid border-b-[#F3F4F6] border-b-[1px]'>
              <div className='flex flex-col gap-y-4 xl:gap-x-0 xl:flex-row xl:justify-between xl:items-center'>
                <p className='black-text-3 px-6 font-normal pb-4 xl:pb-0 xl:w-[45%]'>
                  {shipdata?.shipment_type?.charAt(0).toUpperCase() + shipdata?.shipment_type?.slice(1)}{' '}
                  {shipdata?.air_freight_data?.air_freight_type === 'door_to_port'
                    ? 'Air Freight Details (Door to Port)'
                    : shipdata?.shipment_transport_type === 'ocean_freight'
                    ? '(Ocean Freight Details)'
                    : shipdata?.shipment_transport_type === 'haulage'
                    ? '(Haulage Details)'
                    : ''}
                </p>
                {shipCategory === 'exportair_freight' && (
                  <div className='flex justify-between items-center gap-x-6 pr-6 xl:w-[55%] pl-6 xl:pl-0'>
                    <div>
                      <p className='text-sm font-light grey-text'>Pickup Address</p>
                      <p className='text-sm black-text-3 font-medium capitalize'>{shipdata?.pickup_location ? shipdata?.pickup_location : 'N/A'}</p>
                    </div>
                    <div>
                      <img src={bigArrow} alt='' />
                    </div>
                    <div>
                      <p className='text-sm font-light grey-text'>Delivery Airport</p>
                      <p className='text-sm black-text-3 font-medium capitalize'>
                        {shipdata?.destination_port_code ? shipdata?.destination_port_code : 'N/A'}
                      </p>
                    </div>
                  </div>
                )}
                {shipCategory === 'importair_freight' && (
                  <div className='flex justify-between items-center gap-x-6 pr-6 xl:w-[55%] pl-6 xl:pl-0'>
                    <div>
                      <p className='text-sm font-light grey-text'>Pickup Address</p>
                      <p className='text-sm black-text-3 font-medium capitalize'>{shipdata?.pickup_location ? shipdata?.pickup_location : 'N/A'}</p>
                    </div>
                    <div>
                      <img src={bigArrow} alt='' />
                    </div>
                    <div>
                      <p className='text-sm font-light grey-text'>Delivery Airport</p>
                      <p className='text-sm black-text-3 font-medium capitalize'>
                        {shipdata?.destination_port_code ? shipdata?.destination_port_code : 'N/A'}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='p-6'>
              <>
                <div className='grid grid-cols-2 md:grid-cols-3 text-sm gap-5 md:gap-8'>
                  <div>
                    <p className='grey-text font-light pb-1.5'>Customer Name</p>
                    <p className='black-text-3 capitalize'>{user.firstname + ' ' + user.lastname}</p>
                  </div>
                  <div>
                    <p className='grey-text font-light pb-1.5'>Company Name</p>
                    <p className='black-text-3 capitalize'>{user.company_name}</p>
                  </div>
                  {shipdata?.shipment_type === 'import' ? (
                    <>
                      <div>
                        <p className='grey-text font-light pb-1.5'>Sender Name</p>
                        <p className='black-text-3 capitalize'>
                          {shipdata?.air_freight_data?.consignee_name ? shipdata?.air_freight_data?.consignee_name : 'N/A'}
                        </p>
                      </div>
                      <div>
                        <p className='grey-text font-light pb-1.5'>Sender Phone Number</p>
                        <p className='black-text-3'>
                          {shipdata?.air_freight_data?.consignee_phone ? shipdata?.air_freight_data?.consignee_phone : 'N/A'}
                        </p>
                      </div>
                      <div>
                        <p className='grey-text font-light pb-1.5'>Sender Email</p>
                        <p className='black-text-3'>
                          {shipdata?.air_freight_data?.consignee_email ? shipdata?.air_freight_data?.consignee_email : 'N/A'}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <p className='grey-text font-light pb-1.5'>Consignee Name</p>
                        <p className='black-text-3 capitalize'>
                          {shipdata?.air_freight_data?.consignee_name ? shipdata?.air_freight_data?.consignee_name : 'N/A'}
                        </p>
                      </div>
                      <div>
                        <p className='grey-text font-light pb-1.5'>Consignee Phone Number</p>
                        <p className='black-text-3'>
                          {shipdata?.air_freight_data?.consignee_phone ? shipdata?.air_freight_data?.consignee_phone : 'N/A'}
                        </p>
                      </div>
                      <div>
                        <p className='grey-text font-light pb-1.5'>Consignee Email</p>
                        <p className='black-text-3'>
                          {shipdata?.air_freight_data?.consignee_email ? shipdata?.air_freight_data?.consignee_email : 'N/A'}
                        </p>
                      </div>
                    </>
                  )}
                  <div>
                    <p className='grey-text font-light pb-1.5'>Form M</p>
                    <p className='black-text-3'>{shipdata?.form_m_number ? shipdata?.form_m_number : 'N/A'}</p>
                  </div>
                  <div>
                    <p className='grey-text font-light pb-1.5'>Banker's Acceptance number</p>
                    <p className='black-text-3'>{shipdata?.bankers_acceptance_number ? shipdata?.bankers_acceptance_number : 'N/A'}</p>
                  </div>
                  <div>
                    <p className='grey-text font-light pb-1.5'>Booking Date</p>
                    <p className='black-text-3'>{shipdata.createdAt ? moment(shipdata.createdAt).format('DD-MM-YYYY') : 'N/A'}</p>
                  </div>
                  <div>
                    <p className='grey-text font-light pb-1.5'>Total weight of Goods</p>
                    <p className='black-text-3'>{shipdata?.goods_weight ? shipdata?.goods_weight : 'N/A'} KG</p>
                  </div>
                  <div>
                    <p className='grey-text font-light pb-1.5'>Additional Comments</p>
                    <p className='black-text-3 capitalize'>{shipdata.additional_comments ? shipdata.additional_comments : 'N/A'}</p>
                  </div>
                </div>
              </>
            </div>
          </div>

          {/* Cargo Details */}
          <div className='py-10'>
            <div className='solid-br rounded'>
              {shipdata.air_cargo_details?.length > 1 ? (
                <>
                  <p className='black-text-3 p-6 pb-[18px] font-normal bottom-divider-2'>Cargo Details</p>
                  <div className='px-6 pb-5'>
                    {accordionData?.map((item: any, index) => {
                      return (
                        <>
                          <div className='flex mt-5' key={item._id}>
                            <div
                              className={`flex solid-br px-4 py-4 bottom-divider ${
                                !accordion[item._id] ? 'rounded-bl rounded-br' : ''
                              } rounded-tl rounded-tr cursor-pointer`}
                              style={{ width: '-webkit-fill-available' }}
                              onClick={() => toggleAccordion(item._id)}
                            >
                              <p className='black-text-3 text-sm font-normal capitalize'>Details for Pallet {index + 1}</p>
                              <div className='ml-auto mt-2'>
                                <img src={showDetails ? chevronUp : chevronDown} alt='' width={16} />
                              </div>
                            </div>
                          </div>

                          {accordion[item._id] && (
                            <>
                              <div
                                className={`grid grid-cols-2 md:grid-cols-3 text-sm p-4 pb-8 gap-8 right-divider left-divider bottom-divider ${
                                  accordion[item._id] ? 'rounded-bl rounded-br' : ''
                                }`}
                              >
                                <>
                                  <div>
                                    <p className='grey-text font-light pb-1.5'>Volumetric Weight (CM^3)</p>
                                    <p className='black-text-3'>{item?.container_weight ? item?.container_weight : 'N/A'}</p>
                                  </div>
                                  <div>
                                    <p className='grey-text font-light pb-1.5'>Width of Cargo (CM)</p>
                                    <p className='black-text-3'>{item?.container_width ? item?.container_width : 'N/A'}</p>
                                  </div>
                                  <div>
                                    <p className='grey-text font-light pb-1.5'>Height of Cargo (CM)</p>
                                    <p className='black-text-3'>{item?.container_height ? item?.container_height : 'N/A'}</p>
                                  </div>
                                  <div>
                                    <p className='grey-text font-light pb-1.5'>Length of Cargo (CM)</p>
                                    <p className='black-text-3'>{item?.container_length ? item?.container_length : 'N/A'}</p>
                                  </div>
                                </>
                              </div>
                            </>
                          )}
                        </>
                      )
                    })}
                  </div>
                </>
              ) : (
                <>
                  <p className='black-text-3 p-6 pb-[18px] font-normal bottom-divider-2'>Cargo Details</p>
                  <div className='grid grid-cols-2 md:grid-cols-3 text-sm gap-x-4 p-6 gap-6'>
                    <>
                      <div>
                        <p className='grey-text font-light pb-1.5'>Volumetric Weight (CM^3)</p>
                        <p className='black-text-3'>
                          {shipdata.air_cargo_details[0]?.container_weight ? shipdata.air_cargo_details[0]?.container_weight : 'N/A'}
                        </p>
                      </div>
                      <div>
                        <p className='grey-text font-light pb-1.5'>Width of Cargo (CM)</p>
                        <p className='black-text-3'>
                          {shipdata.air_cargo_details[0]?.container_width ? shipdata.air_cargo_details[0]?.container_width : 'N/A'}
                        </p>
                      </div>
                      <div>
                        <p className='grey-text font-light pb-1.5'>Height of Cargo (CM)</p>
                        <p className='black-text-3'>
                          {shipdata.air_cargo_details[0]?.container_height ? shipdata.air_cargo_details[0]?.container_height : 'N/A'}
                        </p>
                      </div>
                      <div>
                        <p className='grey-text font-light pb-1.5'>Length of Cargo (CM)</p>
                        <p className='black-text-3'>
                          {shipdata.air_cargo_details[0]?.container_length ? shipdata.air_cargo_details[0]?.container_length : 'N/A'}
                        </p>
                      </div>
                      <div>
                        <p className='grey-text font-light pb-1.5'>Number of Pallets</p>
                        <p className='black-text-3'>{shipdata.air_cargo_details ? shipdata.air_cargo_details?.length : 'N/A'}</p>
                      </div>
                    </>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <ShipmentTrackingAir airtrack_data={airtrack_data?.[0]?.tracking_time_logs} />
      </div>
    </div>
  )
}

export default ShipmentDetailAirDTP
