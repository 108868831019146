import { Table } from 'antd'
import type { TableColumnsType } from 'antd'
import { currencyHelper } from '../../../helpers/CurrencyHandker'

interface ViewQuoteTableProps {
  row_data: any
  table_name: string
  item: any
}

const ViewQuoteTable = ({ row_data, table_name, item }: ViewQuoteTableProps) => {
  interface DataType {
    key: React.Key
    basis: string
    RateUsd: string
    description: string
    transitTime: string
    comment: string
  }

  const data = row_data

  const filtColumns = {}

  const columnsToExclude = ['_id', 'createdAt', 'updatedAt', 'shipment_quotes_table_id', 'row_index', 'currency']

  data.forEach((row) => {
    Object.keys(row).forEach((key) => {
      if (row[key] !== '' && !columnsToExclude.includes(key) && row[key] !== null) {
        filtColumns[key] = true
      } else if (columnsToExclude.includes(key)) {
        delete filtColumns[key]
      }
    })
  })

  const filtColumnsNames = Object.keys(filtColumns).filter((key) => !columnsToExclude.includes(key))

  const getTableTitle = (title: string) => {
    switch (title) {
      case 'basis':
        return 'Basis'
      case 'forty_ft':
        return '40FT'
      case 'twenty_ft':
        return '20FT'
      case 'currency':
        return 'Currency'
      case 'comments':
        return 'Comments'
      case 'transit_time':
        return 'Transit Time'
      case 'free_time':
        return 'Free Time'
      case 'description':
        return 'Description'
      case 'transit_rate':
        return 'Rate'
      case 'unit_of_measure':
        return 'Unit of Measure'
      case 'unit':
        return 'Unit'
      case 'amount':
        return 'Amount'
      case 'discount':
        return 'Discount %'
      case 'air_liner_name':
        if (table_name === 'Transit - Air Freight') {
          return 'Air Liner'
        }
        break
      case 'ship_liner_name':
        if (table_name === 'Transit - Ocean Freight') {
          return 'Ship Liner'
        }
        break
    }
  }

  const selectedColumnsState = filtColumnsNames.map((item, i) => {
    return {
      title: getTableTitle(item),
      dataIndex: item,
    }
  })

  return (
    <div className=''>
      <Table
        dataSource={row_data}
        pagination={false}
        className='mt-[32px] overflow-x-auto md:overflow-y-hidden'
        columns={selectedColumnsState as unknown as TableColumnsType<any>}
        tableLayout='auto'
        rowClassName={'border border-[#2d355b] overflow-x-auto'}
      />
      <div className='flex justify-end items-center gap-[20px] pr-[10px] pt-[10px]'>
        <p className='text-[#9CA3AF] text-[20px]'>Sub Total:</p>
        <p>
          {currencyHelper[item?.currency]} {item?.sub_total.toLocaleString('en-US')}
        </p>
      </div>
    </div>
  )
}

export default ViewQuoteTable
