import React, { useState } from 'react'
import SlidingPane from 'react-sliding-pane'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import 'react-sliding-pane/dist/react-sliding-pane.css'

const NavBar = () => {
  const [toggleMenu, setToggleMenu] = useState(false)
  const [isPaneOpen, setisPaneOpen] = useState(false)

  //   @ts-ignore
  const { user_token } = useSelector((store) => store.auth)
  const token = localStorage.getItem('token')

  const handleToggle = () => {
    setToggleMenu(!toggleMenu)
  }
  return (
    <></>
  )
}

export default NavBar
