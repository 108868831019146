import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

//style
import 'index.css'

//icons
import close from 'assets/icons/close.svg'

//redux
import { logoutUser } from 'store/actions'

//components
import LogoutModal from 'components/partials/LogoutModal'

interface Props {
  handleToggle?: () => void
  logoutUser: () => void
}

const Sidebar = ({ handleToggle, logoutUser }: Props) => {
  const [logOutIsOpen, setLogOutIsOpen] = useState(false)

  //close modal
  const closeLogoutModal = () => {
    setLogOutIsOpen(false)
  }

  const logout = () => {
    logoutUser()
    window.location.reload()
  }

  return (
    <aside className='sidebar dashboard-aside flex flex-col border-r-[1px] border-[#eaecf080] desktop-only relative z-10'>
      <div className='pl-8 py-3 border-y-[1px] border-[#eaecf080] max-h-[60px] flex justify-between items-center'>
        <NavLink to='/'>
          <img src={'https://ik.imagekit.io/oneport365/images/oneport-logo.svg'} alt='oneport logo' />
        </NavLink>
        <div className='pr-6 hidden mobile-close'>
          <img src={close} alt='close' onClick={handleToggle} className='cursor-pointer' />
        </div>
      </div>
      <div className='px-4'>
        <NavLink to='/dashboard' className='flex items-center pl-4 py-2 gap-x-3 mt-8 hover:bg-[#F5FDF2] rounded' onClick={handleToggle}>
          {({ isActive }) => (
            <>
              <svg width='13' height='14' fill='none'>
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M11.768 3.725c.475.382.753.956.76 1.565l-.006 5.316a2.614 2.614 0 0 1-2.615 2.614h-1.16a1.27 1.27 0 0 1-1.269-1.263v-2.4a.336.336 0 0 0-.336-.336h-1.74a.336.336 0 0 0-.335.342v.915a.522.522 0 1 1-1.044 0v-.915A1.386 1.386 0 0 1 5.41 8.177h1.739c.763.003 1.38.623 1.38 1.386v2.394c0 .121.098.22.22.22h1.188c.865 0 1.565-.7 1.565-1.565v-5.31a.98.98 0 0 0-.37-.754L7.124 1.354a1.368 1.368 0 0 0-1.74 0L4.1 2.316a.532.532 0 0 1-.62-.864L4.747.531a2.406 2.406 0 0 1 3.014 0l4.006 3.194ZM2.614 12.17h2.667v.012a.522.522 0 1 1 0 1.043H2.614A2.62 2.62 0 0 1 0 10.612v-5.31c.01-.614.297-1.19.783-1.566l.48-.359a.522.522 0 0 1 .604.829l-.47.348a.98.98 0 0 0-.354.748v5.304a1.577 1.577 0 0 0 1.571 1.565Z'
                  fill={isActive ? '#109B32' : '#6B7280'}
                />
              </svg>
              <span className={isActive ? 'green-text font-light' : 'grey-text font-light'}>Dashboard</span>
            </>
          )}
        </NavLink>
        {/* <p className="grey-text text-sm pl-4 mb-4 font-light uppercase">business</p> */}
        <NavLink to='/booking' className='flex items-center pl-4 py-2 gap-x-3 hover:bg-[#F5FDF2] rounded' onClick={handleToggle}>
          {({ isActive }) => (
            <>
              <svg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M10.968 1.822v.69C12.974 2.707 14 3.815 14 5.808v5.514c0 2.217-1.264 3.343-3.75 3.343h-4.5C3.265 14.665 2 13.54 2 11.322V5.808c0-1.326.452-2.276 1.38-2.83a.457.457 0 0 1 .488-.018.474.474 0 0 1-.048.843c-.614.366-.904 1.003-.904 2.005v.177h8.458c.253 0 .458.209.458.466a.462.462 0 0 1-.458.466H2.916v4.405c0 1.692.823 2.394 2.835 2.406h4.498c1.983 0 2.83-.72 2.83-2.412V5.802c0-1.474-.644-2.187-2.11-2.358v.448a.466.466 0 0 1-.477.404.467.467 0 0 1-.446-.44V1.781a.469.469 0 0 1 .149-.327.453.453 0 0 1 .332-.121.475.475 0 0 1 .441.49ZM5.806 4.194a.454.454 0 0 1-.328.134.462.462 0 0 1-.446-.472V1.822a.467.467 0 0 1 .46-.419c.237 0 .435.18.462.42v.642h2.62c.253 0 .458.208.458.466a.462.462 0 0 1-.458.466H5.942v.465a.47.47 0 0 1-.136.332Zm4.988 4.215c-.32 0-.58.264-.58.59 0 .325.26.59.58.59.32 0 .58-.265.58-.59a.595.595 0 0 0-.172-.42.575.575 0 0 0-.414-.17h.006Zm-3.362.59c0-.326.26-.59.58-.59.32 0 .58.264.58.59 0 .325-.26.59-.58.59a.585.585 0 0 1-.58-.59Zm-2.197-.59c-.32 0-.58.264-.58.59 0 .325.26.59.58.59.32 0 .58-.265.58-.59a.595.595 0 0 0-.172-.42.575.575 0 0 0-.414-.17h.006Zm4.98 2.978c0-.326.259-.59.58-.59h-.007a.575.575 0 0 1 .414.17c.11.111.172.262.172.42 0 .325-.26.59-.58.59a.585.585 0 0 1-.58-.59Zm-2.203-.59c-.32 0-.58.264-.58.59 0 .325.26.59.58.59.32 0 .58-.265.58-.59 0-.326-.26-.59-.58-.59Zm-3.357.59c0-.326.26-.59.58-.59h-.006a.575.575 0 0 1 .414.17c.11.111.171.262.171.42 0 .325-.26.59-.58.59a.585.585 0 0 1-.579-.59Z'
                  fill={isActive ? '#109B32' : '#6B7280'}
                />
              </svg>
              <span className={isActive ? 'green-text font-light' : 'grey-text font-light'}>Booking</span>
            </>
          )}
        </NavLink>
        <NavLink to='/shipments' className='flex items-center pl-4 py-2 gap-x-3 hover:bg-[#F5FDF2] rounded' onClick={handleToggle}>
          {({ isActive }) => (
            <>
              <svg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M15.266 3.188c0-.492-.204-.964-.566-1.313a1.978 1.978 0 0 0-1.368-.543H2.666c-.513 0-1.005.196-1.368.543-.362.349-.566.82-.566 1.313v10.24c0 .492.204.964.566 1.313.363.347.855.543 1.368.543h10.666c.513 0 1.005-.196 1.368-.543.362-.348.566-.82.566-1.313V3.188Zm-.653 0v10.24c-.04.615-.307.728-.621.98-.224.155-.465.181-.66.181H2.666c-.706 0-.963-.405-1.1-.536-.138-.132-.152-.438-.152-.625V3.188c0-.363.408-.702.546-.834.361-.347.786-.288.98-.288h10.392c.393 0 .745.156.883.288.137.132.398.47.398.834Z'
                  fill={isActive ? '#109B32' : '#6B7280'}
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M6.02 10.146v-4.61c0-.287-.21-.519-.471-.519s-.47.232-.47.519v4.61c0 .286.209.518.47.518s.47-.232.47-.518Z'
                  fill={isActive ? '#109B32' : '#6B7280'}
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='m5.55 5.15-.662.676a.436.436 0 0 1-.623 0 .453.453 0 0 1 0-.63l.973-.991a.436.436 0 0 1 .622 0l.973.99c.17.175.17.457 0 .63a.436.436 0 0 1-.622 0l-.662-.674ZM10.725 10.059V4.681c0-.334-.21-.605-.471-.605s-.47.27-.47.605v5.378c0 .334.209.605.47.605s.47-.27.47-.605Z'
                  fill={isActive ? '#109B32' : '#6B7280'}
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='m10.254 5.15-.661.676a.436.436 0 0 1-.623 0 .453.453 0 0 1 0-.63l.973-.991a.436.436 0 0 1 .623 0l.972.99c.17.175.17.457 0 .63a.436.436 0 0 1-.622 0l-.662-.674ZM4.361 12.543h7.28c.329 0 .595-.211.595-.47 0-.26-.267-.471-.595-.471H4.36c-.329 0-.595.21-.595.47s.266.47.595.47Z'
                  fill={isActive ? '#109B32' : '#6B7280'}
                />
              </svg>
              <span className={isActive ? 'green-text font-light' : 'grey-text font-light'}>Shipments</span>
            </>
          )}
        </NavLink>
        <NavLink to='/container' className='flex items-center pl-4 py-2 gap-x-3 hover:bg-[#F5FDF2] rounded' onClick={handleToggle}>
          {({ isActive }) => (
            <>
              <svg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <rect x='2.5' y='2.5' width='11' height='11' rx='2.5' stroke={isActive ? '#109B32' : '#6B7280'} />
                <path d='M5 5v6M8 5v6M11 5v6' stroke={isActive ? '#109B32' : '#6B7280'} stroke-linecap='round' />
              </svg>
              <span className={isActive ? 'green-text font-light' : 'grey-text font-light'}>Containers</span>
            </>
          )}
        </NavLink>
        <NavLink to='/quote' className='flex items-center pl-4 py-2 gap-x-3 hover:bg-[#F5FDF2] rounded' onClick={handleToggle}>
          {({ isActive }) => (
            <>
             <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">    
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.42525 1.33398H10.5689C12.7112 1.33398 13.9942 2.63494 14 4.80713V11.1942C14 13.3664 12.7228 14.6673 10.5747 14.6673H8.67054C8.42633 14.64 8.24149 14.4308 8.24149 14.1817C8.24149 13.9325 8.42633 13.7233 8.67054 13.696H10.5689C12.2061 13.696 13.0305 12.8542 13.0305 11.1942V4.80713C13.0305 3.14708 12.2061 2.30529 10.5689 2.30529H5.42525C3.7881 2.30529 2.95791 3.14708 2.95791 4.80713V11.1942C2.95791 12.8542 3.7881 13.696 5.42525 13.696C5.66946 13.7233 5.8543 13.9325 5.8543 14.1817C5.8543 14.4308 5.66946 14.64 5.42525 14.6673C3.28302 14.6673 2 13.3664 2 11.1942V4.80713C2 2.62905 3.28302 1.33398 5.42525 1.33398ZM5.59361 5.66658H7.42235C7.66656 5.63928 7.8514 5.43005 7.8514 5.18093C7.8514 4.93181 7.66656 4.72258 7.42235 4.69528H5.59361C5.34941 4.72258 5.16457 4.93181 5.16457 5.18093C5.16457 5.43005 5.34941 5.63928 5.59361 5.66658ZM10.3948 8.4863H5.59361H5.58781C5.3436 8.459 5.15876 8.24977 5.15876 8.00065C5.15876 7.75153 5.3436 7.5423 5.58781 7.515H10.3948C10.5785 7.49446 10.7576 7.58227 10.8558 7.74103C10.954 7.89979 10.954 8.10151 10.8558 8.26027C10.7576 8.41903 10.5785 8.50684 10.3948 8.4863ZM10.3948 11.306H5.59361H5.58781C5.32489 11.306 5.11176 11.0899 5.11176 10.8233C5.11176 10.5567 5.32489 10.3406 5.58781 10.3406H10.3948C10.6577 10.3406 10.8708 10.5567 10.8708 10.8233C10.8708 11.0899 10.6577 11.306 10.3948 11.306Z" fill="#6B7280"/>
            </svg>
              <span className={isActive ? 'green-text font-light' : 'grey-text font-light'}>Quotes</span>
            </>
          )}
        </NavLink>
        {/* <NavLink
                    to="/invoices"
                    className="flex items-center pl-4 py-2 gap-x-3 hover:bg-[#F5FDF2] rounded"
                    onClick={handleToggle}
                >
                    {({ isActive }) => (
                        <>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.89715 5.84136C2.89715 6.11593 2.66915 6.33951 2.38915 6.33951C2.10915 6.33951 1.88182 6.11593 1.88182 5.84136V4.60187C1.88182 3.16888 3.07048 2.00196 4.53115 2.00065L5.61715 2H5.61782C5.89782 2 6.12515 2.22292 6.12515 2.49815C6.12515 2.77272 5.89848 2.99564 5.61782 2.99564L4.53248 2.9963C3.63048 2.9976 2.89715 3.71737 2.89715 4.60187V5.84136ZM10.3999 2.99545H11.4619C12.3665 2.99545 13.1025 3.71717 13.1025 4.60429V5.84182C13.1025 6.11573 13.3299 6.33931 13.6099 6.33931C13.8899 6.33931 14.1172 6.11573 14.1172 5.84182V4.60429C14.1172 3.16803 12.9265 2.00046 11.4619 2.00046H10.3999C10.1199 2.00046 9.89255 2.22273 9.89255 2.49795C9.89255 2.77252 10.1199 2.99545 10.3999 2.99545ZM14.8253 8.02746H1.17335C0.894016 8.02746 0.666016 8.25038 0.666016 8.5256C0.666016 8.80017 0.894016 9.0231 1.17335 9.0231H1.88135V11.3975C1.88135 12.8311 3.07068 13.998 4.53202 13.9993L5.61735 14C5.89802 14 6.12468 13.7771 6.12535 13.5019C6.12535 13.2273 5.89802 13.0044 5.61802 13.0044L4.53268 13.0037C3.63068 13.0024 2.89668 12.2826 2.89668 11.3975V9.0231H13.1027V11.3955C13.1027 12.2833 12.3667 13.0044 11.462 13.0044H10.4C10.12 13.0044 9.89268 13.2273 9.89268 13.5019C9.89268 13.7771 10.12 14 10.4 14H11.462C12.926 14 14.1173 12.8324 14.1173 11.3955V9.0231H14.8253C15.1053 9.0231 15.3327 8.80017 15.3327 8.5256C15.3327 8.25038 15.1053 8.02746 14.8253 8.02746ZM6.76055 6.33951H9.23855C9.51922 6.33951 9.74655 6.11593 9.74655 5.84136C9.74655 5.56679 9.51922 5.34387 9.23855 5.34387H6.76055C6.48055 5.34387 6.25322 5.56679 6.25322 5.84136C6.25322 6.11593 6.48055 6.33951 6.76055 6.33951ZM6.76075 10.516H9.23875C9.51875 10.516 9.74608 10.7389 9.74608 11.0135C9.74608 11.2887 9.51875 11.511 9.23875 11.511H6.76075C6.48008 11.511 6.25275 11.2887 6.25275 11.0135C6.25275 10.7389 6.48008 10.516 6.76075 10.516Z" fill={isActive ? "#109B32" : "#6B7280"} />
                            </svg>

                            <span className={isActive ? "green-text font-light" : "grey-text font-light"}>Invoices</span>
                        </>
                    )}
                </NavLink> */}
        {/* <NavLink
                    to="/documents"
                    className="flex items-center pl-4 py-2 gap-x-3 hover:bg-[#F5FDF2] rounded"
                    onClick={handleToggle}
                >
                    {({ isActive }) => (
                        <>
                            <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.425 1.332h5.144c2.142 0 3.425 1.301 3.431 3.473v6.387c0 2.172-1.277 3.473-3.425 3.473H8.67a.486.486 0 0 1-.43-.485c0-.25.185-.459.43-.486h1.898c1.637 0 2.461-.842 2.461-2.502V4.805c0-1.66-.824-2.502-2.461-2.502H5.425c-1.637 0-2.467.842-2.467 2.502v6.387c0 1.66.83 2.502 2.467 2.502.244.027.43.237.43.486s-.186.458-.43.485c-2.142 0-3.425-1.3-3.425-3.473V4.805c0-2.178 1.283-3.473 3.425-3.473Zm.169 4.333h1.828a.486.486 0 0 0 .43-.486.486.486 0 0 0-.43-.486H5.594a.486.486 0 0 0-.43.486c0 .25.185.458.43.486Zm4.8 2.82H5.589a.486.486 0 0 1-.43-.486c0-.25.186-.459.43-.486h4.807a.48.48 0 0 1 .46.226.494.494 0 0 1 0 .52.48.48 0 0 1-.46.225Zm0 2.82H5.589a.48.48 0 0 1-.476-.484.48.48 0 0 1 .476-.482h4.807a.48.48 0 0 1 .476.482.48.48 0 0 1-.476.483Z" fill={isActive ? "#109B32" : "#6B7280"} />
                            </svg>
                            <span className={isActive ? "green-text font-light" : "grey-text font-light"}>Documents</span>
                        </>
                    )}
                </NavLink> */}
      </div>
      <div className='mx-4 mt-auto mb-8 lg:mb-0 border-t-[1px] border-[#eaecf080]'>
        <NavLink to='/settings' className='flex items-center pl-4 py-2 gap-x-3 hover:bg-[#F5FDF2] rounded' onClick={handleToggle}>
          {({ isActive }) => (
            <>
              <svg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M5.095 3.582c-.549.304-1.222.3-1.767-.012l-.024-.023a.503.503 0 0 1-.168-.703.53.53 0 0 1 .72-.164c.11.062.233.097.36.1a.742.742 0 0 0 .526-.189.707.707 0 0 0 .235-.496C4.974 1.142 5.747.36 6.722.332h.822c.993 0 1.799.787 1.799 1.757a.661.661 0 0 0 .096.351.723.723 0 0 0 .441.335.74.74 0 0 0 .554-.071c.844-.45 1.9-.17 2.399.632a.514.514 0 0 1-.192.697.535.535 0 0 1-.714-.182.741.741 0 0 0-.995-.257 1.837 1.837 0 0 1-1.763-.02 1.75 1.75 0 0 1-.9-1.48.7.7 0 0 0-.203-.516.734.734 0 0 0-.522-.215h-.822a.74.74 0 0 0-.523.215.705.705 0 0 0-.208.517 1.721 1.721 0 0 1-.896 1.487Zm.896 8.344c.003.393.33.709.732.709l-.006.012c.186 0 .358.097.451.254a.5.5 0 0 1 0 .51.524.524 0 0 1-.451.255c-.994 0-1.8-.787-1.8-1.757a.662.662 0 0 0-.095-.352.741.741 0 0 0-.996-.263c-.844.449-1.9.17-2.398-.633l-.408-.696a1.734 1.734 0 0 1 .648-2.343.735.735 0 0 0 .263-.257.695.695 0 0 0 .12-.567.71.71 0 0 0-.353-.464 1.734 1.734 0 0 1-.648-2.342.532.532 0 0 1 .726-.14c.227.15.293.45.15.679a.7.7 0 0 0 .27.966c.26.156.475.376.623.638.46.824.174 1.856-.648 2.342a.7.7 0 0 0-.27.967l.414.696a.711.711 0 0 0 .442.336c.188.05.388.02.554-.078.267-.152.572-.23.881-.228.994 0 1.799.786 1.799 1.756Zm6.602-4.31a.711.711 0 0 1-.34-.432.694.694 0 0 1 .076-.54.735.735 0 0 1 .264-.257.503.503 0 0 0 .18-.697.54.54 0 0 0-.708-.193 1.734 1.734 0 0 0-.647 2.342c.146.288.372.53.653.697.167.093.29.247.339.429a.688.688 0 0 1-.075.537l-.408.697a.728.728 0 0 1-.995.258 1.837 1.837 0 0 0-1.765.02 1.75 1.75 0 0 0-.897 1.484c.028.262.255.46.524.46.27 0 .496-.198.525-.46a.7.7 0 0 1 .36-.622.734.734 0 0 1 .731.007c.844.45 1.9.17 2.399-.632l.408-.697a1.734 1.734 0 0 0-.624-2.4ZM7.131 4.8c-.913 0-1.735.537-2.084 1.36a2.164 2.164 0 0 0 .492 2.4c.646.63 1.616.817 2.459.474a2.202 2.202 0 0 0 1.387-2.038 2.17 2.17 0 0 0-.66-1.555A2.277 2.277 0 0 0 7.13 4.8Zm0 3.373c-.662 0-1.2-.524-1.2-1.171s.538-1.171 1.2-1.171c.662 0 1.2.524 1.2 1.17 0 .647-.538 1.172-1.2 1.172Z'
                  fill={isActive ? '#109B32' : '#6B7280'}
                />
              </svg>
              <span className={isActive ? 'green-text font-light' : 'grey-text font-light'}>Settings</span>
            </>
          )}
        </NavLink>
        <p className='flex items-center pl-4 py-2 gap-x-3 mb-6 hover:bg-[#F5FDF2] rounded cursor-pointer' onClick={() => setLogOutIsOpen(true)}>
          <>
            <svg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M1.334 8.3c0 .27-.224.488-.5.488a.494.494 0 0 1-.5-.487V3.478c0-1.55 1.292-2.81 2.881-2.81h3.164c1.592 0 2.887 1.264 2.887 2.817v.59a.494.494 0 0 1-.5.486.494.494 0 0 1-.5-.487v-.59c0-1.015-.846-1.84-1.887-1.84H3.215c-1.037 0-1.88.823-1.88 1.834V8.3Zm6.932 1.63c0-.27.224-.489.5-.489s.5.219.5.488v.596c0 1.55-1.292 2.81-2.88 2.81H3.22c-1.592 0-2.887-1.264-2.887-2.817 0-.27.224-.487.5-.487s.5.218.5.487c0 1.016.847 1.841 1.887 1.841h3.164c1.038 0 1.881-.822 1.881-1.834V9.93Zm5.364-3.115a.5.5 0 0 0-.463-.302h-7.8c-.276 0-.5.22-.5.488 0 .27.224.488.5.488h6.59l-1.04 1.01a.479.479 0 0 0 0 .69.504.504 0 0 0 .706 0l1.897-1.842a.48.48 0 0 0 .11-.532Zm-1.882-.707a.507.507 0 0 1-.351-.14l-.479-.463a.479.479 0 0 1-.003-.689.507.507 0 0 1 .707-.003l.478.46a.48.48 0 0 1 .004.69.506.506 0 0 1-.355.145Z'
                fill='#6B7280'
              />
            </svg>
            <span className='hover:green-text font-light grey-text'>Logout</span>
          </>
        </p>
        <LogoutModal isOpen={logOutIsOpen} closeModal={closeLogoutModal} logoutUser={logoutUser} logout={logout} />
      </div>
    </aside>
  )
}

export default connect(null, { logoutUser })(Sidebar)
